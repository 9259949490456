import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import SignatureCanvas from "components/SignatureCanvas";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Issue } from "shared/types";
import { IssueFromValues } from "./IssueCreate";
import ContactCard from "./components/ContactCard";
import CostCard from "./components/CostCard";
import DataCard from "./components/DataCard";
import ItemCard from "./components/ItemCard";
import StatementCard from "./components/StatementCard";
import ToolCard from "./components/ToolCard";
import UserCard from "./components/UserCard";
import { useQuery } from "react-query";
import { getOfferById } from "shared/network/offer.api";
import { useSelector } from "react-redux";
import { RootState } from "config/store";
import Loading from "components/Loading";
import { getOrderById } from "shared/network/order.api";

type Props = {
  issue?: Issue;
  isLoading?: boolean;
  setItemCreateState?: Dispatch<SetStateAction<number | null>>;
  setIssueTypeOpen: Dispatch<SetStateAction<boolean>>;
  setIssueTemplateOpen?: Dispatch<SetStateAction<boolean>>;
};

const IssueForm = ({
  isLoading,
  issue,
  setItemCreateState,
  setIssueTypeOpen,
  setIssueTemplateOpen,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { control, watch, setValue } = useFormContext<IssueFromValues>();
  const [worksheetSelected, setWorksheetSelected] = useState(false);
  const {
    append: appendItem,
    fields: fieldsItem,
    remove: removeItem,
    replace: replaceItem,
  } = useFieldArray({ control, name: "items", keyName: "key" });
  const isCompleted = watch("status") === "COMPLETED";
  const location = useLocation();
  const tenantId = useSelector(
    (state: RootState) => state.authentication?.selectedRelTenant?.tenant?.id,
  );
  const query = new URLSearchParams(location.search);
  const offerId = query.get("offerId");
  const orderId = query.get("orderId");

  const {
    append: appendTool,
    fields: fieldsTool,
    remove: removeTool,
    replace: replaceTool,
  } = useFieldArray({
    control,
    name: "tools",
    keyName: "key",
  });

  const {
    append: appendUser,
    fields: fieldsUser,
    remove: removeUser,
    replace: replaceUser,
  } = useFieldArray({
    control,
    name: "users",
    keyName: "key",
  });

  const [isWorksheet, setIsWorksheet] = useState(false);
  const issueTemplate = watch("issueTemplate");
  const [isIssueTemplateChanged, setIsIssueTemplateChanged] = useState(false);

  const ref = useRef<HTMLDivElement | null>();
  const [open, setOpen] = useState(false);
  const [offset, setOffset] = useState<{ top: number; left: number }>({ top: 0, left: 0 });

  const { isFetching: isFetchingOffer } = useQuery(
    ["offerForIssueCreate"],
    async () => {
      if (offerId) {
        const { data } = await getOfferById(Number(offerId), tenantId);
        return data?.item;
      }
      return Promise.reject();
    },
    {
      enabled: !!offerId,
      onSuccess: data => {
        setValue("name", data?.name);
        setValue("description", data?.description);
        setValue("company", data?.company);
        setValue("offer", data);
        data?.relOfferItems?.forEach(element => {
          appendItem({
            item: element?.item,
            quantity: element?.quantity || 1,
            netPrice: element?.netPrice,
          });
        });
      },
    },
  );

  const { isFetching: isFetchingOrder } = useQuery(
    ["orderForIssueCreate"],
    async () => {
      if (orderId) {
        const { data } = await getOrderById(orderId, tenantId);
        return data?.item;
      }
      return Promise.reject();
    },
    {
      enabled: !!orderId,
      onSuccess: data => {
        setValue("name", data?.orderName || "");
        setValue("description", data?.deliveryComment || "");
        setValue("company", data?.company || null);
        setValue("companySite", data?.companySite || null);
        setValue("offer", data?.offer || null);
        setValue("project", data?.project || null);
        setValue("offer", data?.offer || null);
        setValue("contract", data?.contract || null);
        data?.relOrderItems?.forEach(element => {
          appendItem({
            item: element?.item,
            quantity: element?.quantity || 1,
            netPrice: element?.netPrice,
            currency: element?.currency,
          });
        });
      },
    },
  );

  useEffect(() => {
    setIsWorksheet(issueTemplate?.id === 17);
  }, [issueTemplate]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Loading open={isFetchingOffer || isFetchingOrder} />
      <Grid container justifyContent="center" spacing={2}>
        <Grid container item xs={12}>
          <DataCard
            worksheetSelected={worksheetSelected}
            setWorksheetSelected={setWorksheetSelected}
            issue={issue}
            isWorksheet={isWorksheet}
            setIsIssueTemplateChanged={setIsIssueTemplateChanged}
            removeUser={removeUser}
            setIssueTemplateOpen={setIssueTemplateOpen}
            setIssueTypeOpen={setIssueTypeOpen}
            replaceItem={replaceItem}
          />
        </Grid>
        {isWorksheet && (
          <Grid container item xs={12}>
            <ContactCard issue={issue} />
          </Grid>
        )}
        {isWorksheet && (
          <>
            <Grid item container xs={12}>
              <ToolCard
                issue={issue}
                append={appendTool}
                fields={fieldsTool}
                remove={removeTool}
                replace={replaceTool}
                isIssueTemplateChanged={isIssueTemplateChanged}
              />
            </Grid>
            <Grid item container xs={12}>
              <ItemCard
                issue={issue}
                append={appendItem}
                fields={fieldsItem}
                remove={removeItem}
                replace={replaceItem}
                setItemCreateState={setItemCreateState}
              />
            </Grid>
            <Grid item container xs={12}>
              <UserCard
                issue={issue}
                append={appendUser}
                fields={fieldsUser}
                remove={removeUser}
                replace={replaceUser}
                isIssueTemplateChanged={isIssueTemplateChanged}
              />
            </Grid>
          </>
        )}
        {isWorksheet && (
          <Grid container item xs={12}>
            <CostCard issue={issue} />
          </Grid>
        )}
        {isWorksheet && (
          <Grid container item xs={12}>
            <StatementCard issue={issue} />
          </Grid>
        )}
        {!isWorksheet && (
          <>
            <Grid item container xs={12}>
              <ItemCard
                issue={issue}
                append={appendItem}
                fields={fieldsItem}
                remove={removeItem}
                replace={replaceItem}
                setItemCreateState={setItemCreateState}
              />
            </Grid>
            <Grid item container xs={12} md={6}>
              <ToolCard
                issue={issue}
                append={appendTool}
                fields={fieldsTool}
                remove={removeTool}
                replace={replaceTool}
                isIssueTemplateChanged={isIssueTemplateChanged}
              />
            </Grid>
            <Grid item container xs={12} md={6}>
              <UserCard
                issue={issue}
                append={appendUser}
                fields={fieldsUser}
                remove={removeUser}
                replace={replaceUser}
                isIssueTemplateChanged={isIssueTemplateChanged}
              />
            </Grid>
          </>
        )}
      </Grid>
      {isCompleted && (
        <Dialog open={open} onClose={handleClose} disablePortal>
          <DialogTitle>{t("invoice.modifyStatus")}</DialogTitle>
          <DialogContent
            ref={ref}
            onScroll={() => {
              setOffset({
                top: ref.current?.scrollTop || 0,
                left: ref.current?.scrollLeft || 0,
              });
            }}
          >
            <Grid item xs={12} container justifyContent="center">
              <SignatureCanvas id={"signature"} offsetTop={offset.top} offsetLeft={offset.left} />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box display="flex" justifyContent="center" gridGap={8}>
              <Button color="primary" variant="text" onClick={handleClose}>
                {t("common:button.cancel")}
              </Button>
              <Button type="submit" disabled={isLoading} color="primary">
                {t("common:button.save")}
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
      <Box display="flex" justifyContent="center" gridGap={8} p={2} width="100%">
        <Button color="primary" variant="text" onClick={history.goBack}>
          {t("common:button.cancel")}
        </Button>
        <Button
          type={isCompleted ? "button" : "submit"}
          color="primary"
          disabled={isLoading}
          onClick={handleOpen}
        >
          {issue ? t("common:button.save") : t("common:button.create")}
        </Button>
      </Box>
    </>
  );
};

export default IssueForm;
