import { faFileCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  ButtonBase,
  Card,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Link } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { getFileByPath } from "shared/network/file.api";
import { addProfilePic, getJobFormById } from "shared/network/job-questionnaire.api";
import JobReferredToDialog from "../JobFormsCreate/components/JobReferredToDialog";
import ContactDataCard from "./detailscomponents/ContactDataCard";
import CVDataCard from "./detailscomponents/CVDataCard";
import DetailsDataCard from "./detailscomponents/DetailsDataCard";
import EducationDataCard from "./detailscomponents/EducationDataCard";
import JobDataCard from "./detailscomponents/JobDataCard";
import LanguageDataCard from "./detailscomponents/LanguageDataCard";
import PersonalDataCard from "./detailscomponents/PersonalDataCard";

const JobFormDetails = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const query = new URLSearchParams(location.search);
  const jobFormId = query.get("id");
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenantId = selectedRelTenant?.tenant?.id;

  const [open, setOpen] = useState(false);

  const jobFormQuery = useQuery(
    "jobFormQuery",
    async () => {
      if (jobFormId) {
        const { data } = await getJobFormById(jobFormId, tenantId);
        return data.item;
      }
      return Promise.reject();
    },
    { enabled: !!jobFormId, onSuccess: data => form?.reset(data) },
  );
  const jobForm = jobFormQuery?.data;

  const profilePicQuery = useQuery(
    ["deliveryDeliveryItemDialog", jobForm?.profilePic],
    async () => {
      if (jobForm?.profilePic) {
        const { data } = await getFileByPath(jobForm.profilePic, tenantId);
        const file = new Blob([data], { type: "image/jpeg" });
        const fileURL = URL.createObjectURL(file);
        return fileURL;
      }
      return Promise.reject();
    },
    { enabled: !!jobForm },
  );

  const form = useForm();
  const { register, watch, handleSubmit } = form;
  const idImage = watch("idFile")?.[0];
  const [loading, setLoading] = useState(false);

  async function onSubmit(values: { idFile: any }) {
    setLoading(true);
    try {
      if (values.idFile?.[0] && jobForm?.id) {
        await addProfilePic({
          jobQuestionnaireId: jobForm?.id,
          tenantId: tenantId ? Number(tenantId) : 1,
          file: values.idFile[0],
        });
      }
      jobFormQuery?.refetch();
      form?.reset();
      enqueueSnackbar("Sikeres feltöltés", { variant: "success" });
    } catch (err: any) {
      enqueueSnackbar("Sikertelen feltöltés", { variant: "error" });
    }
    setLoading(false);
  }

  return (
    <Container maxWidth="md">
      <Loading open={jobFormQuery?.isFetching} />
      {jobForm && (account?.user?.isSuperAdmin || selectedRelTenant?.isTenantAdmin) && (
        <Box display="flex" justifyContent="center" flexWrap="wrap" gridGap={8} pb={1}>
          <Box>
            <Button
              size="small"
              variant="outlined"
              onClick={() => setOpen(true)}
              startIcon={<Link />}
            >
              {t("jobForm.jobToCompany")}
            </Button>
            <JobReferredToDialog open={open} setOpen={setOpen} jobForm={jobForm} />
          </Box>
        </Box>
      )}
      {!!jobForm && (
        <Grid container alignItems="flex-start" spacing={1}>
          <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
            {profilePicQuery?.isFetching ? (
              <Skeleton
                variant="rect"
                style={{
                  objectFit: "cover",
                  maxWidth: "100%",
                  height: "300px",
                  borderRadius: 10,
                }}
              />
            ) : (
              !!profilePicQuery?.data && (
                <img
                  src={profilePicQuery?.data}
                  alt={"Profilkép"}
                  style={{
                    objectFit: "cover",
                    maxWidth: "100%",
                    maxHeight: "300px",
                  }}
                />
              )
            )}
            <FormProvider {...form}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Card style={{ padding: 8 }}>
                  <Typography style={{ fontWeight: "bold", fontSize: 16, paddingBottom: 4 }}>
                    Kép feltöltése:
                  </Typography>
                  {!!idImage?.name && (
                    <Typography style={{ paddingBottom: 8 }}>{idImage?.name}</Typography>
                  )}
                  <ButtonBase component="label" style={{ borderRadius: 10, marginBottom: 8 }}>
                    <input
                      style={{ display: "none" }}
                      type="file"
                      {...register("idFile", {
                        required: {
                          value: false,
                          message: t("personalData.formValues.fileRequired"),
                        },
                        validate: value => {
                          if (value?.[0]?.size >= 5242880) {
                            return t("validation.maxSize", {
                              size: 5,
                              unit: "MB",
                            }).toString();
                          }
                        },
                      })}
                    />
                    <Card variant="outlined">
                      <Box display="flex" alignItems="center" gridGap={12} p={"8px 16px"}>
                        <FontAwesomeIcon
                          icon={faFileCirclePlus}
                          style={{ width: 24, height: 24 }}
                        />
                        <Typography style={{ textTransform: "uppercase", fontWeight: "bold" }}>
                          {t("jobForm.personalData.selectCv")}
                        </Typography>
                      </Box>
                    </Card>
                  </ButtonBase>
                  <Button type="submit" disabled={!idImage || loading}>
                    {loading ? (
                      <CircularProgress size={24} style={{ margin: "0 24px" }} />
                    ) : (
                      t("common:button.upload")
                    )}
                  </Button>
                  {!!form?.formState?.errors?.cvFile?.message && (
                    <Typography style={{ paddingTop: 8, color: COLORS.red }}>
                      {form?.formState?.errors?.cvFile?.message}
                    </Typography>
                  )}
                </Card>
              </form>
            </FormProvider>
          </Grid>
          <Grid item xs={12} sm={8}>
            <PersonalDataCard jobForm={jobForm} />
          </Grid>
          <Grid item xs={12}>
            <ContactDataCard jobForm={jobForm} />
          </Grid>
          <Grid item xs={12}>
            <CVDataCard jobForm={jobForm} />
          </Grid>
          <Grid item xs={12}>
            <EducationDataCard jobForm={jobForm} />
          </Grid>
          <Grid item xs={12}>
            <LanguageDataCard jobForm={jobForm} />
          </Grid>
          <Grid item xs={12}>
            <JobDataCard jobForm={jobForm} />
          </Grid>
          <Grid item xs={12}>
            <DetailsDataCard jobForm={jobForm} />
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default JobFormDetails;
