import { Box, Button, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CustomField } from "shared/network/custom-fields.api";
import { Company, IssueTemplate, Item, Project, Tool, User } from "shared/types";
import CustomFieldCard from "./components/CustomFieldCard";
import DataCard from "./components/DataCard";
import ItemListCard from "./components/ItemListCard";
import PeriodsCard from "./components/PeriodsCard";
import ToolListCard from "./components/ToolListCard";
import UserListCard from "./components/UserListCard";
import { useSelector } from "react-redux";
import { RootState } from "config/store";

type Props = {
  issueTemplate?: IssueTemplate;
  isLoading?: boolean;
  setIssueTemplateOpen?: any;
};

export type RelIssueTemplateFormValues = {
  issueTemplateEntry: {
    id?: number | string;
    tenantId?: number;
    project: Project | null;
    assignedUser: User | null;
    name: string;
    worksheet: string;
    description: string;
    priority: string;
    deadlineDays: string;
    periodicCycleType: string | null;
    periodicCycleAmount: number;
    estimatedTime: string;
    company: Company | null;
    issueTypeId: string | number;
    isActive: boolean;
  };
  itemList: Item[];
  toolList: Tool[];
  userList: User[];
  customFields: {
    customField: CustomField;
    defaultValue: string | number | Date | string[];
  }[];
};

const IssueTemplateForm = ({ isLoading, issueTemplate, setIssueTemplateOpen }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Box pb={2}>
          <DataCard issueTemplate={issueTemplate} />
        </Box>
        <Box pb={2}>
          <PeriodsCard issueTemplate={issueTemplate} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box pb={2}>
          <ItemListCard issueTemplate={issueTemplate} />
        </Box>
        <Box pb={2}>
          <ToolListCard issueTemplate={issueTemplate} />
        </Box>
        <Box pb={2}>
          <UserListCard issueTemplate={issueTemplate} />
        </Box>
        <Box pb={2}>
          {/* egyedi mezo */}
          {account.user.isSuperAdmin || selectedRelTenant.isTenantAdmin ? (
            <CustomFieldCard issueTemplate={issueTemplate} />
          ) : null}
        </Box>
      </Grid>
      <Grid item xs={12} container justifyContent="center" style={{ gap: 8 }}>
        <Button
          color="primary"
          variant="text"
          onClick={() => {
            if (setIssueTemplateOpen) {
              setIssueTemplateOpen(null);
            } else {
              history.goBack();
            }
          }}
        >
          {t("common:button.cancel")}
        </Button>
        <Button type="submit" color="primary" disabled={isLoading}>
          {issueTemplate ? t("common:button.save") : t("common:button.create")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default IssueTemplateForm;
