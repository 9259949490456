import { Box, Card, Grid, Typography } from "@material-ui/core";
import { COLORS } from "config/theme";
import { useTranslation } from "react-i18next";
import { JobForm } from "shared/network/job-questionnaire.api";

type Props = {
  jobForm: JobForm;
};

const EducationDataCard = ({ jobForm }: Props) => {
  const { t } = useTranslation();

  return (
    <Card style={{ padding: 16 }}>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontSize: 20,
          fontWeight: "bold",
          paddingBottom: 8,
        }}
      >
        {t("jobForm.educationData.title")}
      </Typography>
      {jobForm?.jobQualificationEntries?.map((field, index) => {
        return (
          <Grid
            key={field.id}
            container
            spacing={1}
            alignItems="flex-end"
            style={{ marginBottom: 8 }}
          >
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between">
                <Typography style={{ fontWeight: "bold", fontSize: 16, color: COLORS.mainLight }}>
                  {t("jobForm.educationData.row", { number: index + 1 })}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography style={{ fontWeight: "bold" }}>
                {t("jobForm.educationData.certificateYear")}:
              </Typography>
              <Typography>{field?.certificateYear}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography style={{ fontWeight: "bold" }}>
                {t("jobForm.educationData.city")}:
              </Typography>
              <Typography>{field?.qualificationCity}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography style={{ fontWeight: "bold" }}>
                {t("jobForm.educationData.school")}:
              </Typography>
              <Typography>{field?.qualificationSchoolName}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography style={{ fontWeight: "bold" }}>
                {t("jobForm.educationData.profession")}:
              </Typography>
              <Typography>{field?.profession}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography style={{ fontWeight: "bold" }}>
                {t("jobForm.educationData.certificateNumber")}:
              </Typography>
              <Typography>{field?.certificateNumber}</Typography>
            </Grid>
          </Grid>
        );
      })}
    </Card>
  );
};

export default EducationDataCard;
