import { Box, Button, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { AddBox, Delete } from "@material-ui/icons";
import FormCard from "components/FormCard";
import { RootState } from "config/store";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { hasAuthority } from "shared/authorization";
import { Item, ItemCategory } from "shared/types";
import DeleteCategoryDialog from "./DeleteCategoryDialog";
import ItemCategoryDialog from "./ItemCategoryDialog";
import { ItemFormValues, useStyles } from "./ItemForm";

type Props = {
  item: Item | undefined;
  isModal: boolean;
  selectedCategories: ItemCategory[];
  setSelectedCategories: Dispatch<SetStateAction<ItemCategory[]>>;
  setCreateOpen: Dispatch<SetStateAction<boolean>>;
};

const ItemFormCategories = ({
  item,
  isModal,
  selectedCategories,
  setSelectedCategories,
  setCreateOpen,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { register, setValue } = useFormContext<ItemFormValues>();
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const [open, setOpen] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState<ItemCategory | null>(null);

  useEffect(() => {
    register("categories");
  }, [register]);

  useEffect(() => {
    let array: ItemCategory[] = [];
    item?.categories.forEach(link => {
      array.push(link.category);
    });
    setSelectedCategories(array);
    setValue("categories", selectedCategories);
  }, [item, setValue]); //eslint-disable-line

  return (
    <>
      {!isModal && (
        <FormCard title={t("item.categories")}>
          <Grid container spacing={1}>
            {selectedCategories.map(category => (
              <Grid item xs={12} md={6} container key={category.id}>
                <Box
                  className={classes.dataContainer}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography className={classes.text}>{category.name}</Typography>
                  <Tooltip title={t("companyCategory.delete").toString()} className={classes.cell}>
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => setDeleteTarget(category)}
                    >
                      <Delete fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Grid container item xs={12} justifyContent="center">
            {hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "COMPANY_ADMIN",
            ]) && (
              <Button
                onClick={() => setOpen(true)}
                variant="outlined"
                size="small"
                color="primary"
                startIcon={<AddBox />}
              >
                {t("common:button.add")}
              </Button>
            )}
          </Grid>
        </FormCard>
      )}
      <ItemCategoryDialog
        item={item}
        open={open}
        setOpen={setOpen}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        setCreateOpen={setCreateOpen}
      />
      <DeleteCategoryDialog
        item={item}
        deleteTarget={deleteTarget}
        setDeleteTarget={setDeleteTarget}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
      />
    </>
  );
};

export default ItemFormCategories;
