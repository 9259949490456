import { Grid, MenuItem, TextField } from "@material-ui/core";
import FormCard from "components/FormCard";
import { PERIODIC_CYCLE } from "config/constants";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IssueTemplate } from "shared/types";

type Props = {
  issueTemplate?: IssueTemplate;
};

const PeriodsCard = ({ issueTemplate }: Props) => {
  const { t } = useTranslation();

  return (
    <FormCard title={t("common:issueTemplateCyclesTitle")}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Controller
            name="issueTemplateEntry.periodicCycleType"
            defaultValue={issueTemplate?.periodicCycleType || PERIODIC_CYCLE[0]}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t("issueTemplate.formValues.periodicCycleType")}
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
                select
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              >
                {PERIODIC_CYCLE.map((period) => (
                  <MenuItem key={period} value={period}>
                    {t(`common:periodicCycle.${period}`)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="issueTemplateEntry.periodicCycleAmount"
            defaultValue={issueTemplate?.periodicCycleAmount || ""}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t("issueTemplate.formValues.periodicCycleAmount")}
                InputLabelProps={{ shrink: true }}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default PeriodsCard;
