import { Box, Button, Container, IconButton } from "@material-ui/core";
import { AddBox, Edit } from "@material-ui/icons";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getCheckItemPage } from "shared/network/check-item";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/DeliveryRound/DeliveryRoundList";
import HaccpCheckDialog from "./HaccpCheckDialog";

const HaccpCheck = () => {
  const { t } = useTranslation();
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any | null>(null);
  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-haccp-item-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-haccp-item-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: t("haccpItem.name"),
      flex: 1,
    },
    {
      field: "parent.title",
      headerName: t("haccpCheckItem.main"),
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => row?.parent?.title || "-",
    },
    {
      field: "isActive",
      headerName: t("haccpCheckItem.isActive"),
      flex: 0.5,
      renderCell: ({ row }: GridRenderCellParams) =>
        row.isActive ? t("haccpItem.status.ACTIVE") : t("haccpItem.status.INACTIVE"),
    },
    {
      field: "actions",
      headerName: " ",
      width: 68,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%" alignItems="center">
          <IconButton color="primary" onClick={() => setSelectedRow(row)}>
            <Edit />
          </IconButton>
        </Box>
      ),
    },
  ];

  const checkItemPageQuery = useQuery(
    ["checkItemPageQuery", page, pageSize, tenant.id, filterValue, sortState],
    async () => {
      const { data } = await getCheckItemPage(page, pageSize, tenant.id, filterValue, sortState);
      return data;
    },
  );

  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gridGap={16}
      >
        <Box>
          <Button startIcon={<AddBox />} onClick={() => setOpen(true)}>
            {t("haccpCheckItem.create")}
          </Button>
        </Box>
        <HaccpCheckDialog
          open={open}
          onClose={() => setOpen(false)}
          defaultValues={selectedRow}
          isModify={false}
        />
        <HaccpCheckDialog
          open={selectedRow}
          onClose={() => setSelectedRow(null)}
          defaultValues={selectedRow}
          isModify={true}
        />
        <PageableTable
          sortState={sortState}
          setSortState={setSortState}
          sessionStorageKey="haccp-item"
          tableState={tableState}
          setTableState={setTableState}
          columns={columns}
          query={checkItemPageQuery}
          minWidth={{ value: "sm" }}
        />
      </Box>
    </Container>
  );
};

export default HaccpCheck;
