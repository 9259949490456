import { Box, Checkbox, FormControlLabel, MenuItem, Typography } from "@material-ui/core";
import { RootState } from "config/store";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getCompanyById } from "shared/network/company.api";
import { listContactByTenant } from "shared/network/contact.api";
import { getJobReferredToPage, JobReferredTo } from "shared/network/job-referred-to.api";

type Props = {
  selectedJobForms: JobReferredTo[];
  setSelectedJobForms: Dispatch<SetStateAction<JobReferredTo[]>>;
  selectedEmails: string[];
  setSelectedEmails: Dispatch<SetStateAction<string[]>>;
  search: string;
  companyId?: number | string;
};

const CreateEmailJobForm = ({
  selectedJobForms,
  setSelectedJobForms,
  selectedEmails,
  setSelectedEmails,
  search,
  companyId,
}: Props) => {
  const { t } = useTranslation();
  const tenantId = useSelector(
    (state: RootState) => state.authentication?.selectedRelTenant?.tenant?.id,
  );

  const companyQuery = useQuery(["companyByIdEmailQuery", companyId], async () => {
    if (companyId) {
      const { data } = await getCompanyById(Number(companyId), tenantId);
      return data.item;
    }
    return Promise.reject();
  });
  const companyEmail = companyQuery.data?.notificationEmail;

  const getJobReferredToQuery = useQuery(
    ["getJobReferredToEmailQuery", tenantId],
    async () => {
      const { data } = await getJobReferredToPage(tenantId, 0, 2000, `company?.id:${companyId}`);
      return data;
    },
    { enabled: !!companyId },
  );

  const filteredList = getJobReferredToQuery.data?.page?.content?.filter(
    value =>
      value.user?.name?.toUpperCase()?.includes(search.toUpperCase()) ||
      value.user?.email?.toUpperCase()?.includes(search.toUpperCase()),
  );

  return (
    <Box overflow="auto" height={400}>
      {!!filteredList?.length
        ? filteredList?.map((contact, index) => {
            return (
              <Box key={index} width="100%">
                <MenuItem
                  style={{ height: 50 }}
                  onClick={() => {
                    const jobForms = [...selectedJobForms];
                    if (selectedJobForms?.find(co => co.id === contact.id)) {
                      setSelectedJobForms(jobForms.filter(us => us.id !== contact.id));
                    } else {
                      jobForms.push(contact);
                      setSelectedJobForms(jobForms);
                    }
                  }}
                >
                  <FormControlLabel
                    label=""
                    control={
                      <Checkbox
                        size="small"
                        color="primary"
                        checked={selectedJobForms?.indexOf(contact) > -1}
                      />
                    }
                  />
                  <Box>
                    <Typography style={{ fontWeight: "bold" }}>{contact?.user?.name}</Typography>
                    <Typography>{contact?.user?.email}</Typography>
                  </Box>
                </MenuItem>
              </Box>
            );
          })
        : !companyEmail && <Typography style={{ padding: 16 }}>A lista üres</Typography>}
    </Box>
  );
};

export default CreateEmailJobForm;
