import { faSignature } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItem, MenuList, Popover, Tooltip, Typography } from "@material-ui/core";
import { Check, GetApp } from "@material-ui/icons";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { hasAuthority } from "shared/authorization";
import { getIssueFile, getIssueTigFile } from "shared/network/files.api";
import { Issue } from "shared/types";
import { dowloadPdf } from "shared/util/fileDownload";
import NewSignatureConfirm from "./NewSignatureDialog";
import { useState } from "react";
import { createIssueTig, createIssueWorksheet } from "shared/network/issues.api";
import { COLORS } from "config/theme";

type Props = {
  issue: Issue;
  setLoadingType: any;

  setType: any;
  anchorEl: any;
  setAnchorEl: any;
  popoverType: "WORKSHEET" | "TIG";
  refetch: () => void;
};

const WorksheetPopover = ({
  issue,
  setLoadingType,
  setType,
  anchorEl,
  setAnchorEl,
  popoverType,
  refetch,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant?.tenant;
  const [open, setOpen] = useState(false);

  async function downloadPDF(issue: Issue, type: "WORKSHEET" | "TIG") {
    setLoadingType(type);
    if (issue?.id) {
      try {
        if (type === "TIG") {
          const { data } = await getIssueTigFile(issue.id?.toString(), tenant?.id);
          dowloadPdf(data, issue?.serialNumber?.replace("MUNKALAP", "TIG"));
        } else {
          const { data } = await getIssueFile(issue.id?.toString(), tenant?.id);
          dowloadPdf(data, issue?.serialNumber);
        }
      } catch (error: any) {
        enqueueSnackbar(
          t("common:notification.download.failure", { subject: t(`common:pdfDownload.${type}`) }),
          { variant: "error" },
        );
      }
    }
    setLoadingType(null);
  }

  const onSubmitCreate = async (issue: Issue, type: "WORKSHEET" | "TIG") => {
    setLoadingType(type);
    try {
      if (type === "WORKSHEET") {
        await createIssueWorksheet({
          issueId: issue?.id?.toString(),
          tenantId: tenant.id.toString(),
          useOld: true,
        });
      } else {
        await createIssueTig({
          issueId: issue?.id?.toString(),
          tenantId: tenant.id.toString(),
          useOld: true,
        });
      }
      downloadPDF(issue, type);
      enqueueSnackbar("Sikeres letöltés.", {
        variant: "success",
      });
      refetch?.();
    } catch (e: any) {
      enqueueSnackbar(t("Sikertelen letöltés"), {
        variant: "error",
      });
    }
    setLoadingType(null);
  };

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <MenuList>
        {popoverType === "WORKSHEET" &&
          hasAuthority(account.user, account.permissions, selectedRelTenant, ["ISSUE_ADMIN"]) && (
            <>
              <Tooltip
                title={issue?.companyWorksheetUserSignBase64 ? t("issues.worksheetSigned") : ""}
              >
                <MenuItem
                  disabled={!issue?.worksheet}
                  onClick={() => {
                    if (!issue?.companyWorksheetUserSignBase64) {
                      setType("DOWNLOAD");
                      setAnchorEl(null);
                    } else {
                      setOpen(true);
                    }
                  }}
                >
                  <FontAwesomeIcon
                    icon={faSignature}
                    style={{ width: 24, height: 24, marginRight: 12 }}
                  />
                  <Typography variant="inherit" style={{ flex: 1 }}>
                    {t("issues.signature")}
                  </Typography>
                  {issue?.companyWorksheetUserSignBase64 && (
                    <Check style={{ marginLeft: 12, color: COLORS.green }} />
                  )}
                </MenuItem>
              </Tooltip>
              <NewSignatureConfirm
                onSubmit={() => {
                  setType("DOWNLOAD");
                  setAnchorEl(null);
                }}
                title={t("issues.signAgainTitle")}
                body={t("issues.signAgainWorksheet")}
                open={open}
                setOpen={setOpen}
              />
            </>
          )}
        {popoverType === "WORKSHEET" &&
          !!issue?.worksheet &&
          hasAuthority(account.user, account.permissions, selectedRelTenant, ["ISSUE_GET"]) && (
            <Tooltip title={issue?.worksheetPath ? t("issues.worksheetGenerated") : ""}>
              <MenuItem
                onClick={() => {
                  onSubmitCreate(issue, "WORKSHEET");
                  setAnchorEl(null);
                }}
              >
                <GetApp style={{ width: 24, height: 24, marginRight: 12 }} />
                <Typography variant="inherit" style={{ flex: 1 }}>
                  {t("issues.download")}
                </Typography>
                {issue?.worksheetPath && <Check style={{ marginLeft: 12, color: COLORS.green }} />}
              </MenuItem>
            </Tooltip>
          )}
        {popoverType === "TIG" &&
          hasAuthority(account.user, account.permissions, selectedRelTenant, ["ISSUE_ADMIN"]) && (
            <>
              <Tooltip title={issue?.companyTigUserSignBase64 ? t("issues.tigSigned") : ""}>
                <MenuItem
                  disabled={!issue?.worksheet}
                  onClick={() => {
                    if (!issue?.companyTigUserSignBase64) {
                      setType("TIG_DOWNLOAD");
                      setAnchorEl(null);
                    } else {
                      setOpen(true);
                    }
                  }}
                >
                  <FontAwesomeIcon
                    icon={faSignature}
                    style={{ width: 24, height: 24, marginRight: 12 }}
                  />
                  <Typography variant="inherit" style={{ flex: 1 }}>
                    {t("issues.signature")}
                  </Typography>
                  {issue?.companyTigUserSignBase64 && (
                    <Check style={{ marginLeft: 12, color: COLORS.green }} />
                  )}
                </MenuItem>
              </Tooltip>
              <NewSignatureConfirm
                onSubmit={() => {
                  setType("TIG_DOWNLOAD");
                  setAnchorEl(null);
                }}
                title={t("issues.signAgainTitle")}
                body={t("issues.signAgainTig")}
                open={open}
                setOpen={setOpen}
              />
            </>
          )}
        {popoverType === "TIG" &&
          !!issue?.worksheet &&
          hasAuthority(account.user, account.permissions, selectedRelTenant, ["ISSUE_GET"]) && (
            <Tooltip title={issue?.tigPath ? t("issues.tigGenerated") : ""}>
              <MenuItem
                onClick={() => {
                  onSubmitCreate(issue, "TIG");
                  setAnchorEl(null);
                }}
              >
                <GetApp style={{ width: 24, height: 24, marginRight: 12 }} />
                <Typography variant="inherit" style={{ flex: 1 }}>
                  {t("issues.download")}
                </Typography>
                {!!issue?.tigPath && <Check style={{ marginLeft: 12, color: COLORS.green }} />}
              </MenuItem>
            </Tooltip>
          )}
      </MenuList>
    </Popover>
  );
};

export default WorksheetPopover;
