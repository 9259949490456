import { Box, Button, Card, Container, Grid, makeStyles, Typography } from "@material-ui/core";
import { AddBox } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listIssueStatusPageable } from "shared/network/issue-status.api";
import { useGetSessionStorageKey } from "views/Comment/function";
import CustomIssueStatusRow from "./IssueStatusRow";

const useStyles = makeStyles({
  divider: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
    marginTop: 8,
  },
  header: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
    alignItems: "center",
  },
  row: {
    alignItems: "center",
    borderBottom: `1px solid ${COLORS.mainGrey}`,
  },
  cell: {
    padding: 8,
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const IssueStatusList = () => {
  const { t }: any = useTranslation();
  const classes = useStyles();
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant?.tenant;

  const [page, setPage] = useState(
    parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("IssueStatusPageNumber")) ||
        JSON.stringify(0),
    ),
  );

  const issueStatusListQuery = useQuery(["issueStatusList", tenant.id, page], async () => {
    const { data } = await listIssueStatusPageable(page, 20, tenant.id, "isDeleted=false");
    return data;
  });

  return (
    <Card style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
      <Loading open={issueStatusListQuery.isFetching} />
      {issueStatusListQuery.isError ? (
        <Box style={{ margin: "20px auto", textAlign: "center" }}>
          <Typography
            variant="h5"
            align="center"
            color="secondary"
            style={{ marginBottom: "20px" }}
          >
            {t("common:errorWhileFetching")}
          </Typography>
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "ISSUE_TYPE_ADMIN",
          ]) && (
            <Box display="flex" justifyContent="flex-end">
              <Button
                component={Link}
                to={`/issues/issue-type/issue-type-create`}
                startIcon={<AddBox />}
              >
                {t("issueStatus.create")}
              </Button>
            </Box>
          )}
        </Box>
      ) : (
        <>
          {issueStatusListQuery.data?.page.numberOfElements === 0 ? (
            <Box style={{ margin: "20px auto", textAlign: "center" }}>
              <Typography
                variant="h5"
                align="center"
                color="secondary"
                style={{ marginBottom: "20px" }}
              >
                {t("common:noData")}
              </Typography>
              <Button component={Link} to={`/issues/status/create`} startIcon={<AddBox />}>
                {t("issueStatus.create")}
              </Button>
            </Box>
          ) : (
            <Container maxWidth="sm">
              <Grid container className={classes.header}>
                <Grid item xs={6} className={classes.cell}>
                  <Typography variant="body2" style={{ fontSize: 16 }} className={classes.text}>
                    {t("issueStatus.formValues.name")}
                  </Typography>
                </Grid>
                <Grid item container xs={6} justifyContent="flex-end" className={classes.cell}>
                  <Button component={Link} to={`/issues/status/create`} startIcon={<AddBox />}>
                    {t("issueStatus.create")}
                  </Button>
                </Grid>
              </Grid>
              {issueStatusListQuery.data?.page.numberOfElements ? (
                issueStatusListQuery.data.page.content.map(issueStatus => (
                  <CustomIssueStatusRow key={issueStatus.id} issueStatus={issueStatus} />
                ))
              ) : (
                <Grid item container xs={12} justifyContent="center" style={{ margin: 12 }}>
                  <Box textAlign="center">
                    <Typography color="secondary" style={{ fontSize: 20, width: "100%" }}>
                      {t("common:emptyIssueTypeList")}
                    </Typography>
                  </Box>
                </Grid>
              )}
              {issueStatusListQuery.data && issueStatusListQuery?.data?.page.totalPages > 1 && (
                <Pagination
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "24px 0",
                  }}
                  count={issueStatusListQuery?.data?.page.totalPages}
                  color="primary"
                  page={issueStatusListQuery.data.page.number + 1}
                  onChange={(e, page) => {
                    sessionStorage.setItem(
                      `IssueStatusPageNumber-${tenant?.id}`,
                      JSON.stringify(page - 1),
                    );
                    setPage(page - 1);
                  }}
                />
              )}
            </Container>
          )}
        </>
      )}
    </Card>
  );
};

export default IssueStatusList;
