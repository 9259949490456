import { faDownload, faEdit, faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import { AddBox, Assignment, Edit, GetApp, Replay } from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { INVOICE_TYPE } from "config/constants";
import { RootState } from "config/store";
import supportedLocales from "config/supportedLocales";
import { format, isPast, subDays } from "date-fns";
import i18n from "i18n";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { getInvoiceFile } from "shared/network/files.api";
import { listInvoicePageable } from "shared/network/invoice.api";
import { Invoice, InvoiceItem } from "shared/types";
import { fileDownload, pdfDownload } from "shared/util/fileDownload";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";
import InvoiceStatusModifyDialog from "./InvoiceStatusModifyDialog";
import { useColoredRowStyles } from "views/Issues/NewIssueOverviewList";
import { getFilterAndSearchItemName } from "views/Admin/Tenants/TenantAllInvoice";
import Loading from "components/Loading";
import DocumentUpload from "./components/DocumentUpload";
import { COLORS } from "config/theme";
import { getFileByPath } from "shared/network/file.api";

const InvoiceList = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { setHeaderButtons } = useHeader();
  const colorClasses = useColoredRowStyles();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  const history = useHistory();
  const isCompany = location.pathname.includes("/companies");
  const isAll = location.pathname.includes("/list-all");
  const isCustomer = location.pathname.includes("/list-customer");
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey(`rap-invoice-list-${id}-page-number`),
      ) || JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey(`rap-invoice-list-${id}-page-size`)) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const invoiceListQuery = useQuery(
    ["listInvoicePageable", page, pageSize, selectedRelTenant.tenant.id, filterValue, sortState],
    async () => {
      const filterContent = getFilterAndSearchItemName(filterValue);

      const { data } = await listInvoicePageable(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        id ? `customerId:${id};` + filterContent.filter : `customerId:${id}`,
        sortState,
        filterContent.itemName,
      );
      return data;
    },
  );

  async function onDownload(invoice: Invoice) {
    setDocOpenLoading(true);
    if (invoice?.id) {
      try {
        const { data } = await getFileByPath(
          invoice?.uploadedDocumentPath,
          selectedRelTenant?.tenant.id,
        );
        fileDownload(data, `${t("common:pdfDownload.INVOICE")}_${invoice?.id || ""}`);
      } catch (e) {
        enqueueSnackbar(
          t("common:notification.download.failure", {
            subject: t("comment.subject"),
          }),
          {
            variant: "error",
          },
        );
      }
    }
    setDocOpenLoading(false);
  }

  const columns: GridColDef[] = [
    {
      field: "serialNumber",
      headerName: t("invoice.serialNumber"),
      flex: 1,
      type: "stringContainsNumber",
    },
    {
      field: "customerName",
      headerName: t("invoice.formValues.customer.customerName"),
      flex: 2,
      type: "stringContainsNumber",
    },
    {
      field: "creationDate",
      headerName: t("invoice.formValues.creationDate"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => {
        return row.creationDate
          ? format(new Date(row.creationDate), "P", { locale: supportedLocales[i18n.language] })
          : "";
      },
      type: "date",
    },
    {
      field: "completionDate",
      headerName: t("invoice.formValues.completionDate"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => {
        return row.completionDate
          ? format(new Date(row.completionDate), "P", { locale: supportedLocales[i18n.language] })
          : "";
      },
      type: "date",
    },
    {
      field: "paymentDeadline",
      headerName: t("invoice.formValues.paymentDeadline"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => {
        return row.paymentDeadline
          ? format(new Date(row.paymentDeadline), "P", { locale: supportedLocales[i18n.language] })
          : "";
      },
      type: "date",
    },
    {
      field: "invoiceType",
      headerName: t("invoice.formValues.invoiceType.title"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        t(`invoice.formValues.invoiceType.${row.invoiceType}`),
      type: "select",
    },
    {
      field: "grossPrice",
      headerName: t("invoice.invoiceItem.grossPrice"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => {
        let temp = 0;
        row.invoiceItems?.forEach(
          (invoiceItem: InvoiceItem) =>
            (temp += Number.parseInt(invoiceItem.grossPrice.toString())),
        );
        return t("common:number", { num: temp }) + " Ft";
      },
    },
    {
      field: "actions",
      headerName: " ",
      width: 180,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["INVOICE_ADMIN"]) &&
            row.pdfPath && (
              <Tooltip title={t("invoice.downloadPdf").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  onClick={async () => {
                    if (row.id) {
                      try {
                        const { data } = await getInvoiceFile(row.id, selectedRelTenant.tenant.id);
                        pdfDownload(data, row?.serialNumber, t("common:pdfDownload.INVOICE"));
                        enqueueSnackbar(
                          t("common:notification.download.success", {
                            subject: t("invoice.subject"),
                          }),
                          {
                            variant: "success",
                          },
                        );
                      } catch {
                        enqueueSnackbar(
                          t("common:notification.download.failure", {
                            subject: t("invoice.subject"),
                          }),
                          {
                            variant: "error",
                          },
                        );
                      }
                    }
                  }}
                >
                  <GetApp />
                </IconButton>
              </Tooltip>
            )}
          {!row?.uploadedDocumentPath ? (
            <Tooltip title={t("invoice.document.add").toString()}>
              <IconButton
                color="primary"
                size="small"
                style={{ marginRight: 4 }}
                onClick={() => setSelectedPicInvoice(row as Invoice)}
              >
                <FontAwesomeIcon
                  icon={faFileArrowUp}
                  style={{
                    fontSize: 20,
                    width: 20,
                    color: COLORS.main,
                  }}
                />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={t("invoice.document.download").toString()}>
              <IconButton
                color="primary"
                size="small"
                style={{ marginRight: 4 }}
                onClick={async () => onDownload(row as Invoice)}
              >
                <FontAwesomeIcon
                  icon={faDownload}
                  style={{
                    fontSize: 20,
                    width: 20,
                    color: COLORS.main,
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["INVOICE_GET"]) && (
            <Tooltip title={t("invoice.details").toString()}>
              <IconButton
                component={Link}
                to={
                  isCompany
                    ? `/companies/${
                        isAll ? "list-all" : isCustomer ? "list-customer" : "list-supplier"
                      }/company-details/invoices/invoice-details?id=${row.id}&backId=${id}`
                    : ``
                }
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
              >
                <Assignment color="primary" />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["INVOICE_ADMIN"]) &&
            row.status === "PENDING" && (
              <Tooltip title={t("invoice.modify").toString()}>
                <IconButton
                  component={Link}
                  to={
                    isCompany
                      ? `/companies/${
                          isAll ? "list-all" : isCustomer ? "list-customer" : "list-supplier"
                        }/company-details/invoices/invoice-modify?id=${row.id}&backId=${id}`
                      : ``
                  }
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  disabled={row.status !== "PENDING"}
                >
                  <Edit color="primary" />
                </IconButton>
              </Tooltip>
            )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["INVOICE_ADMIN"]) &&
            row.status !== "PENDING" && (
              <Tooltip title={t("invoice.storno").toString()}>
                <IconButton size="small" color="primary" style={{ margin: "0 8px" }}>
                  <Replay color="primary" />
                </IconButton>
              </Tooltip>
            )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "INVOICE_ADMIN",
          ]) && (
            <Tooltip title={t("invoice.modifyStatus").toString()}>
              <IconButton
                size="small"
                style={{ margin: "0 8px" }}
                onClick={() => setSelectedInvoice(row as Invoice)}
                color="primary"
              >
                <FontAwesomeIcon icon={faEdit} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      history.push(`/invoices/invoices-list/invoice-details?id=${params.row.id}`);
    }
  }

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["INVOICE_ADMIN"]) && (
          <Button
            component={Link}
            to={`/invoices/invoices-list/invoice-create`}
            startIcon={<AddBox />}
          >
            {t("invoice.create")}
          </Button>
        )}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant, filterValue]); //eslint-disable-line

  const [docOpenLoading, setDocOpenLoading] = useState(false);
  const [selectedPicInvoice, setSelectedPicInvoice] = useState<Invoice | null>(null);

  return (
    <>
      <Loading open={docOpenLoading} />
      {selectedPicInvoice?.id && (
        <DocumentUpload
          subjectType={"INVOICE_DOCUMENT"}
          subjectId={selectedPicInvoice.id}
          open={!!selectedPicInvoice}
          setOpen={() => setSelectedPicInvoice(null)}
          refetch={() => invoiceListQuery?.refetch()}
        />
      )}
      <InvoiceStatusModifyDialog
        selectedInvoice={selectedInvoice}
        setSelectedInvoice={setSelectedInvoice}
        refetch={invoiceListQuery.refetch}
      />
      <PageableTable
        filterOptions={[
          {
            columnName: "invoiceType",
            options: INVOICE_TYPE.map(value => {
              return {
                translated: t(`invoice.formValues.invoiceType.${value}`),
                value,
              };
            }),
          },
        ]}
        filterable={["customerName", "creationDate", "serialNumber"]} //ha backenden jó, akkor ezt ki kell szedni
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey={`invoice-list-${id}`}
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={invoiceListQuery}
        onCellClick={onCellClick}
        minWidth={{ value: "xl" }}
        getRowClassName={params => {
          if (selectedRelTenant?.tenant?.isInvoiceColoring) {
            if (params.row?.paidDate) {
              return colorClasses.green;
            } else if (isPast(new Date(params.row?.paymentDeadline))) {
              return colorClasses.red;
            } else if (isPast(subDays(new Date(params.row?.paymentDeadline), 2))) {
              return colorClasses.orange;
            } else {
              return colorClasses.green;
            }
          } else {
            return "";
          }
        }}
      />
    </>
  );
};

export default InvoiceList;
