import DetailsCard from "components/DetailsCard";
import supportedLocales from "config/supportedLocales";
import { format } from "date-fns";
import i18n from "i18n";
import { useTranslation } from "react-i18next";
import { JobForm } from "shared/network/job-questionnaire.api";
import displayAddress from "shared/util/displayAddress";

type Props = {
  jobForm: JobForm;
};

const ContactDataCard = ({ jobForm }: Props) => {
  const { t } = useTranslation();

  return (
    <DetailsCard
      style={{ height: "100%" }}
      title={t("jobForm.personalData.title")}
      columns="flex"
      columnFlexRule={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
      dataList={[
        {
          name: t("jobForm.personalData.orderNumber"),
          value: jobForm?.orderNumber || "-",
          hide: !jobForm?.orderNumber,
        },
        {
          name: t("jobForm.personalData.name"),
          value: jobForm?.name || "-",
        },

        {
          name: t("jobForm.personalData.birthName"),
          value: jobForm?.birthName || "-",
        },
        {
          name: t("jobForm.personalData.birthPlace"),
          value: jobForm?.birthPlace || "-",
        },
        {
          name: t("jobForm.personalData.birthDate"),
          value: jobForm?.birthDate
            ? format(new Date(jobForm.birthDate), "P", {
                locale: supportedLocales[i18n.language],
              })
            : "-",
        },
        {
          name: t("jobForm.personalData.idCardNumber"),
          value: jobForm?.idCardNumber || "-",
        },
        {
          name: t("jobForm.personalData.addressCardNumber"),
          value: jobForm?.addressCardNumber || "-",
        },
        {
          name: t("jobForm.personalData.address"),
          value: jobForm?.address ? displayAddress(jobForm?.address) : "-",
        },
        {
          name: t("jobForm.personalData.tempAddress"),
          value: jobForm?.address ? displayAddress(jobForm?.address) : "-",
        },
      ]}
    />
  );
};

export default ContactDataCard;
