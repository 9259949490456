import { Skeleton, SkeletonProps } from "@material-ui/lab";

const InputSkeleton = (props: SkeletonProps) => (
  <Skeleton
    variant="rect"
    height={40}
    {...props}
    style={{ display: "inline-block", borderRadius: 4 }}
  />
);

export default InputSkeleton;
