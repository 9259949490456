import { Box, Button, Container, Grid, MenuItem, TextField } from "@material-ui/core";
import FormCard from "components/FormCard";
import { STATUSES } from "config/constants";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { IssueStatus } from "shared/network/issue-status.api";

type Props = {
  issueStatus?: IssueStatus;
};

export type IssueStatusFormValues = {
  name: string;
  status: string;
};

const IssueStatusForm = ({ issueStatus }: Props) => {
  const { t }: any = useTranslation();
  const history = useHistory();
  const { control, setValue } = useFormContext<IssueStatusFormValues>();

  useEffect(() => {
    if (issueStatus) {
      setValue("status", issueStatus?.status || "");
    }
  }, [issueStatus, setValue]);

  return (
    <Container maxWidth="xs">
      <FormCard title={t("issueStatus.title")}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="name"
              defaultValue={issueStatus?.name || ""}
              rules={{ required: t("validation.required").toString() }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true, required: true }}
                  label={t("issueStatus.formValues.name")}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={12} md={12}>
            <Controller
              name="status"
              defaultValue={""}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("issueStatus.formValues.type")}
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ displayEmpty: true }}
                  select
                >
                  <MenuItem value="">{t("common:choose")}</MenuItem>
                  {STATUSES.map(status => (
                    <MenuItem key={status} value={status}>
                      {t(`common:statuses.${status}`)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center" mt={2} gridGap={8}>
          <Button color="primary" variant="text" onClick={() => history.goBack()}>
            {t("common:button.cancel")}
          </Button>
          <Button type="submit" color="primary">
            {t("common:button.save")}
          </Button>
        </Box>
      </FormCard>
    </Container>
  );
};

export default IssueStatusForm;
