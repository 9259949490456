import { Box, CircularProgress, Container } from "@material-ui/core";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SliceStatus } from "shared/common";
import { createIssueStatus } from "shared/network/issue-status.api";
import IssueStatusForm, { IssueStatusFormValues } from "./IssueStatusForm";

const IssueStatusCreate = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const form = useForm<IssueStatusFormValues>();
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);
  const [status, setStatus] = useState<SliceStatus>("idle");

  const onSubmitCreate = async (values: IssueStatusFormValues) => {
    try {
      setStatus("pending");
      await createIssueStatus(
        {
          ...values,
          tenantId: tenant.id,
        },
        tenant.id,
      );
      setStatus("success");
      enqueueSnackbar(t("common:notification.create.success"), { variant: "success" });

      history.goBack();
    } catch (e) {
      enqueueSnackbar(t("common:notification.create.failure"), { variant: "error" });
      setStatus("failure");
    }
  };

  return (
    <Container maxWidth="lg">
      <form onSubmit={form.handleSubmit(onSubmitCreate)}>
        {status === "pending" ? (
          <Box display="flex" alignItems="center" justifyContent="center" height="300px">
            <CircularProgress />
          </Box>
        ) : (
          <FormProvider {...form}>
            <IssueStatusForm />
          </FormProvider>
        )}
      </form>
    </Container>
  );
};

export default IssueStatusCreate;
