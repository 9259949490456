import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import { AddBox, Assignment, Edit } from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listCompanyPageable } from "shared/network/company.api";
import { getTagPage } from "shared/network/tag.api";
import { Company } from "shared/types";
import displayAddress from "shared/util/displayAddress";
import { useGetSessionStorageKey } from "views/Comment/function";
import RelCreateTag from "views/Tag/RelCreateTag";
import RelModifyTag from "views/Tag/RelModifyTag";
import TagIcon from "views/Tag/TagIcon";
import { getTagColumnWidth } from "views/User/UserList";

export type TableState = {
  page: number;
  pageSize: number;
  filterOpen: boolean;
  filterValue: string;
};

const CompanyList = () => {
  const { t } = useTranslation();
  const { setHeaderButtons } = useHeader();
  const history = useHistory();

  const location = useLocation();
  const isCustomer = location.pathname.includes("/list-customer");
  const isSupplier = location.pathname.includes("/list-supplier");

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-company-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-company-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const companyListQuery = useQuery(
    [
      "companyList",
      selectedRelTenant.tenant.id,
      page,
      pageSize,
      account?.user?.isSuperAdmin,
      filterValue,
      sortState,
      isCustomer,
      isSupplier,
    ],
    async () => {
      const { data } = await listCompanyPageable(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        (isCustomer ? "isCustomer:true;" : "") +
          (isSupplier ? "isSupplier:true;" : "") +
          filterValue,
        sortState,
      );
      return data;
    },
  );

  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [selectedToModify, setSelectedToModify] = useState<Company | null>(null);

  const columns: GridColDef[] = [
    {
      field: "tagSearch",
      headerName: t("tags.title"),
      width: getTagColumnWidth(companyListQuery?.data?.page?.content || []),
      sortable: false,
      filterable: true,
      type: "select",
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" width="100%">
          <TagIcon
            row={row}
            relTagList={row?.relTagSubjectEntryList}
            setSelectedRow={setSelectedRow}
            setSelectedToModify={setSelectedToModify}
          />
        </Box>
      ),
    },
    {
      field: "nameSearch",
      headerName: t("company.formValues.name"),
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => row.name,
    },
    {
      field: "notificationEmail",
      headerName: t("company.formValues.email"),
      flex: 1,
      type: "stringContainsNumber",
    },
    {
      field: "phone",
      headerName: t("company.formValues.phone"),
      flex: 1,
      type: "stringContainsNumber",
    },
    {
      field: "address.country",
      headerName: t("company.formValues.address.country"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.address.country,
    },
    {
      field: "address.city",
      headerName: t("company.formValues.address.city"),
      flex: 1,
      hide: true,
      valueGetter: ({ row }: GridValueGetterParams) => row.address.city,
    },
    {
      field: "address.zipCode",
      headerName: t("company.formValues.address.zipCode"),
      flex: 1,
      hide: true,
      type: "stringContainsNumber",
      valueGetter: ({ row }: GridValueGetterParams) => row.address.zipCode,
    },
    {
      field: "address.areaName",
      headerName: t("company.formValues.address.areaName"),
      flex: 1,
      hide: true,
      type: "areaNameAndType",
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.address.areaName + " " + row.address.areaType,
    },
    {
      field: "address",
      headerName: t("company.addressTitle"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => displayAddress(row.address),
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "COMPANY_GET",
            "COMPANY_ADMIN",
          ]) && (
            <Tooltip title={t("company.details").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                component={Link}
                to={`/companies/${
                  isCustomer ? "list-customer" : isSupplier ? "list-supplier" : "list-all"
                }/company-details?id=${row.id}&from=company${
                  isSupplier ? `&isSupplier=${isSupplier}` : ""
                }`}
              >
                <Assignment />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "COMPANY_GET",
            "COMPANY_ADMIN",
          ]) && (
            <Tooltip title={t("company.modify").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                component={Link}
                to={`/companies/${
                  isCustomer ? "list-customer" : isSupplier ? "list-supplier" : "list-all"
                }/company-modify?id=${row.id}`}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  useEffect(
    () => {
      setHeaderButtons(
        <Box display="flex" gridGap={12}>
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "COMPANY_ADMIN",
          ]) && (
            <Button
              component={Link}
              to={`/companies/${
                isCustomer ? "list-customer" : isSupplier ? "list-supplier" : "list-all"
              }/company-create`}
              variant="contained"
              style={{ margin: "auto" }}
              startIcon={<AddBox />}
            >
              {t("company.create")}
            </Button>
          )}
        </Box>,
      );
      return () => {
        setHeaderButtons(null);
      };
    },
    /*eslint-disable*/
    [t, account.user, account.permissions, selectedRelTenant, filterValue, isCustomer, isSupplier],
    /*eslint-enable*/
  );

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions" && params.field !== "tagSearch") {
      history.push(
        `/companies/${
          isCustomer ? "list-customer" : isSupplier ? "list-supplier" : "list-all"
        }/company-details?id=${params.row.id}&isSupplier=${isSupplier}`,
      );
    }
  }

  const tagQuery = useQuery(["tagCOMPANYQuery", selectedRelTenant?.tenant?.id], async () => {
    const { data } = await getTagPage(
      selectedRelTenant?.tenant?.id,
      0,
      1000,
      `(tagType:COMPANY;(OR)tagType(IS_NULL)1;)`,
    );

    return data;
  });

  return (
    <>
      {!!selectedRow && (
        <RelCreateTag
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          refetch={companyListQuery.refetch}
          subjectType={"COMPANY"}
        />
      )}
      {!!selectedToModify && (
        <RelModifyTag
          selectedToModify={selectedToModify}
          setSelectedToModify={setSelectedToModify}
          refetch={companyListQuery.refetch}
          subjectType={"COMPANY"}
        />
      )}
      <PageableTable
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="company"
        filterable={[
          "tagSearch",
          "nameSearch",
          "notificationEmail",
          "phone",
          "address.country",
          "address.city",
          "address.zipCode",
          "address.areaName",
        ]}
        filterOptions={[
          {
            columnName: "tagSearch",
            options: tagQuery?.data?.page?.content?.map(value => {
              return {
                translated: value?.tag || "",
                value: "x" + value?.id + "y",
              };
            }),
          },
        ]}
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={companyListQuery}
        onCellClick={onCellClick}
      />
    </>
  );
};

export default CompanyList;
