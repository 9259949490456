import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { KeyboardDatePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import { NUMBER_FORMAT } from "config/constants";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IssueFromValues } from "../IssueCreate";

type Props = {
  type: string;
  name: string;
  defaultValue: string;
  htmlAttributes: any;
  index: number;
  label: string;
  fieldValues: string;
};

const CustomField = ({
  type,
  name,
  defaultValue,
  htmlAttributes,
  index,
  label,
  fieldValues,
}: Props) => {
  const { control } = useFormContext<IssueFromValues>();
  const { t } = useTranslation();

  return (
    <>
      {type === "TEXTFIELD" ? (
        <Grid key={index} item sm={12} md={4}>
          <Controller
            control={control}
            name={`custom.${name}`}
            defaultValue={defaultValue ? defaultValue : ""}
            rules={{
              required: htmlAttributes.required ? t("validation.required").toString() : false,
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={label}
                InputLabelProps={{
                  shrink: true,
                  required: !!htmlAttributes.required,
                }}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
      ) : type === "NUMBER" ? (
        <Grid key={index} item sm={12} md={4}>
          <Controller
            control={control}
            name={`custom.${name}`}
            defaultValue={defaultValue ? defaultValue : ""}
            rules={{
              required: htmlAttributes.required ? t("validation.required").toString() : false,
              validate: value => {
                if (value && !value.toString().match(NUMBER_FORMAT)) {
                  return t("common:validation.numberFormat").toString();
                }
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={label}
                InputLabelProps={{
                  shrink: true,
                  required: !!htmlAttributes.required,
                }}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
      ) : type === "TEXTAREA" ? (
        <Grid key={index} item sm={12} md={4}>
          <Controller
            control={control}
            name={`custom.${name}`}
            defaultValue={defaultValue ? defaultValue : ""}
            rules={{
              required: htmlAttributes.required ? t("validation.required").toString() : false,
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                multiline
                minRows={htmlAttributes.rows || 3}
                label={label}
                InputLabelProps={{
                  shrink: true,
                  required: !!htmlAttributes.required,
                }}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
      ) : type === "DATE" ? (
        <Grid key={index} item sm={12} md={4}>
          <Controller
            control={control}
            name={`custom.${name}`}
            defaultValue={defaultValue ? new Date(defaultValue) : new Date()}
            rules={{
              required: htmlAttributes.required ? t("validation.required").toString() : false,
            }}
            render={({ field, fieldState }) => (
              <KeyboardDatePicker
                {...field}
                ref={undefined}
                variant="inline"
                label={label}
                format="yyyy.MM.dd."
                InputLabelProps={{
                  shrink: true,
                  required: !!htmlAttributes.required,
                }}
                autoOk
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
      ) : type === "DATETIME" ? (
        <Grid key={index} item sm={12} md={4}>
          <Controller
            control={control}
            name={`custom.${name}`}
            defaultValue={defaultValue ? new Date(defaultValue) : new Date()}
            rules={{
              required: htmlAttributes.required ? t("validation.required").toString() : false,
            }}
            render={({ field, fieldState }) => (
              <KeyboardDateTimePicker
                {...field}
                ref={undefined}
                variant="inline"
                label={label}
                format="yyyy.MM.dd. HH:mm"
                ampm={false}
                InputLabelProps={{
                  shrink: true,
                  required: !!htmlAttributes.required,
                }}
                autoOk
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
      ) : type === "SELECT" ? (
        <Grid key={index} item sm={12} md={4}>
          <Controller
            name={`custom.${name}`}
            defaultValue={defaultValue ? defaultValue : ""}
            rules={{
              required: htmlAttributes.required ? t("validation.required").toString() : false,
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                InputLabelProps={{
                  shrink: true,
                  required: !!htmlAttributes.required,
                }}
                SelectProps={{ displayEmpty: true }}
                label={
                  label === "A szerződés szerinti 30 perces működési vizsgálatot az ügyfél kéri"
                    ? "Működési vizsgálat"
                    : label
                }
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                select
              >
                <MenuItem value="">
                  <>{t("common:choose")}</>
                </MenuItem>
                {!!fieldValues &&
                  JSON.parse(fieldValues).map((value: { value: string }, index: number) => (
                    <MenuItem key={index} value={value.value}>
                      {value.value}
                    </MenuItem>
                  ))}
              </TextField>
            )}
          />
        </Grid>
      ) : type === "CHECKBOX" ? (
        <Grid key={index} item sm={12} md={4}>
          <Controller
            name={`custom.${name}`}
            defaultValue={defaultValue ? JSON.parse(defaultValue) : []}
            render={({ field }) => (
              <FormGroup>
                <InputLabel shrink>{label}</InputLabel>
                {!!fieldValues &&
                  JSON.parse(fieldValues).map((value: { value: string }, index: number) => (
                    <FormControlLabel
                      key={index}
                      labelPlacement="end"
                      label={value.value}
                      control={
                        <Checkbox
                          {...field}
                          onChange={event => {
                            if (event.target.checked) {
                              field.onChange([...(field.value as string[]), value.value]);
                            } else {
                              (field.value as string[]).splice(
                                (field.value as string[]).findIndex(temp => temp === value.value),
                                1,
                              );
                            }
                          }}
                        />
                      }
                    />
                  ))}
              </FormGroup>
            )}
          />
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};

export default CustomField;
