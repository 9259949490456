import { faBell, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import DetailsCard from "components/DetailsCard";
import Loading from "components/Loading";
import SingleQueryTable from "components/PageableTable/SingleQueryTable";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { getCompanyUsersById } from "shared/network/company.api";
import { Company } from "shared/types";
import displayAddress from "shared/util/displayAddress";
import RelCompanyCategoryList from "views/Admin/CompanyCategory/RelCompanyCategoryList";
import CommentList from "views/Comment/CommentList";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";
import CompanySite from "views/Company/CompanySite/CompanySite";
import ContactList from "views/Company/Contact/ContactList";
import NicknameList from "views/Nickname/NicknameList";
import ReminderCreateDialog from "views/Reminder/ReminderCreateDialog";
import BankAccountListPage from "../BankAccount/BankAccountListPage";
import CreateEmail from "views/Email/CreateEmail";
import { getLink } from "views/Report/AcceptTos";

type Props = {
  company: Company | null;
  loading: boolean;
  refetch: () => void;
};

const useStyles = makeStyles({
  cardHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 16,
  },
  cardContainer: {
    padding: 12,
    width: "100%",
    border: `1px solid ${COLORS.mainGrey}`,
    borderRadius: 4,
    marginBottom: 16,
  },
});

const CompanyDetails = ({ company, loading, refetch }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const isAll = location.pathname.includes("/list-all");
  const isCustomer = location.pathname.includes("/list-customer");
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey("rap-list-company-details-user-page-number"),
      ) || JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey("rap-list-company-details-user-page-size"),
      ) || JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;

  const companyUsersQuery = useQuery(["companyUsersQuery"], async () => {
    if (id) {
      const { data } = await getCompanyUsersById(Number.parseInt(id), tenant.id);
      return { ...data, items: data.items || [] };
    }
    return Promise.reject();
  });

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("userList.name"),
      flex: 1,
    },
    {
      field: "email",
      headerName: t("userList.email"),
      flex: 1,
    },
    {
      field: "status",
      headerName: t("userList.status.title"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => t("userList.status." + row.status) || "-",
    },
  ];

  const [reminderOpen, setReminderOpen] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);

  return (
    <>
      <Loading open={loading || companyUsersQuery.isFetching} />{" "}
      <ReminderCreateDialog
        open={reminderOpen}
        setOpen={setReminderOpen}
        company={company || undefined}
      />
      {!!company && emailOpen && <CreateEmail open={emailOpen} setOpen={setEmailOpen} />}
      <Box display="flex" justifyContent="center" gridGap={8} pb={1}>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => setReminderOpen(true)}
          startIcon={<FontAwesomeIcon icon={faBell} width={14} />}
        >
          {t("reminder.create")}
        </Button>
        {/* <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => setEmailOpen(true)}
          startIcon={<FontAwesomeIcon icon={faEnvelope} width={14} />}
        >
          Adatok küldése e-mailben
        </Button> */}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "COMPANY_GET",
          "COMPANY_ADMIN",
        ]) && (
          <Button
            size="small"
            variant="outlined"
            component={Link}
            to={`/companies/${
              isAll ? "list-all" : isCustomer ? "list-customer" : "list-supplier"
            }/company-modify?id=${id}`}
            startIcon={<Edit />}
          >
            {t("common:button.modify")}
          </Button>
        )}
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box display="flex" flexDirection="column" gridGap={16} pb={2}>
            <DetailsCard
              title={t("company.data")}
              columns="flex"
              columnFlexRule={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
              dataList={[
                { name: t("company.formValues.name"), value: company?.name },
                {
                  name: t("company.formValues.taxNumber"),
                  value: company?.taxNumber,
                },
                {
                  name: t("company.formValues.registrationNumber"),
                  value: company?.registrationNumber,
                },
                {
                  name: t("company.formValues.email"),
                  value: company?.notificationEmail,
                },
                {
                  name: t("company.formValues.phone"),
                  value: company?.phone,
                },
                {
                  name: t("company.formValues.bankAccount"),
                  value: company?.bankAccount || "-",
                },
                {
                  name: t("tenant.formValues.iban"),
                  value: company?.iban || "-",
                },
                {
                  name: t("company.formValues.isSupplier"),
                  value: company?.isSupplier ? t("common:YES") : t("common:NO"),
                },
                {
                  name: t("company.formValues.isCustomer"),
                  value: company?.isCustomer ? t("common:YES") : t("common:NO"),
                },
                {
                  name: t("company.formValues.baseLanguage"),
                  value: company?.baseLanguage
                    ? t(`common:language.${company?.baseLanguage}`)
                    : "-",
                },
                {
                  name: t("company.addressTitle"),
                  value: displayAddress(company?.address) || "",
                },
                {
                  name: t("company.formValues.description"),
                  value: company?.description || "",
                },
              ]}
            />
            <DetailsCard
              title={t("tenant.links")}
              columns={1}
              dataList={[
                {
                  name: t("tenant.formValues.webpage"),
                  value: company?.webpage || "-",
                  linkTo: company?.webpage ? getLink(company?.webpage || "") : undefined,
                },
                {
                  name: t("tenant.formValues.viber"),
                  value: company?.viber,
                  hide: !company?.viber,
                },
                {
                  name: t("tenant.formValues.facebook"),
                  value: company?.facebook,
                  hide: !company?.facebook,
                  linkTo: getLink(company?.facebook || ""),
                },
                {
                  name: t("tenant.formValues.instagram"),
                  value: company?.instagram,
                  hide: !company?.instagram,
                  linkTo: getLink(company?.instagram || ""),
                },
                {
                  name: t("tenant.formValues.linkedin"),
                  value: company?.linkedin,
                  hide: !company?.linkedin,
                  linkTo: getLink(company?.linkedin || ""),
                },
                {
                  name: t("tenant.formValues.tiktok"),
                  value: company?.tiktok,
                  hide: !company?.tiktok,
                  linkTo: getLink(company?.tiktok || ""),
                },
                {
                  name: t("tenant.formValues.youtube"),
                  value: company?.youtube,
                  hide: !company?.youtube,
                  linkTo: getLink(company?.youtube || ""),
                },
                {
                  name: t("tenant.formValues.pinterest"),
                  value: company?.pinterest,
                  hide: !company?.pinterest,
                  linkTo: getLink(company?.pinterest || ""),
                },
                {
                  name: t("tenant.formValues.twitter"),
                  value: company?.twitter,
                  hide: !company?.twitter,
                  linkTo: getLink(company?.twitter || ""),
                },
              ]}
            />
            <DetailsCard
              title={t("company.baseCurrencyTitle")}
              columns={2}
              dataList={[
                {
                  name: t("company.formValues.baseCurrency.name"),
                  value: company?.baseCurrency?.name,
                },
                {
                  name: t("company.formValues.baseCurrency.code"),
                  value: company?.baseCurrency?.code,
                },
                {
                  name: t("company.formValues.baseCurrency.prefix"),
                  value: company?.baseCurrency?.prefix || "",
                },
                {
                  name: t("company.formValues.baseCurrency.postfix"),
                  value: company?.baseCurrency?.postfix || "",
                },
              ]}
            />
            {!!company && <NicknameList company={company} refetch={refetch} />}
            <RelCompanyCategoryList />
            {hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "COMMENT_ADMIN",
            ]) &&
              !!id && <CommentList paramId={id} />}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" flexDirection="column" gridGap={16} pb={2}>
            <Card style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
              <Box className={classes.cardHeader}>
                <Typography variant="h2">{t("company.users")}</Typography>
              </Box>
              <SingleQueryTable
                transparent
                query={companyUsersQuery}
                columns={columns}
                setTableState={setTableState}
                tableState={tableState}
                sessionStorageKey={`companyDetailsUserList_${tenant.id}`}
              />
            </Card>
            <Card style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
              <ContactList isCompany isAll={isAll} isCustomer={isCustomer} />
            </Card>
            <Card style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
              <CompanySite isCompany isAll={isAll} isCustomer={isCustomer} />
            </Card>
            <Card style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
              <BankAccountListPage isCompany isAll={isAll} isCustomer={isCustomer} />
            </Card>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyDetails;
