import { Container, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { isDate } from "date-fns";
import format from "date-fns/format";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { SliceStatus } from "shared/common";
import { createIssue } from "shared/network/issues.api";
import { getProjectById } from "shared/network/project.api";
import { createRelIssueCustomFields } from "shared/network/rel-issue-custom-fields";
import {
  BaseCurrency,
  Company,
  CompanySite,
  IssueTemplate,
  IssueType,
  Item,
  Project,
  SerialItem,
  Tool,
  User,
  Warehouse,
} from "shared/types";
import ItemCreate from "views/Items/ItemCreate/ItemCreate";
import Login from "views/Login/Login";
import IssueForm from "./IssueForm";
import IssueTypeCreate from "./IssueType/IssueTypeCreate";
import IssueTemplateCreate from "./IssueTemplate/IssueTemplateCreate";

export type IssueFromValues = {
  issueStatusOwn: any;
  netPrice?: number | string;
  id?: number;
  companySite: CompanySite;
  tenantId?: number;
  project: Project | null;
  assignedUser: User | null;
  name: string;
  description: string;
  status: string;
  priority: string;
  parentIssueId: number | string;
  startDate: Date;
  endDate: Date;
  estimatedTime: number;
  completionPercentage: number;
  company: Company | null;
  contractorCompany: Company | null;
  responsibleUser: User | null;
  issueType: IssueType | null;
  items: any; // IssueFormItem[];
  tools: {
    tool: Tool | null;
  }[];
  users: {
    user: User | null;
    userType: string | null;
  }[];
  timeEntries?: any[]; //TimeEntry[];
  spentTime: number;
  issueTemplate: IssueTemplate | null;
  custom: any;
  contract: any;
  offer: any;
  worksheet?: any;
};

export type IssueFormItem = {
  id?: number | string;
  item: Item | null;
  quantity?: string;
  netPrice?: number | string;
  currency?: BaseCurrency | null;
  itemSerial?: SerialItem | null;
  fromWarehouse: Warehouse | null;
  totalOperatingHours?: number | null;
  activeDeliveryTime?: number | null;
  passiveDeliveryTime?: number | null;
  numberOfBanknotes?: number | null;
};

const IssueCreate = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState<SliceStatus>("idle");
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const parentId = query.get("parentId");
  const projectId = query.get("projectId");
  const selectedRelTenant = useSelector(
    (state: RootState) => state.authentication?.selectedRelTenant,
  );
  const tenant = selectedRelTenant?.tenant;
  const user = useSelector((state: RootState) => state.authentication?.account?.user);
  const form = useForm<IssueFromValues>({ shouldUnregister: true });

  const [itemCreateState, setItemCreateState] = useState<number | null>(null);
  const [issueTemplateOpen, setIssueTemplateOpen] = useState<boolean>(false);
  const [issueTypeOpen, setIssueTypeOpen] = useState<boolean>(false);

  const projectByIdQuery = useQuery(
    ["projectByIdQuery", selectedRelTenant?.tenant.id, projectId],
    async () => {
      if (projectId) {
        const { data } = await getProjectById(selectedRelTenant?.tenant.id, projectId);
        return data.item;
      }
      return Promise.reject();
    },
    {
      onSuccess: data => {
        if (data) {
          form.setValue("project", data);
        }
        if (data?.company) {
          form.setValue("company", data.company);
        }
      },
      enabled: !!projectId,
    },
  );

  const onSubmitCreate = async (values: IssueFromValues) => {
    try {
      setStatus("pending");
      const { data } = await createIssue(
        {
          ...values,
          issueStatusOwn: values.issueStatusOwn ? { id: values.issueStatusOwn } : undefined,
          contract: values.contract || null,
          contractorCompany: values.contractorCompany || null,
          parentIssueId: values.parentIssueId || null,
          issueTemplateId: values.issueTemplate?.id || null,
          assignedUserId: values.assignedUser?.id || null,
          startDate: format(new Date(values.startDate), "yyyy-MM-dd"),
          endDate: format(new Date(values.endDate), "yyyy-MM-dd"),
          items: values.items.map((item: IssueFormItem) => {
            return {
              item: item.item,
              quantity: item.item?.type === "SERIAL_NUMBER" ? "1" : item.quantity?.toString(),
              itemSerial: item.item?.type === "SERIAL_NUMBER" ? item.itemSerial : undefined,
              fromWarehouse: item.fromWarehouse,
              netPrice: item.netPrice,
              currency: item.currency,
            };
          }),
        },
        selectedRelTenant.tenant.id,
      );
      if (values.custom) {
        await createRelIssueCustomFields(
          {
            issueId: data.item.id,
            relIssueCustomFieldsEntries: Object.entries(values.custom).map(
              ([key, value], index) => {
                return {
                  tenantId: selectedRelTenant.tenant.id,
                  issueId: data.item.id,
                  customField: {
                    id: key,
                  },
                  value: isDate(value)
                    ? format(new Date(value as Date), "yyyy-MM-dd")
                    : typeof value === "object"
                    ? JSON.stringify(value)
                    : (value as string),
                  orderNumber: index + 1,
                };
              },
            ),
          },
          selectedRelTenant.tenant.id,
        );
      }
      enqueueSnackbar(
        t("common:notification.create.success", {
          subject: t("issues.subject"),
        }),
        {
          variant: "success",
        },
      );
      setStatus("success");
      history.goBack();
    } catch (error) {
      setStatus("failure");
      enqueueSnackbar(
        t("common:notification.create.failure", {
          subject: t("issues.subject"),
        }),
        {
          variant: "error",
        },
      );
      setStatus("failure");
    }
  };

  useEffect(() => {
    form.setValue("parentIssueId", parentId || "");
  }, [form, parentId]);

  function setNewItem(item: Item, index: number | null | undefined) {
    if (item) {
      form.setValue(`items.${index}.item`, item);
      form.setValue(`items.${index}.netPrice`, item?.itemPriceHistories?.[0]?.price || "");
      form.setValue(
        `items.${index}.currency`,
        item?.itemPriceHistories?.[0]?.currency || tenant?.baseCurrency,
      );
    }
  }

  function setNewIssueType(issueType: IssueType) {
    if (issueType) {
      form.setValue(`issueType`, issueType);
    }
  }

  function setNewIssueTemplate(issueTemplate: IssueTemplate) {
    if (issueTemplate) {
      form.setValue(`issueTemplate`, issueTemplate);
    }
  }

  return (
    <>
      <Loading open={status === "pending" || projectByIdQuery?.isFetching} />
      {selectedRelTenant?.tenant.id && user ? (
        <Container maxWidth="xl">
          <Dialog
            open={itemCreateState !== null}
            fullWidth
            maxWidth="md"
            onClose={() => setItemCreateState(null)}
          >
            <DialogTitle>{t("item.create")}</DialogTitle>
            <DialogContent style={{ padding: 0 }}>
              <ItemCreate
                itemCreateState={itemCreateState}
                setItemCreateState={setItemCreateState}
                setNewItem={setNewItem}
              />
            </DialogContent>
          </Dialog>
          <Dialog
            fullWidth
            maxWidth="md"
            open={issueTemplateOpen}
            onClose={() => setIssueTemplateOpen(false)}
          >
            <DialogTitle>Új feladatsablon</DialogTitle>
            <DialogContent style={{ padding: 0 }}>
              <IssueTemplateCreate
                setIssueTemplateOpen={setIssueTemplateOpen}
                setNewIssueTemplate={setNewIssueTemplate}
                isDialog
              />
            </DialogContent>
          </Dialog>
          <Dialog
            fullWidth
            maxWidth="xs"
            open={issueTypeOpen}
            onClose={() => setIssueTypeOpen(false)}
          >
            <DialogTitle>Új feladattípus</DialogTitle>
            <DialogContent style={{ padding: 0 }}>
              <IssueTypeCreate
                setIssueTypeOpen={setIssueTypeOpen}
                setNewIssueType={setNewIssueType}
                isDialog
              />
            </DialogContent>
          </Dialog>
          <form onSubmit={form.handleSubmit(onSubmitCreate)}>
            <FormProvider {...form}>
              <IssueForm
                isLoading={status !== "idle" && status !== "failure"}
                setItemCreateState={setItemCreateState}
                setIssueTemplateOpen={setIssueTemplateOpen}
                setIssueTypeOpen={setIssueTypeOpen}
              />
            </FormProvider>
          </form>
        </Container>
      ) : (
        <Login />
      )}
    </>
  );
};
export default IssueCreate;
