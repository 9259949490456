import {
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import ConfirmationButton from "components/ConfirmationButton";
import queryClient from "config/query";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { deleteIssueStatus, IssueStatus } from "shared/network/issue-status.api";

type Props = {
  issueStatus: IssueStatus;
};

const useStyles = makeStyles({
  header: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
    alignItems: "center",
  },
  row: {
    alignItems: "center",
    borderBottom: `1px solid ${COLORS.mainGrey}`,
  },
  cell: {
    padding: 8,
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const IssueStatusRow = ({ issueStatus }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant?.tenant;
  const [deleteLoading, setDeleteLoading] = useState(false);

  return (
    <Grid container className={classes.row} style={{ alignItems: "center" }}>
      <Grid item xs={6} className={classes.cell}>
        <Typography className={classes.text}>{issueStatus.name}</Typography>
      </Grid>
      <Grid item container xs={6} justifyContent="flex-end">
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ISSUE_ADMIN"]) && (
          <Tooltip title={t("issueStatus.modify").toString()}>
            <IconButton
              component={Link}
              to={`/issues/status/modify?id=${issueStatus.id}`}
              size="small"
              color="primary"
              style={{ margin: "0 8px" }}
            >
              <Edit color="primary" />
            </IconButton>
          </Tooltip>
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ISSUE_ADMIN"]) && (
          <Tooltip title={t("issueStatus.modify").toString()}>
            <ConfirmationButton
              color="primary"
              size="small"
              onSubmit={async () => {
                setDeleteLoading(true);
                try {
                  await deleteIssueStatus(issueStatus.id, tenant?.id);
                  queryClient.refetchQueries(["issueStatusList"]);
                } catch {}
                setDeleteLoading(false);
              }}
              buttonType="ICON"
              title="Törlés"
              body="Biztosan törli a státuszt?"
              disabled={deleteLoading}
            >
              {deleteLoading ? <CircularProgress size={24} /> : <Delete />}
            </ConfirmationButton>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
};

export default IssueStatusRow;
