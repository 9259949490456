import { Card, Grid, Typography } from "@material-ui/core";
import { COLORS } from "config/theme";
import { useTranslation } from "react-i18next";
import { JobForm } from "shared/network/job-questionnaire.api";

type Props = {
  jobForm: JobForm;
};

const LanguageDataCard = ({ jobForm }: Props) => {
  const { t } = useTranslation();

  return (
    <Card style={{ padding: 16 }}>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontSize: 20,
          fontWeight: "bold",
          paddingBottom: 8,
        }}
      >
        {t("jobForm.languageData.title")}
      </Typography>
      {jobForm?.jobLanguageSkillEntries?.map((field, index) => {
        return (
          <Grid key={index} container spacing={1}>
            <Grid item xs={12} sm={4}>
              <Typography style={{ fontWeight: "bold", fontSize: 16, color: COLORS.mainLight }}>
                {index + 1}. {field?.language}:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography>
                <strong>
                  {t("jobForm.languageData.oralLevel")}
                  {": "}
                </strong>
                {t(`jobForm.languageData.${field?.oralLevel}`)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography>
                <strong>
                  {t("jobForm.languageData.writtenLevel")}
                  {": "}
                </strong>
                {t(`jobForm.languageData.${field?.writtenLevel}`)}
              </Typography>{" "}
            </Grid>
          </Grid>
        );
      })}
    </Card>
  );
};

export default LanguageDataCard;
