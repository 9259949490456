import { lazy } from "react";
import IssueStatusCreate from "views/Issues/IssueStatus/IssueStatusCreate";
import IssueStatusModify from "views/Issues/IssueStatus/IssueStatusModify";

const IssueTabs = lazy(() => import("views/Issues/IssuesTabs"));
const IssueCreate = lazy(() => import("views/Issues/IssueCreate"));
const IssueModify = lazy(() => import("views/Issues/IssueModify"));
const TimeEntryCreate = lazy(() => import("views/TimeEntry/TimeEntryCreate"));
const TimeEntryModify = lazy(() => import("views/TimeEntry/TimeEntryModify"));
const IssueDetailsPage = lazy(() => import("views/Issues/IssueDetails/IssueDetailsPage"));
const IssueTemplateCreate = lazy(() => import("views/Issues/IssueTemplate/IssueTemplateCreate"));
const IssueTemplateDetails = lazy(() => import("views/Issues/IssueTemplate/IssueTemplateDetails"));
const IssueTemplateModify = lazy(() => import("views/Issues/IssueTemplate/IssueTemplateModify"));
const IssueTypeCreate = lazy(() => import("views/Issues/IssueType/IssueTypeCreate"));
const IssueTypeModify = lazy(() => import("views/Issues/IssueType/IssueTypeModify"));
const CustomFieldCreate = lazy(() => import("views/Issues/CustomFields/CustomFieldCreate"));
const CustomFieldModify = lazy(() => import("views/Issues/CustomFields/CustomFieldModify"));

export const ISSUE_PRIVATE_ROUTES = [
  {
    path: "/issues",
    component: IssueTabs,
    permissions: ["ISSUE_GET"],
    routes: [
      {
        path: "/all",
        component: IssueTabs,
        permissions: ["ISSUE_GET"],
        routes: [
          {
            path: "/time-entry-create",
            component: TimeEntryCreate,
            permissions: ["ISSUE_GET"],
          },
          {
            path: "/time-entry-modify",
            component: TimeEntryModify,
            permissions: ["ISSUE_GET"],
          },
          {
            path: "/issue-details",
            component: IssueDetailsPage,
            permissions: ["ISSUE_GET"],
          },
          {
            path: "/issue-modify",
            component: IssueModify,
            permissions: ["ISSUE_ADMIN"],
          },
          {
            path: "/issue-create",
            component: IssueCreate,
            permissions: ["ISSUE_ADMIN"],
          },
        ],
      },
      {
        path: "/personal",
        component: IssueTabs,
        permissions: ["ISSUE_GET"],
        routes: [
          {
            path: "/time-entry-create",
            component: TimeEntryCreate,
            permissions: ["ISSUE_GET"],
          },
          {
            path: "/time-entry-modify",
            component: TimeEntryModify,
            permissions: ["ISSUE_GET"],
          },
          {
            path: "/issue-details",
            component: IssueDetailsPage,
            permissions: ["ISSUE_GET"],
          },
          {
            path: "/issue-modify",
            component: IssueModify,
            permissions: ["ISSUE_ADMIN"],
          },
          {
            path: "/issue-create",
            component: IssueCreate,
            permissions: ["ISSUE_ADMIN"],
          },
        ],
      },
      {
        path: "/worksheet",
        component: IssueTabs,
        permissions: ["ISSUE_GET"],
        routes: [
          {
            path: "/time-entry-create",
            component: TimeEntryCreate,
            permissions: ["ISSUE_GET"],
          },
          {
            path: "/time-entry-modify",
            component: TimeEntryModify,
            permissions: ["ISSUE_GET"],
          },
          {
            path: "/issue-details",
            component: IssueDetailsPage,
            permissions: ["ISSUE_GET"],
          },
          {
            path: "/issue-modify",
            component: IssueModify,
            permissions: ["ISSUE_ADMIN"],
          },
          {
            path: "/issue-create",
            component: IssueCreate,
            permissions: ["ISSUE_ADMIN"],
          },
        ],
      },
      {
        path: "/templates",
        component: IssueTabs,
        permissions: ["ISSUE_TEMPLATE_GET"],
        routes: [
          {
            path: "/issue-template-details",
            component: IssueTemplateDetails,
            permissions: ["ISSUE_TEMPLATE_GET"],
          },
          {
            path: "/issue-template-modify",
            component: IssueTemplateModify,
            permissions: ["ISSUE_TEMPLATE_ADMIN"],
          },
          {
            path: "/issue-template-create",
            component: IssueTemplateCreate,
            permissions: ["ISSUE_TEMPLATE_ADMIN"],
          },
        ],
      },
      {
        path: "/issue-type",
        component: IssueTabs,
        permissions: ["ISSUE_TYPE_GET"],
        routes: [
          {
            path: "/issue-type-modify",
            component: IssueTypeModify,
            permissions: ["ISSUE_TYPE_ADMIN"],
          },
          {
            path: "/issue-type-create",
            component: IssueTypeCreate,
            permissions: ["ISSUE_TYPE_ADMIN"],
          },
        ],
      },
      {
        path: "/status",
        component: IssueTabs,
        permissions: ["ISSUE_GET"],
        routes: [
          {
            path: "/modify",
            component: IssueStatusModify,
            permissions: ["ISSUE_ADMIN"],
          },
          {
            path: "/create",
            component: IssueStatusCreate,
            permissions: ["ISSUE_ADMIN"],
          },
        ],
      },
      {
        path: "/custom-fields",
        component: IssueTabs,
        permissions: ["ISSUE_GET"],
        routes: [
          {
            path: "/custom-fields-modify",
            component: CustomFieldModify,
            permissions: ["CUSTOM_FIELDS_ADMIN"],
          },
          {
            path: "/custom-fields-create",
            component: CustomFieldCreate,
            permissions: ["CUSTOM_FIELDS_ADMIN"],
          },
        ],
      },
      {
        path: "/kanban",
        component: IssueTabs,
        permissions: ["ISSUE_GET"],
        routes: [
          {
            path: "/time-entry-create",
            component: TimeEntryCreate,
            permissions: ["ISSUE_GET"],
          },
          {
            path: "/issue-details",
            component: IssueDetailsPage,
            permissions: ["ISSUE_GET"],
          },
          {
            path: "/issue-modify",
            component: IssueModify,
            permissions: ["ISSUE_ADMIN"],
          },
        ],
      },
    ],
  },
];
