import { faSquareEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, IconButton } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { AddBox, Delete, Edit, LockOpen, PermIdentity } from "@material-ui/icons";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BlockIcon from "@material-ui/icons/Block";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import DoneIcon from "@material-ui/icons/Done";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowData,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import ConfirmationButton from "components/ConfirmationButton";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { Link as RouterLink } from "components/Router";
import { USER_STATUS } from "config/constants";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import {
  deleteUser,
  getUserById,
  listUsers,
  modifyUser,
  sendLawyerEmail,
} from "shared/network/user.api";
import { User } from "shared/types";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";
import RelCreateTag from "views/Tag/RelCreateTag";
import RelModifyTag from "views/Tag/RelModifyTag";
import TagIcon from "views/Tag/TagIcon";
import PermissionGroupModal from "./components/PermissionGroupModal";
import { getTagPage } from "shared/network/tag.api";

export function getTagColumnWidth(list: any[]) {
  let maxLength = 0;
  list?.forEach(value => {
    if ((value?.relTagSubjectEntryList?.length || 0) > maxLength) {
      maxLength = value?.relTagSubjectEntryList?.length || 0;
    }
  });
  return (maxLength + 1) * 24 + 24;
}

const UserList = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<number | null>(null);

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const { setHeaderButtons } = useHeader();

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-user-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-user-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const listUsersQuery = useQuery(
    ["listUsersQuery", page, pageSize, filterValue, sortState, selectedRelTenant.tenant.id],
    async () => {
      const hasRelCompanyFilter = filterValue.includes("relUserCompany[0].company.name:");
      let newFilterValue = filterValue;
      let companyName = "";
      if (hasRelCompanyFilter) {
        newFilterValue = "";
        const idx = filterValue.search(/relUserCompany\[0\].company.name:(.+);/);
        const endIdx = filterValue.indexOf(";", idx);
        filterValue.substr(idx, endIdx);
        newFilterValue = `${filterValue.substring(0, idx === 0 ? 0 : idx)}${filterValue.substring(
          endIdx,
        )}`;
        companyName = filterValue.substring(idx, endIdx)?.split(":")?.[1];
      }
      const { data } = await listUsers(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        hasRelCompanyFilter ? newFilterValue : filterValue,
        sortState,
        companyName,
      );
      return data;
    },
  );

  async function goToEmployee(user: GridRowData) {
    const { data } = await getUserById(selectedRelTenant.tenant.id, user.id);
    const { employee } = data;

    if (employee?.id) {
      history.push(`/my-company/employees/employee-modify?id=${employee.id}`);
    } else {
      history.push(
        `/my-company/employees/employee-create?name=${encodeURIComponent(
          user.name,
        )}&email=${encodeURIComponent(user.email)}`,
      );
    }
  }

  async function ban(user: GridRowData) {
    try {
      if (user.status === "ACTIVE") {
        user.status = "BANNED";
      } else if (user.status === "BANNED") {
        user.status = "ACTIVE";
      }
      await modifyUser(user, selectedRelTenant.tenant.id);
      listUsersQuery.refetch();
      enqueueSnackbar(
        t("common:notification.ban.success", {
          subject: t("user.subject"),
        }),
        { variant: "success" },
      );
    } catch (error) {
      enqueueSnackbar(
        t("common:notification.ban.failure", {
          subject: t("user.subject"),
        }),
        { variant: "error" },
      );
    }
  }

  async function sendEmail(user: any) {
    try {
      await sendLawyerEmail(user, selectedRelTenant.tenant.id);
      enqueueSnackbar(t("email.success"), { variant: "success" });
    } catch (error) {
      enqueueSnackbar(t("email.failure"), { variant: "error" });
    }
  }

  async function onDelete(user: User) {
    try {
      await deleteUser(user, selectedRelTenant.tenant.id);
      listUsersQuery.refetch();
      enqueueSnackbar(t("user.deleteSuccess"), { variant: "success" });
    } catch (error) {
      enqueueSnackbar(t("user.deleteFailure"), { variant: "error" });
    }
  }

  const columns: GridColDef[] = [
    {
      field: "tagSearch",
      headerName: t("tags.title"),
      width: getTagColumnWidth(listUsersQuery?.data?.page?.content || []),
      sortable: false,
      filterable: true,
      type: "select",
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" width="100%">
          <TagIcon
            row={row}
            relTagList={row?.relTagSubjectEntryList}
            setSelectedRow={setSelectedRow}
            setSelectedToModify={setSelectedToModify}
          />
        </Box>
      ),
    },
    {
      field: "name",
      headerName: t("userList.name"),
      flex: 1,
    },
    {
      field: "relUserCompany[0].company.name",
      headerName: t("company.formValues.name"),
      hide: !selectedRelTenant?.tenant?.isUseDefault,
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.relUserCompany?.[0]?.company ? row.relUserCompany?.[0]?.company.name : "-",
    },
    {
      field: "email",
      headerName: t("userList.email"),
      flex: 1,
    },
    {
      field: "status",
      headerName: t("userList.status.title"),
      width: 120,
      align: "center",
      sortable: false,
      headerAlign: "center",
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <Tooltip title={t(`userList.status.${row.status}`).toString()}>
            {row.status === "ACTIVE" ? (
              <CheckCircleOutlineIcon style={{ color: COLORS.green }} />
            ) : row.status === "NEEDS_ACTIVATION" ? (
              <DoneIcon />
            ) : (
              <BlockIcon style={{ color: COLORS.red }} />
            )}
          </Tooltip>
        );
      },
      type: "select",
    },
    {
      field: "actions",
      headerName: " ",
      width: 340,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {(selectedRelTenant?.isTenantAdmin || account.user.isSuperAdmin) &&
            selectedRelTenant?.tenant?.isUseReport && (
              <Box>
                <Tooltip title={t(`email.title`).toString()}>
                  <IconButton
                    size="small"
                    color="primary"
                    style={{ margin: "0 8px" }}
                    onClick={() => sendEmail(row)}
                  >
                    <FontAwesomeIcon icon={faSquareEnvelope} style={{ fontSize: 24 }} />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "PERMISSION_GROUP_GET",
            "PERMISSION_ADMIN",
          ]) && (
            <Tooltip title={"Jogosultságok"}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                onClick={() => setOpen(row.id)}
              >
                <LockOpen />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "EMPLOYEE_ADMIN",
          ]) ? (
            <>
              <Box>
                <Tooltip title={t("user.addAsEmployee").toString()}>
                  <div>
                    <IconButton
                      size="small"
                      disabled={!!row.relUserCompany?.length}
                      color="primary"
                      style={{ margin: "0 8px" }}
                      onClick={() => goToEmployee(row)}
                    >
                      <PermIdentity />
                    </IconButton>
                  </div>
                </Tooltip>
              </Box>
              {row.status === "ACTIVE" ? (
                <Box>
                  <Tooltip title={t(`user.banUser`).toString()}>
                    <IconButton
                      size="small"
                      color="primary"
                      style={{ margin: "0 8px" }}
                      onClick={() => ban(row)}
                    >
                      <BlockIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              ) : row.status === "BANNED" ? (
                <Box>
                  <Tooltip title={t(`user.activateUser`).toString()}>
                    <IconButton
                      size="small"
                      color="primary"
                      style={{ margin: "0 8px" }}
                      onClick={() => ban(row)}
                    >
                      <BlockIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              ) : (
                <Box>
                  <IconButton size="small" color="primary" style={{ margin: "0 8px" }} disabled>
                    <BlockIcon />
                  </IconButton>
                </Box>
              )}
              <Box>
                <Tooltip title={t(`common:button.delete`).toString()}>
                  <div>
                    <ConfirmationButton
                      buttonType="ICON"
                      size="small"
                      color="primary"
                      style={{ margin: "0 8px" }}
                      onSubmit={() => onDelete(row as User)}
                      title={t("user.deleteTitle")}
                      body={t("user.deleteBody")}
                    >
                      <Delete color="primary" />
                    </ConfirmationButton>
                  </div>
                </Tooltip>
              </Box>
              <Box>
                <Tooltip title={t(`userList.details`).toString()}>
                  <IconButton
                    size="small"
                    color="primary"
                    style={{ margin: "0 8px" }}
                    component={RouterLink}
                    to={`/my-company/users/user-details?id=${row.id}`}
                  >
                    <AssignmentIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box>
                <Tooltip title={t(`userList.modify`).toString()}>
                  <IconButton
                    size="small"
                    color="primary"
                    style={{ margin: "0 8px" }}
                    component={RouterLink}
                    to={`/my-company/users/user-modify?id=${row.id}`}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          ) : (
            <Box>
              <Tooltip title={t(`userList.details`).toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  component={RouterLink}
                  to={`/my-company/users/user-details?id=${row.id}`}
                >
                  <AssignmentIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {selectedRelTenant?.tenant?.isUseReport &&
          hasAuthority(account.user, account.permissions, selectedRelTenant, ["USER_ADMIN"]) && (
            <Button
              variant="contained"
              component={Link}
              startIcon={<AddBox />}
              style={{ margin: "auto" }}
              to="/my-company/users/lawyer-create"
            >
              {t("userList.lawyer")}
            </Button>
          )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["USER_ADMIN"]) && (
          <Button
            variant="contained"
            component={Link}
            startIcon={<AddBox />}
            style={{ margin: "auto" }}
            to="/my-company/users/user-create"
          >
            {t("userList.create")}
          </Button>
        )}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant, filterValue]); //eslint-disable-line

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions" && params.field !== "tagSearch") {
      history.push(`/my-company/users/user-details?id=${params.row.id}`);
    }
  }

  const tagQuery = useQuery(["tagUSERQuery", selectedRelTenant?.tenant?.id], async () => {
    const { data } = await getTagPage(
      selectedRelTenant?.tenant?.id,
      0,
      1000,
      `(tagType:USER;(OR)tagType(IS_NULL)1;)`,
    );

    return data;
  });

  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [selectedToModify, setSelectedToModify] = useState<User | null>(null);

  return (
    <>
      {!!selectedRow && (
        <RelCreateTag
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          refetch={listUsersQuery.refetch}
          subjectType={"USER"}
        />
      )}
      {!!selectedToModify && (
        <RelModifyTag
          selectedToModify={selectedToModify}
          setSelectedToModify={setSelectedToModify}
          refetch={listUsersQuery.refetch}
          subjectType={"USER"}
        />
      )}
      <PermissionGroupModal open={open} setOpen={setOpen} />
      <PageableTable
        filterOptions={[
          {
            columnName: "status",
            options: USER_STATUS.map(value => {
              return { translated: t(`userList.status.${value}`), value };
            }),
          },
          {
            columnName: "tagSearch",
            options: tagQuery?.data?.page?.content?.map(value => {
              return {
                translated: value?.tag || "",
                value: "x" + value?.id + "y",
              };
            }),
          },
        ]}
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="user"
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={listUsersQuery}
        onCellClick={onCellClick}
      />
    </>
  );
};
export default UserList;
