import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { GridSearchIcon } from "@mui/x-data-grid";
import { RootState } from "config/store";
import JoditEditor from "jodit-react";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { multipleEmail } from "shared/network/company.api";
import { Contact, User } from "shared/types";
import { EMAIL_REGEX } from "shared/validation";
import { useDebouncedCallback } from "use-debounce";
import { defaultTextAreaConfig } from "views/Reminder/ReminderForm";
import CreateEmailContacts from "./CreateEmailContacts";
import CreateEmailUsers from "./CreateEmailUsers";
import { JobReferredTo } from "shared/network/job-referred-to.api";
import CreateEmailJobForm from "./CreateEmailJobForm";
import Loading from "components/Loading";

type FormValues = {
  subject: string;
  campaign?: string;
  content: string;
  valid: any;
  replyEmail: string;
};

type Props = {
  email?: any;
  refetch?: () => void;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedEmailForNew?: any;
  setSelectedEmailForNew?: Dispatch<SetStateAction<any>>;
  companyId?: string | number | null;
};

const CreateEmail = ({
  email,
  refetch,
  setOpen,
  open,
  selectedEmailForNew,
  setSelectedEmailForNew,
  companyId,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm({ shouldUnregister: false });
  const { setValue } = form;
  const tenant = useSelector((state: RootState) => state.authentication?.selectedRelTenant?.tenant);
  const tenantId = tenant?.id;
  const userEmail = useSelector((state: RootState) => state.authentication?.account?.user?.email);
  const [search, setSearch] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  const [selectedJobForms, setSelectedJobForms] = useState<JobReferredTo[]>([]);
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  async function onSubmit(values: FormValues) {
    setLoading(true);

    const emailAddresses: string[] = [];

    selectedContacts.forEach(contact => emailAddresses?.push(contact.email));
    selectedEmails.forEach(email => emailAddresses?.push(email));
    selectedJobForms.forEach(value => {
      if (value.user) {
        emailAddresses?.push(value.user.email);
      }
    });

    try {
      await multipleEmail({
        subject: values.subject,
        content: values.content || "<p></p>",
        tenantId: tenantId,
        users: selectedUsers,
        emails: emailAddresses,
        campaignName: values.campaign,
        replyEmail: values?.replyEmail,
        companyId: companyId || undefined,
      });
      enqueueSnackbar(
        t("Az emailek kiküldése folyamatban.", {
          subject: t("customEmail.email"),
        }),
        { variant: "success" },
      );
      form.reset();
      setSelectedUsers([]);
      setSelectedEmails([]);
      setSelectedContacts([]);
      setOpen(false);
      refetch?.();
    } catch (error: any) {
      enqueueSnackbar(
        t("common:notification.send.failure", {
          subject: t("customEmail.email"),
        }),
        {
          variant: "error",
        },
      );
    }
    setLoading(false);
  }

  useEffect(() => {
    if (selectedUsers?.length || selectedEmails?.length) {
      form.register("valid", {
        validate: {
          users: () => {
            return !!selectedUsers.length || !!selectedEmails.length;
          },
        },
      });
    }
  }, [form, selectedUsers?.length, selectedEmails?.length]);

  useEffect(() => {
    setValue("content", selectedEmailForNew?.content || "");
    setValue("subject", selectedEmailForNew?.subject || "");
    setValue("campaign", selectedEmailForNew?.campaignName || "");
    setValue("replyEmail", selectedEmailForNew?.replyEmail || "");
  }, [
    selectedEmailForNew?.campaignName,
    selectedEmailForNew?.content,
    selectedEmailForNew?.relCustomNotificationLogUsers,
    selectedEmailForNew?.replyEmail,
    selectedEmailForNew?.subject,
    setValue,
  ]);

  function close() {
    setOpen(false);
    setSelectedEmails([]);
    setSelectedUsers([]);
    setSearch("");
    form.reset({});
    setSelectedEmailForNew?.(null);
  }

  const textAreaConfig = useMemo(() => {
    return defaultTextAreaConfig;
  }, []);

  const [tab, setTab] = useState(tenant?.isJobMarket && companyId ? 3 : companyId ? 1 : 0);

  useEffect(() => {
    setValue("replyEmail", userEmail || "");
  }, [email, setValue, userEmail]);

  const handleChange = useDebouncedCallback((value: string) => {
    setSearch(value);
  }, 500);

  return (
    <>
      <Loading open={loading} />
      <Dialog open={open} maxWidth="lg" fullWidth onClose={() => close()} disableEnforceFocus>
        <DialogTitle>{t("customEmail.header")}</DialogTitle>
        <DialogContent>
          <form id="custom-email" onSubmit={form.handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  style={{ paddingBottom: 4 }}
                  onChange={event => handleChange(event.target.value)}
                  placeholder={t("customEmail.placeholder")}
                  hiddenLabel
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <GridSearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  error={!selectedUsers.length && !selectedEmails.length}
                  helperText={"Keressen vagy írjon be egy e-mail címet!"}
                />
                {EMAIL_REGEX?.test(search) && (
                  <MenuItem
                    onClick={() => {
                      if (selectedEmails.find(email => email === search)) {
                        setSelectedEmails(selectedEmails.filter(email => email !== search));
                      } else {
                        let emails = [...selectedEmails];
                        emails.push(search);
                        setSelectedEmails(emails);
                      }
                    }}
                  >
                    <FormControlLabel
                      label=""
                      control={
                        <Checkbox
                          checked={
                            selectedEmails.findIndex(
                              email => email.toLowerCase() === search.toLowerCase(),
                            ) !== -1
                          }
                          color="primary"
                        />
                      }
                    />
                    <Box>
                      <Typography style={{ flex: 1 }}>{search}</Typography>
                    </Box>
                  </MenuItem>
                )}
                {!!companyId && tenant?.isJobMarket && (
                  <>
                    <MenuItem
                      onClick={() => setTab(3)}
                      selected={tab === 3}
                      color="primary"
                      style={{ fontWeight: "bold" }}
                    >
                      Kiközvetítettek
                    </MenuItem>
                    <Collapse in={tab === 3}>
                      <CreateEmailJobForm
                        selectedJobForms={selectedJobForms}
                        setSelectedJobForms={setSelectedJobForms}
                        selectedEmails={selectedEmails}
                        setSelectedEmails={setSelectedEmails}
                        search={search}
                        companyId={companyId}
                      />
                    </Collapse>
                  </>
                )}
                {!!companyId && (
                  <>
                    <MenuItem
                      onClick={() => setTab(1)}
                      selected={tab === 1}
                      color="primary"
                      style={{ fontWeight: "bold" }}
                    >
                      Kontaktok
                    </MenuItem>
                    <Collapse in={tab === 1}>
                      <CreateEmailContacts
                        selectedContacts={selectedContacts}
                        setSelectedContacts={setSelectedContacts}
                        selectedEmails={selectedEmails}
                        setSelectedEmails={setSelectedEmails}
                        search={search}
                        companyId={companyId}
                      />
                    </Collapse>
                  </>
                )}
                <Box>
                  <MenuItem
                    onClick={() => setTab(0)}
                    selected={tab === 0}
                    color="primary"
                    style={{ fontWeight: "bold" }}
                  >
                    Felhasználók
                  </MenuItem>
                  <Collapse in={tab === 0}>
                    <CreateEmailUsers
                      selectedUsers={selectedUsers}
                      setSelectedUsers={setSelectedUsers}
                      selectedEmails={selectedEmails}
                      setSelectedEmails={setSelectedEmails}
                      search={search}
                      setSearch={setSearch}
                      companyId={companyId}
                    />
                  </Collapse>
                  <MenuItem
                    onClick={() => setTab(2)}
                    selected={tab === 2}
                    color="primary"
                    style={{ fontWeight: "bold" }}
                  >
                    Összes címzett
                  </MenuItem>
                  <Collapse in={tab === 2}>
                    <Box overflow="auto" height={400} p={1}>
                      {selectedUsers.map((us, index) => {
                        return (
                          <Box key={index}>
                            <Box style={{ paddingBottom: 8 }}>
                              <Typography
                                style={{ width: "fit-content", fontWeight: "bold" }}
                                noWrap
                              >
                                {us.name}
                              </Typography>
                              <Typography style={{ width: "fit-content" }} noWrap>
                                {us.email}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                      {selectedContacts.map((co, index) => {
                        return (
                          <Box key={index}>
                            <Box key={index} style={{ paddingBottom: 8 }}>
                              <Typography
                                style={{ width: "fit-content", fontWeight: "bold" }}
                                noWrap
                              >
                                {co.name}
                              </Typography>
                              <Typography style={{ width: "fit-content" }} noWrap>
                                {co.email}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                      {selectedJobForms.map((co, index) => {
                        return (
                          <Box key={index}>
                            <Box key={index} style={{ paddingBottom: 8 }}>
                              <Typography
                                style={{ width: "fit-content", fontWeight: "bold" }}
                                noWrap
                              >
                                {co.user?.name}
                              </Typography>
                              <Typography style={{ width: "fit-content" }} noWrap>
                                {co.user?.email}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                      {selectedEmails.map((email, index) => {
                        return (
                          <Box key={index}>
                            {" "}
                            <Box key={index} style={{ paddingBottom: 8 }}>
                              <Typography
                                style={{
                                  width: "fit-content",
                                }}
                                noWrap
                                key={`${email}_${index}`}
                              >
                                {email}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                      {!selectedEmails?.length &&
                        !selectedContacts?.length &&
                        !selectedUsers?.length &&
                        !selectedJobForms?.length && (
                          <Typography style={{ padding: 8 }}>A lista üres</Typography>
                        )}
                    </Box>
                  </Collapse>
                </Box>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      control={form.control}
                      name="subject"
                      defaultValue={email?.subjectType || ""}
                      rules={{
                        required: { value: true, message: t("validation.required").toString() },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label={t("customEmail.subject")}
                          InputLabelProps={{ required: true }}
                          error={!!fieldState?.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={form.control}
                      name="campaign"
                      defaultValue={email?.subjectType || ""}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label={t("customEmail.campaign")}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={form.control}
                      name="replyEmail"
                      defaultValue={email ? email?.replyEmail : ""}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label={t("customEmail.replyEmail")}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={form.control}
                      name="content"
                      defaultValue={email?.content || ""}
                      render={({ field, fieldState }) => (
                        <JoditEditor
                          config={textAreaConfig}
                          ref={field.ref}
                          value={field.value}
                          onChange={content => {
                            field.onChange(content);
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => close()}>
            {t("common:button.cancel")}
          </Button>
          <Button form="custom-email" type="submit" disabled={loading}>
            {t("customEmail.submit")?.toString()}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateEmail;
