import axios from "config/axios";
import { Company, RelUserCompany, User } from "shared/types";
import { GenericListResponse, GenericPageResponse, GenericResponse } from "shared/common";

const ENDPOINT = "/company";
const CUSTOM_ENDPOINT = "/marketingemail";
const REL_CUSTOM_ENDPOINT = "/relmarketingemailuser";

export const createCompany = (param: Company, tenantId: number) =>
  axios.post(`${ENDPOINT}/create`, {
    param,
    tenantId,
  }); //COMPANY_ADMIN

export const modifyCompany = (param: Company, tenantId: number) =>
  axios.put<GenericResponse<Company>>(`${ENDPOINT}/modify`, {
    param,
    tenantId,
  }); //COMPANY_ADMIN

export const listCompanyByTenant = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<Company>>(
    `${ENDPOINT}/reduced-list?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //COMPANY_GET

export const listCompanyForMonitoringByTenant = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<Company>>(
    `${ENDPOINT}/monitoring-list?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //MONITORING_GET

export const listCompanyPageable = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<Company>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //COMPANY_GET

export const getCompanyById = (companyId: number | string, tenantId: number) =>
  axios.post<GenericResponse<Company>>(`${ENDPOINT}/get-by-id`, {
    param: companyId,
    tenantId: tenantId,
  }); //COMPANY_GET

export const getCompanyUsersById = (companyId: number | string, tenantId: number) =>
  axios.post<GenericListResponse<RelUserCompany>>(`${ENDPOINT}/get-users`, {
    param: companyId,
    tenantId: tenantId,
  }); //COMPANY_GET

export const getCustomEmailList = (
  page = 0,
  pageSize = 10,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.post(
    `${CUSTOM_ENDPOINT}/get-page?page=${page}&size=${pageSize}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  );

export const getCustomEmailRelList = (
  page = 0,
  pageSize = 10,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.post(
    `${REL_CUSTOM_ENDPOINT}/get-page?page=${page}&size=${pageSize}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  );

export const multipleEmail = ({
  subject,
  content,
  tenantId,
  users,
  emails,
  campaignName,
  replyEmail,
  companyId,
}: {
  subject: string;
  content: string;
  users: User[];
  emails: string[];
  campaignName?: string;
  replyEmail?: string;
  companyId?: string | number;
  tenantId: number;
}) =>
  axios.post(`${CUSTOM_ENDPOINT}/send-email`, {
    subject: subject,
    content: content,
    users: users,
    emails: emails,
    campaignName: campaignName,
    replyEmail,
    companyId,
    tenantId,
  });
