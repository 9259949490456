import { Box, Container, Tab, Tabs } from "@material-ui/core";
import PrivateRoute from "components/PrivateRoute";
import { RootState } from "config/store";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router";
import { Link, Switch } from "react-router-dom";
import JobFormDetails from "./JobFormDetails";
import JobFormPartners from "./JobFormPartners";

export const JOB_FORM_DETAILS_TABS = [`/job-forms/details`, `/job-forms/partners`];

const JobFormDetailsTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  return (
    <>
      {location.pathname === "/job-forms" && <Redirect to={`/job-forms/details?id=${id}`} />}
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
        style={{ backgroundColor: "rgba(255, 255, 255, 0.6)", paddingLeft: 24, paddingRight: 24 }}
      >
        <Tab
          label={t("jobForm.data")}
          component={Link}
          to={`${JOB_FORM_DETAILS_TABS[0]}?id=${id}`}
          value={JOB_FORM_DETAILS_TABS[0]}
        />
        {(account?.user?.isSuperAdmin || selectedRelTenant?.isTenantAdmin) && (
          <Tab
            label={t("jobForm.jobTo")}
            component={Link}
            to={`${JOB_FORM_DETAILS_TABS[1]}?id=${id}`}
            value={JOB_FORM_DETAILS_TABS[1]}
          />
        )}
      </Tabs>
      <Container maxWidth="xl">
        <Box>
          <Switch location={location}>
            <PrivateRoute path={JOB_FORM_DETAILS_TABS[0]} component={JobFormDetails} />
            {(account?.user?.isSuperAdmin || selectedRelTenant?.isTenantAdmin) && (
              <PrivateRoute path={JOB_FORM_DETAILS_TABS[1]} component={JobFormPartners} />
            )}
          </Switch>
        </Box>
      </Container>
    </>
  );
};

export default JobFormDetailsTabs;
