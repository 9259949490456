import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, IconButton, Tooltip } from "@material-ui/core";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import FilterBar from "components/PageableTable/FilterBar";
import { PRIORITY, STATUSES } from "config/constants";
import { RootState } from "config/store";
import { BOX_SHADOW, COLORS } from "config/theme";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Issue } from "shared/types";
import { TableState } from "views/Company/CompanyList";
import IssueRelIssueCreateModal from "../IssueDetails/IssueRelIssueCreateModal";
import KanbanArrangeModal from "./components/KanbanArrangeModal";
import KanbanItemList from "./components/KanbanItemList";
import { useQuery } from "react-query";
import { listIssueStatusPageable } from "shared/network/issue-status.api";

const KanbanBoard = () => {
  const { t } = useTranslation();
  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;
  const [orderValue, setOrderValue] = useState("");
  const [createOpen, setCreateOpen] = useState<boolean>(false);
  const [modalIssue, setModalIssue] = useState<Issue>();
  const [orderAnchorEl, setOrderAnchorEl] = useState<HTMLDivElement | HTMLButtonElement | null>(
    null,
  );
  const [tableState, setTableState] = useState<TableState>({
    page: 0,
    pageSize: 15,
    filterOpen: false,
    filterValue: "",
  });

  const columns: GridColDef[] = [
    {
      field: "assignedUser.name",
      headerName: t("issues.formValues.assignedUser"),
      type: "stringContainsNumber",
    },
    {
      field: "priority",
      headerName: t("issues.formValues.priority"),
      type: "select",
      valueGetter: ({ row }: GridValueGetterParams) => t(`common:priority.${row.priority}`),
    },
  ];

  const issueStatusListQuery = useQuery(
    ["issueStatusList", tenant.id],
    async () => {
      const { data } = await listIssueStatusPageable(0, 2000, tenant.id, "isDeleted=false");
      return data;
    },
    { enabled: selectedRelTenant?.tenant?.isIssueOwnType },
  );

  const columnCount = selectedRelTenant?.tenant?.isIssueOwnType
    ? issueStatusListQuery?.data?.page?.content?.length || 0
    : STATUSES?.length;

  return (
    <>
      <IssueRelIssueCreateModal
        key={modalIssue?.id}
        open={createOpen}
        setOpen={setCreateOpen}
        issue={modalIssue}
      />

      <Box display="flex" justifyContent="space-between">
        <FilterBar
          columns={columns}
          setTableState={setTableState}
          sessionStorageKey={"email-list"}
          id={tenant?.id}
          smallMargin
          filterOptions={[
            {
              columnName: "priority",
              options: PRIORITY?.map(value => {
                return {
                  translated: t(`common:priority.${value}`),
                  value: value?.toString(),
                };
              }),
            },
          ]}
        />
        <Tooltip title={t("common:arrangement.title").toString()}>
          <div>
            <IconButton
              size="small"
              onClick={event => {
                event.stopPropagation();
                setOrderAnchorEl(event?.currentTarget);
              }}
              style={{
                color: COLORS.white,
                backgroundColor: COLORS.mainLight,
                boxShadow: BOX_SHADOW,
                height: 32,
                width: 32,
                margin: 8,
              }}
            >
              <FontAwesomeIcon
                icon={faSort}
                style={{
                  fontSize: 14,
                  width: 14,
                  color: COLORS.white,
                }}
              />
            </IconButton>
          </div>
        </Tooltip>
        <KanbanArrangeModal
          setArrangeValue={setOrderValue}
          anchorEl={orderAnchorEl}
          onClose={() => {
            setOrderAnchorEl(null);
          }}
        />
      </Box>
      <div style={{ overflowX: "auto", width: "100%" }}>
        <Grid container justifyContent="center" style={{ minWidth: columnCount * 250 }}>
          {selectedRelTenant?.tenant?.isIssueOwnType
            ? issueStatusListQuery?.data?.page?.content?.map((issueStatus, index) => {
                return (
                  <KanbanItemList
                    key={index}
                    index={index}
                    ownStatus={issueStatus}
                    setModalIssue={setModalIssue}
                    setCreateOpen={setCreateOpen}
                    searchValue={tableState?.filterValue}
                    orderValue={orderValue}
                    columnCount={issueStatusListQuery?.data?.page?.content?.length || 0}
                  />
                );
              })
            : STATUSES.map((status, index) => {
                return (
                  <KanbanItemList
                    key={index}
                    index={index}
                    status={status}
                    setModalIssue={setModalIssue}
                    setCreateOpen={setCreateOpen}
                    searchValue={tableState?.filterValue}
                    orderValue={orderValue}
                    columnCount={STATUSES?.length}
                  />
                );
              })}
        </Grid>
      </div>
    </>
  );
};

export default KanbanBoard;
