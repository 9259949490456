import { Box, Button, Grid } from "@material-ui/core";
import { AddBox } from "@material-ui/icons";
import FormCard from "components/FormCard";
import { RootState } from "config/store";
import { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getCompositeItemsById } from "shared/network/item.api";
import { Item } from "shared/types";
import { ItemFormValues } from "views/Items/components/ItemForm";
import CompositeItemFormRow from "./CompositeItemFormRow";

type Props = {
  item?: Item;
  calculatePriceFromComposites: () => void;
};

const CompositeItemForm = ({ item, calculatePriceFromComposites }: Props) => {
  const { t } = useTranslation();
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);
  const { control, setValue } = useFormContext<ItemFormValues>();

  const { append, fields, remove } = useFieldArray({
    control,
    name: "compositeItems",
    keyName: "key",
  });

  const compositeItemListQuery = useQuery(["caregoryListQuery", tenant.id, item?.id], async () => {
    if (item?.id) {
      const { data } = await getCompositeItemsById(tenant.id, item?.id.toString());
      return data.items;
    }
    return Promise.reject();
  });
  const compositeItems = compositeItemListQuery.data;

  useEffect(() => {
    append({
      buildingItem: null,
      compositeItem: item,
      quantity: 1,
      type: "",
    });
  }, [append]); //eslint-disable-line

  useEffect(() => {
    setValue("compositeItems", item?.id && item?.isComposite ? compositeItems || [] : []);
  }, [compositeItems, tenant.id, setValue]); //eslint-disable-line

  return (
    <>
      <FormCard title={t("item.compositeDetails")}>
        <Grid container>
          {fields.map((field, index) => {
            return (
              <CompositeItemFormRow
                key={field.key}
                field={field}
                remove={remove}
                index={index}
                calculatePriceFromComposites={calculatePriceFromComposites}
                compositeItems={compositeItems}
              />
            );
          })}
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={event => {
                  event.stopPropagation();
                  append({});
                }}
                startIcon={<AddBox />}
              >
                {t("item.addCompositePart")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </FormCard>
    </>
  );
};

export default CompositeItemForm;
