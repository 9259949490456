import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, Tooltip } from "@material-ui/core";
import { COLORS } from "config/theme";
import { useTranslation } from "react-i18next";
import { RelTag } from "shared/network/rel-tag.api.ts";
import { getIcon } from "./RelTagIcons";

type Props = {
  row: any;
  relTagList: any;
  setSelectedRow: any;
  setSelectedToModify: any;
  isSmallScreen?: boolean;
};

const TagIcon = ({
  row,
  relTagList,
  setSelectedRow,
  setSelectedToModify,
  isSmallScreen,
}: Props) => {
  const { t }: any = useTranslation();
  return (
    <Box
      display="flex"
      justifyContent={isSmallScreen ? "flex-start" : "space-between"}
      width="100%"
    >
      <Box>
        {relTagList?.map((rel: RelTag) => (
          <Tooltip key={rel?.id} title={rel.tag.tag}>
            <IconButton size="small" onClick={() => setSelectedToModify(rel)}>
              <FontAwesomeIcon icon={getIcon(rel.icon)} style={{ color: rel?.color }} />
            </IconButton>
          </Tooltip>
        ))}
      </Box>
      <Box>
        <Tooltip title={t("tags.add")}>
          <IconButton size="small" onClick={() => setSelectedRow(row?.id)}>
            <FontAwesomeIcon icon={faAdd} style={{ color: COLORS.lightGrey }} />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default TagIcon;
