import { Container } from "@material-ui/core";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { SliceStatus } from "shared/common";
import { getIssueStatus, modifyIssueStatus } from "shared/network/issue-status.api";
import { IssueType } from "shared/types";
import IssueStatusForm from "./IssueStatusForm";

const IssueStatusModify = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const form = useForm<IssueType>();
  const [status, setStatus] = useState<SliceStatus>("idle");

  const issueStatusQuery = useQuery(["issueStatusQuery", tenant.id], async () => {
    const { data } = await getIssueStatus(Number(id), tenant.id);
    return data.item;
  });

  const onSubmitModify = async (values: IssueType) => {
    setStatus("pending");
    try {
      await modifyIssueStatus({ ...issueStatusQuery.data, ...values }, tenant.id);
      enqueueSnackbar(t("common:notification.modify.success"), { variant: "success" });
      setStatus("success");
      history.goBack();
    } catch {
      enqueueSnackbar(t("common:notification.modify.failure"), { variant: "error" });
      setStatus("failure");
    }
  };

  return (
    <Container maxWidth="lg">
      <form onSubmit={form.handleSubmit(onSubmitModify)}>
        <Loading open={issueStatusQuery.isFetching || status === "pending"} />
        {issueStatusQuery.data && (
          <FormProvider {...form}>
            <IssueStatusForm issueStatus={issueStatusQuery.data} />
          </FormProvider>
        )}
      </form>
    </Container>
  );
};

export default IssueStatusModify;
