import axios from "config/axios";
import { GenericPageResponse, GenericResponse } from "shared/common";
import { Tag } from "./tag.api";

export type RelTag = {
  id: number;
  tenantId: number;
  tag: Tag;
  type: TAG_TYPE;
  subjectId: number;
  color: string;
  icon: string;
};

export type TAG_TYPE = "USER" | "COMPANY" | "JOB_QUESTIONNAIRE" | "PROJECT" | "ISSUE" | "OFFER";

const ENDPOINT = "/rel-tag";

export const createRelTag = (param: any, tenantId: number) =>
  axios.post<GenericResponse<RelTag>>(`${ENDPOINT}/create`, { param, tenantId });

export const modifyRelTag = (param: string, tenantId: number) =>
  axios.put(`${ENDPOINT}/modify`, { param, tenantId });

export const deleteRelTag = (param: string, tenantId: number) =>
  axios.delete(`${ENDPOINT}`, { data: { param, tenantId } });

export const getRelTagById = (id: number, tenantId: number) =>
  axios.post(`${ENDPOINT}/get-by-id`, { param: id, tenantId });

export const getRelTagPage = (
  tenantId: number,
  page = 0,
  size = 10,
  search: string = "",
  sort: string = "",
) => {
  return axios.get<GenericPageResponse<RelTag>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${
      search || ""
    }&sort=${sort || ""}`,
  );
};
