import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getTagById, modifyTag } from "shared/network/tag.api";

type FormValues = {
  tag: string;
};

type Props = {
  selectedId: number | null;
  setSelectedId: Dispatch<SetStateAction<number | null>>;
  refetch: () => void;
};

const ModifyTag = ({ selectedId, setSelectedId, refetch }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const tenantId = useSelector(
    (state: RootState) => state.authentication?.selectedRelTenant?.tenant?.id,
  );
  const form = useForm();
  const { handleSubmit } = form;
  const [loading, setLoading] = useState(false);

  const getTagByIdQuery = useQuery(
    ["getTagByIdQuery", selectedId, tenantId],
    async () => {
      const { data } = await getTagById(Number(selectedId), tenantId);
      return data.item;
    },
    {
      enabled: !!selectedId,
      onSuccess: data => form.reset(data),
    },
  );
  const tag = getTagByIdQuery.data;

  async function onSubmit(values: FormValues) {
    setLoading(true);
    try {
      await modifyTag({ ...tag, ...values }, tenantId);
      refetch();
      enqueueSnackbar(t("common:notification.modify.success"), { variant: "success" });
      setSelectedId(null);
    } catch {
      enqueueSnackbar(t("common:notification.modify.failure"), { variant: "error" });
    }
    setLoading(false);
  }

  return (
    <>
      <Loading open={loading || getTagByIdQuery?.isFetching} />
      <Dialog fullWidth maxWidth="xs" open={!!selectedId} onClose={() => setSelectedId(null)}>
        <DialogTitle>{t("tags.modify")}</DialogTitle>
        <DialogContent>
          <FormProvider {...form}>
            <form id="tag-form" onSubmit={handleSubmit(onSubmit)}>
              <Grid item xs={12}>
                <Controller
                  name="tag"
                  rules={{
                    required: t("validation.required").toString(),
                  }}
                  defaultValue={null}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("tags.tag")}
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                      }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="text" onClick={() => setSelectedId(null)}>
            {t("common:button.cancel")}
          </Button>
          <Button disabled={loading} form="tag-form" variant="contained" type="submit">
            {t("common:button.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModifyTag;
