import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { RootState } from "config/store";
import { Dispatch, SetStateAction, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createReminder } from "shared/network/reminder.api";
import { Company, Issue, Project, User } from "shared/types";
import ReminderForm from "./ReminderForm";
import queryClient from "config/query";
import { useSnackbar } from "notistack";
import Loading from "components/Loading";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  company?: Company;
  issue?: Issue;
  project?: Project;
};

export type ReminderFormValues = {
  reminderUser?: User | null;
  company?: any | null; //Company
  issue?: any | null; //Issue
  reminderMessage: string;
  when?: Date | null;
  tenantId: number;
  project?: Project | null;
};

const ReminderCreateDialog = ({ open, setOpen, company, issue, project }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const tenant = useSelector((state: RootState) => state.authentication?.selectedRelTenant?.tenant);
  const form = useForm<ReminderFormValues>({ mode: "all" });
  const { handleSubmit } = form;
  const [loading, setLoading] = useState(false);

  async function onSubmit(values: ReminderFormValues) {
    setLoading(true);
    try {
      await createReminder({
        ...values,
        message: values.reminderMessage,
        when: values.when?.toISOString(),
        tenantId: tenant?.id,
      });
      queryClient.refetchQueries("reminderList");
      onClose();
      enqueueSnackbar(
        t("common:notification.create.success", {
          subject: t("reminder.subject"),
        }),
        { variant: "success" },
      );
    } catch {
      enqueueSnackbar(
        t("common:notification.create.failure", {
          subject: t("reminder.subject"),
        }),
        { variant: "error" },
      );
    }
    setLoading(false);
  }

  function onClose() {
    setOpen(false);
  }

  return (
    <>
      <Loading open={loading} />
      <Dialog maxWidth="sm" fullWidth open={open} onClose={() => onClose()}>
        <DialogTitle>{t("reminder.create")}</DialogTitle>
        <DialogContent>
          <FormProvider {...form}>
            <form id="reminder-create" onSubmit={handleSubmit(onSubmit)}>
              <ReminderForm company={company} issue={issue} project={project} />
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => onClose()}>
            {t("common:button.cancel")}
          </Button>
          <Button form="reminder-create" type="submit">
            {t("common:button.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReminderCreateDialog;
