import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Loading from "components/Loading";
import SignatureCanvas from "components/SignatureCanvas";
import { BACKEND_URL } from "config/constants";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { listContactByTenant } from "shared/network/contact.api";
import { getIssueFile, getIssueTigFile } from "shared/network/files.api";
import { createIssueTig, createIssueWorksheet } from "shared/network/issues.api";
import { Company, Contact } from "shared/types";
import { dowloadPdf } from "shared/util/fileDownload";
import { useDebouncedCallback } from "use-debounce";

type Props = {
  issueId: number;
  issueSerialNumber: string;
  issueCompany?: Company | null;
  type: "DOWNLOAD" | "OPEN" | "TIG_DOWNLOAD" | null;
  setType: Dispatch<SetStateAction<"DOWNLOAD" | "OPEN" | "TIG_DOWNLOAD" | null>>;
  refetch?: () => void;
};

export type FormValues = {
  file?: FileList;
  userFullName?: string | null;
  userJob?: string | null;
};

const IssueSignDialog = ({
  issueId,
  issueSerialNumber,
  type,
  setType,
  issueCompany,
  refetch,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<FormValues>();
  const { handleSubmit } = form;
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const ref = useRef<HTMLDivElement | null>();
  const [offset, setOffset] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
  const [loading, setLoading] = useState(false);
  const [contactSearch, setContactSearch] = useState<string>("");

  const onSubmitCreate = async (values: FormValues) => {
    setLoading(true);
    try {
      const canvas = document.getElementById("signature") as HTMLCanvasElement;
      if (canvas) {
        canvas.toBlob(async blob => {
          if (blob) {
            const deliveryPromise = await createIssueWorksheet({
              issueId: issueId?.toString(),
              tenantId: tenant.id.toString(),
              userName: values.userFullName || null,
              userJob: values.userJob || null,
              file: new File([blob], "signature.png", { type: "image/png" }),
            }).catch(() => {
              enqueueSnackbar(t("Sikertelen exportálás"), {
                variant: "error",
              });
              setLoading(false);
            });
            if (!!deliveryPromise) {
              if (type === "DOWNLOAD") {
                downloadWorksheetPDF();
              } else {
                openWorksheetPDF();
              }
              enqueueSnackbar("Sikeres exportálás.", {
                variant: "success",
              });
              refetch?.();
              setLoading(false);
            }
          }
        }, "image/png");
      }
    } catch (e: any) {
      enqueueSnackbar(t("common:notification.DEFAULT"), { variant: "error" });
      setLoading(false);
    }
  };

  const onSubmitTigCreate = async (values: FormValues) => {
    setLoading(true);
    try {
      const canvas = document.getElementById("signature") as HTMLCanvasElement;
      if (canvas) {
        canvas.toBlob(async blob => {
          if (blob) {
            const deliveryPromise = await createIssueTig({
              issueId: issueId?.toString(),
              tenantId: tenant.id.toString(),
              userName: values.userFullName || null,
              userJob: values.userJob || null,
              file: new File([blob], "signature.png", { type: "image/png" }),
            }).catch(() => {
              enqueueSnackbar(t("Sikertelen exportálás"), {
                variant: "error",
              });
              setLoading(false);
            });
            if (!!deliveryPromise) {
              downloadWorksheetPDF();
              enqueueSnackbar("Sikeres exportálás.", {
                variant: "success",
              });
              refetch?.();
              setLoading(false);
            }
          }
        }, "image/png");
      }
    } catch (e: any) {
      enqueueSnackbar(t("common:notification.DEFAULT"), { variant: "error" });
      setLoading(false);
    }
  };

  const openWorksheetPDF = async () => {
    setLoading(true);
    try {
      window.open(`${BACKEND_URL}/files/issue/worksheet/${issueId}`, "_blank");
      setType(null);
    } catch (error) {
      enqueueSnackbar(
        t("common:notification.open.failure", {
          subject: t("issues.worksheet.subject"),
        }),
        { variant: "error" },
      );
    }
    setLoading(false);
  };

  const downloadWorksheetPDF = async () => {
    setLoading(true);
    if (issueId) {
      try {
        if (type === "TIG_DOWNLOAD") {
          const { data } = await getIssueTigFile(issueId.toString(), tenant.id);
          dowloadPdf(data, issueSerialNumber?.replace("MUNKALAP", "TIG"));
        } else {
          const { data } = await getIssueFile(issueId.toString(), tenant.id);
          dowloadPdf(data, issueSerialNumber);
        }

        setType(null);
      } catch (error: any) {
        if (type === "TIG_DOWNLOAD") {
          enqueueSnackbar(
            t("common:notification.download.failure", {
              subject: t("common:pdfDownload.TIG"),
            }),
            { variant: "error" },
          );
        } else {
          enqueueSnackbar(
            t("common:notification.download.failure", {
              subject: t("common:pdfDownload.WORKSHEET"),
            }),
            { variant: "error" },
          );
        }
      }
    }
    setLoading(false);
  };

  const contactListQuery = useQuery(
    ["contactListForDeliveryStatus", tenant.id, issueCompany?.id, contactSearch],
    async () => {
      const { data } = await listContactByTenant(
        0,
        10,
        tenant.id,
        (contactSearch ? `name:${contactSearch}` : ``) +
          (issueCompany?.id ? `;companyId:${issueCompany.id}` : ""),
      );
      return data.page;
    },
    { enabled: !!issueCompany },
  );

  const handleContactSearchChange = useDebouncedCallback((value: string) => {
    setContactSearch(value);
  }, 300);

  return (
    <>
      <Loading open={loading} />
      <Dialog open={!!type} onClose={() => setType(null)} PaperProps={{ style: { margin: 0 } }}>
        <DialogTitle style={{ padding: "16px 16px 8px 16px" }}>
          {type === "OPEN"
            ? t("issues.worksheet.open")
            : type === "DOWNLOAD"
            ? t("issues.worksheet.download")
            : type === "TIG_DOWNLOAD"
            ? t("issues.worksheet.tigDownload")
            : ""}
        </DialogTitle>
        <DialogContent
          style={{ padding: 16 }}
          ref={ref}
          onScroll={() => {
            setOffset({ top: ref.current?.scrollTop || 0, left: ref.current?.scrollLeft || 0 });
          }}
        >
          <FormProvider {...form}>
            <form
              id="issue-sign"
              onSubmit={handleSubmit(type === "TIG_DOWNLOAD" ? onSubmitTigCreate : onSubmitCreate)}
            >
              <Grid container style={{ width: 350, margin: 0 }}>
                <Grid item xs={12} style={{ paddingBottom: 16 }}>
                  {!!issueCompany && (
                    <Controller
                      name="contact"
                      defaultValue={null}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          {...field}
                          onChange={(_, value) => {
                            field.onChange(value);
                            handleContactSearchChange("");
                            form.setValue("userFullName", value?.name);
                            form.setValue("userJob", value?.title);
                          }}
                          onInputChange={(event, newInputValue) => {
                            handleContactSearchChange(newInputValue);
                          }}
                          options={contactListQuery.data?.content || []}
                          getOptionLabel={(option: Contact) => option.name}
                          getOptionSelected={option => option.name === field.value?.name}
                          renderInput={params => (
                            <TextField
                              {...params}
                              InputLabelProps={{ shrink: true }}
                              label="Átvevő kiválasztása"
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={12} style={{ paddingBottom: 16 }}>
                  <Controller
                    name="userFullName"
                    defaultValue=""
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label="Átvevő neve"
                        InputLabelProps={{ shrink: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} style={{ paddingBottom: 16 }}>
                  <Controller
                    name="userJob"
                    defaultValue=""
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label="Átvevő beosztása"
                        InputLabelProps={{ shrink: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} style={{ paddingBottom: 8 }}>
                  <Divider />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <SignatureCanvas
                    id="signature"
                    offsetTop={offset.top}
                    offsetLeft={offset.left}
                    optional
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="center" gridGap={8}>
            <Button color="primary" variant="text" onClick={() => setType(null)}>
              {t("common:button.cancel")}
            </Button>
            <Button form="issue-sign" type="submit" color="primary">
              {t("common:button.ok")}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default IssueSignDialog;
