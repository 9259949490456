import { STATUSES } from "config/constants";
import { Dispatch, SetStateAction } from "react";
import { Issue } from "shared/types";
import KanbanItem from "./KanbanItem";
import { Box, Button, CircularProgress, Grid, LinearProgress, Typography } from "@material-ui/core";
import { COLORS } from "config/theme";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery, useQuery } from "react-query";
import { listIssues } from "shared/network/issues.api";
import { RootState } from "config/store";
import { useSelector } from "react-redux";
import { Skeleton } from "@material-ui/lab";
import { IssueStatus } from "shared/network/issue-status.api";

type Props = {
  index: number;
  setCreateOpen: Dispatch<SetStateAction<boolean>>;
  setModalIssue: Dispatch<SetStateAction<Issue | undefined>>;
  status?: string;
  ownStatus?: IssueStatus;
  orderValue: string;
  searchValue: string;
  columnCount: number;
};

const defaultIssue = {
  id: "",
  name: "",
  description: "",
  status: "",
  priority: "",
  project: null,
  parentIssueId: "",
  startDate: "2022-01-01",
  endDate: "2022-01-01",
  estimatedTime: 0,
  company: null,
  completionPercentage: 0,
  contract: null,
  responsibleUser: null,
  offer: null,
  issueTemplateId: 0,
  issueType: null,
  spentTime: 0,
  serialNumber: "",
  relIssueIssues: [],
  worksheetPath: "",
  tigPath: "",
  companySite: null,
  numberOfChildren: 0,
  contractorCompany: null,
};

function length(statutes: string[], issues: Issue[]) {
  let maxLength = 0;
  statutes.forEach(s => {
    let issueLength = issues?.filter(issue => issue.status === s).length;
    if (issueLength && maxLength < issueLength) {
      maxLength = issueLength;
    }
  });
  return maxLength;
}

const KanbanItemList = ({
  index,
  status,
  ownStatus,
  setCreateOpen,
  setModalIssue,
  orderValue,
  searchValue,
  columnCount,
}: Props) => {
  const { t } = useTranslation();
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;
  const isAdmin = account.user.isSuperAdmin || selectedRelTenant.isTenantAdmin;
  const userId = account.user.id;
  const tempIssue: Partial<Issue> = { ...defaultIssue, status: status, issueStatusOwn: ownStatus };

  const issuesQuery = useInfiniteQuery(
    [
      `kanbanIssuesQuery-${status || ownStatus?.id}`,
      tenant.id,
      status,
      ownStatus?.id,
      isAdmin,
      searchValue,
      userId,
      orderValue,
    ],
    async ({ pageParam = 0 }) => {
      const { data } = await listIssues(
        pageParam,
        50,
        tenant.id,
        (status ? `status:${status};` : `issueStatusOwn.id:${ownStatus?.id};`) +
          (isAdmin ? searchValue : `assignedUser.id:${userId};${searchValue}`),
        orderValue,
      );
      return data;
    },
    {
      getNextPageParam: lastGroup => {
        const nextPage = lastGroup.page.number + 1;
        if (nextPage <= lastGroup.page.totalPages - 1) {
          return nextPage;
        }
        return false;
      },
    },
  );
  const { data, fetchNextPage, isLoading, isFetching, isFetchingNextPage } = issuesQuery;

  const issues = data?.pages.map(page => page.page?.content).flat();
  const hasNextPage =
    data && issues && (data?.pages?.[0]?.page?.totalElements || 0) > issues?.length;

  return (
    <Grid
      key={index}
      item
      xs={2}
      style={{
        minWidth: 250,
        marginTop: 8,
        backgroundColor: "rgba(255, 255, 255, 0.6)",
        borderRight: index !== 0 || index !== columnCount - 1 ? `1px solid #d3d3d3` : 0,
        borderLeft: index === 0 ? "1px solid #d3d3d3" : "0px",
        borderBottom: "1px solid #d3d3d3",
      }}
    >
      <Grid
        item
        xs={12}
        style={{
          position: "sticky",
          top: 0,
          backgroundColor: COLORS.mainGrey,
          borderBottom: `1px solid #d3d3d3`,
          borderTop: "1px solid #d3d3d3",
        }}
      >
        <Typography
          style={{
            fontWeight: "bold",
            textAlign: "center",
            padding: 10,
          }}
        >
          {status ? t(`common:statuses.${status}`) : ownStatus?.name}
        </Typography>
        {isFetching && <LinearProgress />}
      </Grid>
      <Grid item xs={12}>
        {isLoading ? (
          <>
            <Skeleton
              variant="rect"
              style={{ height: 250, margin: 8, padding: 4, borderRadius: 10 }}
            />
            <Skeleton
              variant="rect"
              style={{ height: 250, margin: 8, padding: 4, borderRadius: 10 }}
            />
            <Skeleton
              variant="rect"
              style={{ height: 250, margin: 8, padding: 4, borderRadius: 10 }}
            />
          </>
        ) : !issues?.length ? (
          <KanbanItem
            issue={tempIssue as Issue}
            setCreateOpen={setCreateOpen}
            setModalIssue={setModalIssue}
            refetch={issuesQuery.refetch}
            length={1}
          />
        ) : (
          issues?.map(issue => (
            <KanbanItem
              key={issue.id}
              issue={issue}
              setCreateOpen={setCreateOpen}
              setModalIssue={setModalIssue}
              refetch={issuesQuery.refetch}
              length={length(STATUSES, issues)}
            />
          ))
        )}
      </Grid>
      {hasNextPage && (
        <Grid item xs={12}>
          <Box textAlign="center" p={1}>
            {isFetchingNextPage ? (
              <CircularProgress size={24} />
            ) : (
              <Button
                size="small"
                variant="text"
                onClick={event => {
                  fetchNextPage();
                }}
              >
                {t("loadMore")}...
              </Button>
            )}
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default KanbanItemList;
