import { FormControlLabel, Grid, MenuItem, Switch, TextField, Tooltip } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import FormCard from "components/FormCard";
import InputSkeleton from "components/InputSkeleton";
import {
  NUMBER_FORMAT,
  PRIORITY,
  PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH,
  PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY,
} from "config/constants";
import { RootState } from "config/store";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { listCompanyByTenant } from "shared/network/company.api";
import { listIssueTypeByTenant } from "shared/network/issue-type.api";
import { listProject } from "shared/network/project.api";
import { listUsers } from "shared/network/user.api";
import { Company, IssueTemplate, Project, User, Worksheet } from "shared/types";
import { RelIssueTemplateFormValues } from "../IssueTemplateForm";
import { useDebouncedCallback } from "use-debounce";
import { Info } from "@material-ui/icons";
import { COLORS } from "config/theme";
import { listWorksheet } from "shared/network/worksheet.api";

type Props = {
  issueTemplate?: IssueTemplate;
};

const DataCard = ({ issueTemplate }: Props) => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<RelIssueTemplateFormValues>();
  const selectedCompany = watch("issueTemplateEntry.company");

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const [projectSearch, setProjectSearch] = useState<string>("");
  const [userSearch, setUserSearch] = useState<string>("");
  const [companySearch, setCompanySearch] = useState<string>("");

  const projectListQuery = useQuery(
    ["projectListForIssueTemplate", tenant.id, projectSearch, selectedCompany],
    async () => {
      const { data } = await listProject(
        0,
        10,
        tenant.id,
        (selectedCompany ? `company.id:${selectedCompany?.id};` : ``) +
          (projectSearch ? `(name:${projectSearch};(OR)identifier:${projectSearch};)` : ``),
      );
      return data.page.content;
    },
  );

  const userListQuery = useQuery(["userListForIssueTemplate", tenant.id, userSearch], async () => {
    const { data } = await listUsers(0, 10, tenant.id, userSearch ? `name:${userSearch}` : ``);
    return data.page.content;
  });
  const worksheetListQuery = useQuery(["worksheetListForIssue", tenant.id], async () => {
    const { data } = await listWorksheet(tenant.id);
    return data.items;
  });

  const companyListQuery = useQuery(
    ["companyListForIssueTemplate", tenant.id, companySearch],
    async () => {
      const { data } = await listCompanyByTenant(
        0,
        10,
        tenant.id,
        companySearch ? `nameSearch:${companySearch}` : ``,
      );
      return data;
    },
  );

  const issueTypeListQuery = useQuery(["issueTypeListForIssueTemplate", tenant.id], async () => {
    const { data } = await listIssueTypeByTenant(tenant.id);
    return data.items;
  });

  const handleProjectSearchChange = useDebouncedCallback((value: string) => {
    if (value.length >= PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH || value.length === 0) {
      setProjectSearch(value);
    }
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  const handleUserSearchChange = useDebouncedCallback((value: string) => {
    if (value.length >= PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH || value.length === 0) {
      setUserSearch(value);
    }
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  const handleCompanySearchChange = useDebouncedCallback((value: string) => {
    if (value.length >= PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH || value.length === 0) {
      setCompanySearch(value);
    }
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  return (
    <FormCard title={t("common:issueTemplateData")}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="issueTemplateEntry.name"
            defaultValue={issueTemplate?.name || ""}
            rules={{
              required: t("validation.required").toString(),
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t("issueTemplate.formValues.name")}
                InputLabelProps={{ shrink: true, required: true }}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="issueTemplateEntry.company"
            defaultValue={issueTemplate?.company || null}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                onChange={(_, value) => {
                  field.onChange(value);
                  setValue("issueTemplateEntry.project", null);
                  handleCompanySearchChange("");
                }}
                onInputChange={(event, newInputValue) => {
                  handleCompanySearchChange(newInputValue);
                }}
                options={companyListQuery.data?.page?.content || []}
                getOptionLabel={(option: Company) => option.name}
                getOptionSelected={option => option.id === field.value?.id}
                renderInput={params => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <Tooltip
                          style={{
                            paddingRight: "2px",
                          }}
                          title={t("tooltip.customer").toString()}
                        >
                          <Info style={{ color: COLORS.lightBlue }} />
                        </Tooltip>
                      ),
                    }}
                    label={t("issueTemplate.formValues.company")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        {!!selectedCompany?.id && (
          <Grid item xs={6}>
            <Controller
              name="issueTemplateEntry.project"
              defaultValue={issueTemplate?.project || null}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  onChange={(_, value) => {
                    field.onChange(value);
                    handleProjectSearchChange("");
                  }}
                  onInputChange={(event, newInputValue) => {
                    handleProjectSearchChange(newInputValue);
                  }}
                  options={projectListQuery.data || [] /*companyProjectList*/}
                  getOptionLabel={(option: Project) =>
                    (option.name || "") + option.identifier ? `(${option.identifier})` : ""
                  }
                  getOptionSelected={option => option.id === field.value?.id}
                  renderInput={params => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <Tooltip
                            style={{
                              paddingRight: "2px",
                            }}
                            title={t("tooltip.project").toString()}
                          >
                            <Info style={{ color: COLORS.lightBlue }} />
                          </Tooltip>
                        ),
                      }}
                      label={t("issueTemplate.formValues.project")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <Controller
            name="issueTemplateEntry.assignedUser"
            defaultValue={issueTemplate?.assignedUser || null}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                onChange={(_, value) => {
                  field.onChange(value);
                  handleUserSearchChange("");
                }}
                onInputChange={(event, newInputValue) => {
                  handleUserSearchChange(newInputValue);
                }}
                options={userListQuery.data?.filter(user => !user.isSuperAdmin) || []}
                getOptionLabel={(option: User) => option.name}
                getOptionSelected={option => option.id === field.value?.id}
                renderInput={params => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <Tooltip
                          style={{
                            paddingRight: "2px",
                          }}
                          title={t("tooltip.relIssue").toString()}
                        >
                          <Info style={{ color: COLORS.lightBlue }} />
                        </Tooltip>
                      ),
                    }}
                    label={t("issueTemplate.formValues.user")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="issueTemplateEntry.priority"
            defaultValue={issueTemplate?.priority || "NORMAL"}
            rules={{
              required: t("validation.required").toString(),
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t("issues.formValues.priority")}
                InputLabelProps={{ shrink: true, required: true }}
                SelectProps={{ displayEmpty: true }}
                select
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              >
                <MenuItem disabled value="default">
                  {t("common:choose")}
                </MenuItem>
                {PRIORITY.map(priority => (
                  <MenuItem key={priority} value={priority}>
                    {t(`common:priority.${priority}`)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="issueTemplateEntry.deadlineDays"
            defaultValue={issueTemplate?.deadlineDays?.toString() || "5"}
            rules={{
              validate: value => {
                if (value && !value.match(NUMBER_FORMAT)) {
                  return t("common:validation.numberFormat").toString();
                }
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Kezdéstől számított határidő (nap)"
                InputLabelProps={{ shrink: true }}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="issueTemplateEntry.estimatedTime"
            defaultValue={issueTemplate?.estimatedTime?.toString() || ""}
            rules={{
              required: t("validation.required").toString(),
              validate: value => {
                if (value && !value.match(NUMBER_FORMAT)) {
                  return t("common:validation.numberFormat").toString();
                }
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t("issueTemplate.formValues.estimatedTime")}
                InputLabelProps={{ shrink: true, required: true }}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {issueTypeListQuery.isFetching ? (
            <InputSkeleton />
          ) : (
            <Controller
              name="issueTemplateEntry.issueTypeId"
              defaultValue={issueTemplate?.issueTypeId.toString() || ""}
              rules={{
                required: t("validation.required").toString(),
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  select
                  label={t("issues.formValues.issueType")}
                  InputLabelProps={{ required: true, shrink: true }}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                >
                  <MenuItem disabled value="">
                    {t("common:choose")}
                  </MenuItem>
                  {issueTypeListQuery.data?.length &&
                    issueTypeListQuery.data.map(issueType => (
                      <MenuItem key={issueType.id} value={issueType.id}>
                        {issueType.type}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="issueTemplateEntry.worksheet"
            defaultValue={issueTemplate?.worksheet || null}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                onChange={(_, value) => {
                  field.onChange(value);
                }}
                onInputChange={(event, newInputValue) => {
                  handleCompanySearchChange(newInputValue);
                }}
                options={worksheetListQuery.data || []}
                getOptionLabel={(option: Worksheet) => option.name || ""}
                getOptionSelected={option => option.id === field.value?.id}
                renderInput={params => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    label={t("issues.formValues.worksheet")}
                    InputLabelProps={{ shrink: true }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="issueTemplateEntry.description"
            defaultValue={issueTemplate?.description || ""}
            rules={{
              required: t("validation.required").toString(),
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                multiline
                minRows={4}
                label={t("issueTemplate.formValues.description")}
                InputLabelProps={{ shrink: true, required: true }}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Controller
            name="issueTemplateEntry.isActive"
            defaultValue={!!issueTemplate?.isActive}
            render={({ field: { onChange, value, ref } }) => (
              <FormControlLabel
                label={t("issueTemplate.formValues.isActive")}
                labelPlacement="start"
                control={
                  <Switch onChange={onChange} checked={value} inputRef={ref} color="primary" />
                }
              />
            )}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default DataCard;
