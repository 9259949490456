import { Box, Button, Container, Grid, TextField, Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import FormCard from "components/FormCard";
import {
  PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY,
  PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH,
} from "config/constants";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { listCompanyByTenant } from "shared/network/company.api";
import { Company, IssueType } from "shared/types";
import { useDebouncedCallback } from "use-debounce";
import ColorPicker from "./ColorPicker";

type Props = {
  issueType?: IssueType;
  selectedCompany?: Company | null;
  isDialog: boolean;
  setIssueTypeOpen: any;
};

export type IssueTypeFormValues = {
  id: number | string;
  tenantId: number;
  company: Company | null;
  type: string;
  color: string;
};

const IssueTypeForm = ({ issueType, selectedCompany, isDialog, setIssueTypeOpen }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { control, setValue } = useFormContext<IssueTypeFormValues>();

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const [companySearch, setCompanySearch] = useState<string>("");

  const companyListQuery = useQuery(
    ["listCompanyForIssueType", tenant.id, companySearch],
    async () => {
      const { data } = await listCompanyByTenant(
        0,
        10,
        tenant.id,
        companySearch && `nameSearch:${companySearch}`,
      );
      return data;
    },
  );

  useEffect(() => {
    if (issueType?.companyId) {
      setValue(
        "company",
        companyListQuery.data?.page?.content.find(company => issueType.companyId === company.id) ||
          null,
      );
    }
  }, [companyListQuery.data, issueType?.companyId, setValue]);

  useEffect(() => {
    if (selectedCompany) {
      setValue("company", selectedCompany);
    }
    if (issueType) {
      setValue("type", issueType.type);
    }
  }, [selectedCompany, issueType, setValue]);

  const handleCompanySearchChange = useDebouncedCallback((value: string) => {
    if (value.length >= PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH || value.length === 0) {
      setCompanySearch(value);
    }
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  return (
    <Container maxWidth="xs" style={{ padding: isDialog ? 0 : undefined }}>
      <FormCard title={!isDialog ? t("common:issueType") : undefined}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="type"
              defaultValue={issueType?.type || ""}
              rules={{ required: t("validation.required").toString() }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true, required: true }}
                  label={t("issueType.formValues.type")}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="company"
              defaultValue={null}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  onChange={(_, value) => {
                    field.onChange(value);
                    handleCompanySearchChange("");
                  }}
                  onInputChange={(event, newInputValue) => {
                    handleCompanySearchChange(newInputValue);
                  }}
                  options={companyListQuery.data?.page?.content || []}
                  getOptionLabel={(option: Company) => option.name}
                  getOptionSelected={option => option.id === field.value?.id}
                  disabled={selectedCompany !== null}
                  renderInput={params => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <Tooltip
                            style={{
                              paddingRight: "2px",
                            }}
                            title={t("tooltip.company").toString()}
                          >
                            <Info style={{ color: COLORS.lightBlue }} />
                          </Tooltip>
                        ),
                      }}
                      InputLabelProps={{ shrink: true }}
                      label={t("issueType.formValues.company")}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="color"
              defaultValue={issueType?.color || "#B3D7FF"}
              render={({ field }) => (
                <ColorPicker
                  {...field}
                  label={t("issueType.formValues.color")}
                  value={field?.value}
                  setValue={field.onChange}
                />
              )}
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center" mt={2} gridGap={8}>
          <Button
            color="primary"
            variant="text"
            onClick={() => {
              if (setIssueTypeOpen) {
                setIssueTypeOpen(null);
              } else {
                history.goBack();
              }
            }}
          >
            {t("common:button.cancel")}
          </Button>
          <Button type="submit" color="primary">
            {!!issueType ? t("common:button.modify") : t("common:button.create")}
          </Button>
        </Box>
      </FormCard>
    </Container>
  );
};

export default IssueTypeForm;
