import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { BusinessCenter, Check, Close, Edit, Note } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import { GridColDef } from "@mui/x-data-grid";
import ConfirmationButton from "components/ConfirmationButton";
import Loading from "components/Loading";
import FilterBar from "components/PageableTable/FilterBar";
import queryClient from "config/query";
import { RootState } from "config/store";
import supportedLocales from "config/supportedLocales";
import { COLORS } from "config/theme";
import { format } from "date-fns";
import i18n from "i18n";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { hasAuthority } from "shared/authorization";
import { getCompanyById } from "shared/network/company.api";
import { getProjectById } from "shared/network/project.api";
import { listReminderPages, Reminder, updateStatus } from "shared/network/reminder.api";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";
import ReminderModifyDialog from "views/Reminder/ReminderModifyDialog";
import ReminderCreateDialog from "../ReminderCreateDialog";
import ReminderViewerListItem from "./ReminderViewerListItem";

type Props = {
  needTitle: boolean;
  companyId?: number;
  projectId?: number;
};

const ReminderViewerList = ({ needTitle, companyId, projectId }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const account = useSelector((state: RootState) => state?.authentication?.account);
  const selectedRelTenant = useSelector(
    (state: RootState) => state?.authentication?.selectedRelTenant,
  );

  const tenant = selectedRelTenant?.tenant;
  const isTenantAdmin = selectedRelTenant?.isTenantAdmin;
  const user = account?.user;

  const isSuperAdmin = user?.isSuperAdmin;

  const [loading, setLoading] = useState(false);
  const [selectedReminder, setSelectedReminder] = useState<Reminder | null>(null);
  const [selectedModifyReminder, setSelectedModifyReminder] = useState<Reminder | null>(null);
  const [reminderOpen, setReminderOpen] = useState(false);
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-reminder-list-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-reminder-list-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const reminderList = useQuery(
    ["reminderList", page, pageSize, user?.id, tenant?.id, filterValue],
    async () => {
      if (tenant?.id) {
        const { data } = await listReminderPages(
          page,
          pageSize,
          tenant?.id,
          (!!companyId
            ? `company.id=${companyId};`
            : projectId
            ? `project.id=${projectId};`
            : getUserFilter()) + filterValue,
          "",
        );
        return data;
      }
      return Promise.reject();
    },
    {
      onSuccess: data => {
        if (selectedReminder) {
          const updatedSelected = data?.page?.content?.find(
            value => value?.id === selectedReminder?.id,
          );
          setSelectedReminder(updatedSelected || null);
        }
      },
    },
  );

  function getUserFilter() {
    if (isTenantAdmin || isSuperAdmin) {
      return "";
    } else {
      return `user.id=${user?.id};`;
    }
  }

  const columns: GridColDef[] = [
    {
      field: "when",
      headerName: t("reminder.when"),
      flex: 2,
      type: "dateTime",
    },
    {
      field: "user.name",
      headerName: t("reminder.userName"),
      flex: 2,
      type: "stringContainsNumber",
    },
    {
      field: "company.name",
      headerName: t("reminder.companyName"),
      flex: 2,
      type: "stringContainsNumber",
    },
    {
      field: "issue.id",
      headerName: t("reminder.issueId"),
      flex: 1,
    },
    {
      field: "status",
      headerName: t("reminder.status"),
      hide: true,
      type: "select",
    },
    {
      field: "reminderMessage",
      headerName: t("reminder.reminderMessage"),
      flex: 3,
      type: "stringContainsNumber",
    },
  ];

  async function statusModify(id: number, status: "DONE" | "REMOVED") {
    setLoading(true);
    try {
      await updateStatus(id, status, tenant?.id);
      queryClient.refetchQueries("reminderList");
      setSelectedModifyReminder(null);
      enqueueSnackbar(t("common:notification.save.success"), { variant: "success" });
    } catch {
      enqueueSnackbar(t("common:notification.save.failure"), { variant: "error" });
    }
    setLoading(false);
  }

  const companyQuery = useQuery(
    ["companyByIdQuery", companyId],
    async () => {
      if (companyId) {
        const { data } = await getCompanyById(companyId, tenant?.id);
        return data.item;
      }
      return Promise.reject();
    },
    { enabled: !!companyId },
  );

  const projectByIdQuery = useQuery(
    ["projectByIdQuery", tenant?.id, projectId],
    async () => {
      if (projectId) {
        const { data } = await getProjectById(tenant?.id, projectId);
        return data.item;
      }
      return Promise.reject();
    },
    { enabled: !!projectId },
  );

  function getDefaultFilters(columns: GridColDef[]) {
    const statusColumn = columns?.find(column => column.field === "status");
    if (statusColumn) {
      return [
        {
          column: statusColumn,
          isNegate: false,
          value: "ACTIVE",
        },
      ];
    } else {
      return [];
    }
  }

  return (
    <Box overflow="auto">
      <Card style={{ backgroundColor: "rgba(255, 255, 255, 0.6)", minWidth: 700 }}>
        <Loading open={loading} />
        {selectedReminder && (
          <ReminderModifyDialog
            selectedReminder={selectedModifyReminder}
            setSelectedReminder={setSelectedModifyReminder}
          />
        )}
        {!!needTitle && (
          <Box style={{ margin: 10 }}>
            <Typography variant="h2">{t("reminder.reminders")}</Typography>
          </Box>
        )}
        <FilterBar
          columns={columns}
          setTableState={setTableState}
          sessionStorageKey={"email-list"}
          id={tenant?.id}
          smallMargin
          filterable={
            isTenantAdmin || isSuperAdmin
              ? undefined
              : ["when", "company.name", "issue.id", "reminderMessage"]
          }
          filterOptions={[
            {
              columnName: "status",
              options: ["ACTIVE", "DONE", "REMOVED"].map(value => {
                return {
                  translated: t(`reminder.${value}`),
                  value,
                };
              }),
            },
          ]}
          defaultFilters={getDefaultFilters(columns)}
        />
        <Divider />
        <Box style={{ display: "flex" }}>
          <Box>
            {(companyQuery?.data || projectByIdQuery?.data) &&
              hasAuthority(account?.user, account?.permissions, selectedRelTenant, [
                "EMPLOYEE_ADMIN",
              ]) && (
                <>
                  <Box display="flex" justifyContent="center" height={50}>
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={() => setReminderOpen(true)}
                      startIcon={<FontAwesomeIcon icon={faBell} width={14} />}
                      style={{ margin: 8 }}
                    >
                      {t("reminder.create")}
                    </Button>
                    <ReminderCreateDialog
                      open={reminderOpen}
                      setOpen={setReminderOpen}
                      company={companyQuery?.data || undefined}
                      project={projectByIdQuery?.data || undefined}
                    />
                  </Box>
                  <Divider />
                </>
              )}
            <Box
              minWidth={250}
              style={{
                minHeight: "calc(100vh - 300px)",
                maxHeight: "calc(100vh - 300px)",
                overflow: "auto",
              }}
            >
              {!reminderList?.data?.page?.content?.length && !reminderList?.isFetching && (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                  <Typography style={{ fontWeight: "bold", padding: 8 }}>
                    {t("reminder.emptyList")}
                  </Typography>
                </Box>
              )}
              {reminderList?.isFetching ? (
                <Box display="flex" justifyContent="center" alignItems="center" p={2} height="100%">
                  <CircularProgress />
                </Box>
              ) : (
                <Box>
                  {reminderList?.data?.page?.content?.map(reminder => (
                    <ReminderViewerListItem
                      key={reminder?.id}
                      reminder={reminder}
                      isSelected={reminder?.id === selectedReminder?.id}
                      setSelectedReminder={setSelectedReminder}
                    />
                  ))}
                  {reminderList.data && reminderList.data?.page?.totalPages > 1 && (
                    <Pagination
                      size="small"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                      }}
                      count={reminderList?.data?.page?.totalPages}
                      color="primary"
                      page={reminderList.data?.page?.number + 1}
                      onChange={(_, page) => {
                        setTableState(state => {
                          return { ...state, page: page - 1 };
                        });
                      }}
                      siblingCount={1}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Box>
          <Box>
            <Divider orientation="vertical" flexItem style={{ height: "100%" }} />
          </Box>
          {!selectedReminder && (
            <Box width={"100%"} display="flex" justifyContent="center" alignItems="center">
              <Box textAlign="center">
                <FontAwesomeIcon
                  icon={faBell}
                  style={{
                    fontSize: 60,
                    width: 60,
                    color: COLORS.lighterGrey,
                    marginBottom: 16,
                  }}
                />
                <Typography style={{ fontWeight: "bold", color: COLORS.lighterGrey }}>
                  {t("emailLog.select")}
                </Typography>
              </Box>
            </Box>
          )}
          {selectedReminder && (
            <Box width="100%" p={2}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                pb={1}
                flexWrap="wrap"
              >
                <Box display="flex" alignItems="center">
                  <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
                    {selectedReminder?.user?.name}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                  <Typography style={{ paddingRight: 8 }}>
                    {selectedReminder?.when &&
                      format(new Date(selectedReminder?.when), "Pp", {
                        locale: supportedLocales[i18n.language],
                      })}
                  </Typography>
                  {selectedReminder?.status === "ACTIVE" && (
                    <Tooltip title={t("reminder.statusDoneTitle").toString()}>
                      <span>
                        <ConfirmationButton
                          variant="text"
                          size="small"
                          color="primary"
                          buttonType="ICON"
                          title={t("reminder.statusDoneTitle").toString()}
                          body={t("reminder.statusDoneBody").toString()}
                          onSubmit={async () => statusModify(selectedReminder?.id, "DONE")}
                          style={{ color: COLORS.green }}
                        >
                          <Check />
                        </ConfirmationButton>
                      </span>
                    </Tooltip>
                  )}
                  {selectedReminder?.status === "ACTIVE" && (
                    <Tooltip title={t("reminder.statusRemoveTitle").toString()}>
                      <span>
                        <ConfirmationButton
                          variant="text"
                          size="small"
                          color="primary"
                          buttonType="ICON"
                          title={t("reminder.statusRemoveTitle").toString()}
                          body={t("reminder.statusRemoveBody").toString()}
                          onSubmit={async () => statusModify(selectedReminder?.id, "REMOVED")}
                          style={{ color: COLORS.red }}
                        >
                          <Close />
                        </ConfirmationButton>
                      </span>
                    </Tooltip>
                  )}
                  {selectedReminder?.status === "REMOVED" && (
                    <Tooltip title={t("reminder.REMOVED").toString()}>
                      <Close style={{ color: COLORS.lightGrey }} />
                    </Tooltip>
                  )}
                  {selectedReminder?.status === "DONE" && (
                    <Tooltip title={t("reminder.DONE").toString()}>
                      <Check style={{ color: COLORS.lightGrey }} />
                    </Tooltip>
                  )}
                  {hasAuthority(account?.user, account?.permissions, selectedRelTenant, [
                    "EMPLOYEE_ADMIN",
                  ]) && (
                    <Box>
                      <Tooltip title={t("common:button.modify").toString()}>
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => {
                            setSelectedModifyReminder(selectedReminder);
                          }}
                        >
                          <Edit color="primary" fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              </Box>
              <Divider />
              <Box display="flex" gridGap={16} pt={1.5}>
                {!!selectedReminder?.company && (
                  <Box display="flex" alignItems="center" gridGap={8}>
                    <Tooltip title={t("emailLog.company").toString()}>
                      <Avatar style={{ height: 32, width: 32 }}>
                        <BusinessCenter fontSize="small" />
                      </Avatar>
                    </Tooltip>
                    <a
                      href={`/companies/list-all/company-details/details?id=${selectedReminder?.company?.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        {selectedReminder?.company?.name}
                      </Typography>
                    </a>
                  </Box>
                )}
                {selectedReminder?.issue && (
                  <Box display="flex" alignItems="center" gridGap={8}>
                    <Tooltip title={t("issues.title").toString()}>
                      <Avatar style={{ height: 32, width: 32 }}>
                        <Note fontSize="small" />
                      </Avatar>
                    </Tooltip>
                    <Typography style={{ fontWeight: "bold" }}>
                      {selectedReminder?.issue?.name}
                    </Typography>
                  </Box>
                )}
              </Box>
              {selectedReminder?.reminderMessage && (
                <Box
                  style={{
                    border: "1px solid rgba(0,0,0,0.15)",
                    borderRadius: 10,
                    marginTop: 12,

                    minHeight: "calc(100vh - 380px)",
                    maxHeight: "calc(100vh - 380px)",
                    overflow: "auto",
                  }}
                >
                  <div
                    style={{
                      padding: 16,
                      width: "100%",
                      height: "100%",
                      maxWidth: 1000,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: selectedReminder?.reminderMessage || "",
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default ReminderViewerList;
