import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import FormCard from "components/FormCard";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { hasAnyAuthority } from "shared/authorization";
import { listCompanyByTenant } from "shared/network/company.api";
import { Company, Employee, RelUserTenants, User } from "shared/types";
import { UserFormValues } from "../UserCreate/UserCreate";
import { useDebouncedCallback } from "use-debounce";
import {
  PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY,
  PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH,
} from "config/constants";
import JoditEditor from "jodit-react";
import { defaultTextAreaConfig } from "views/Reminder/ReminderForm";

type Props = {
  user?: {
    user: Partial<User>;
    employee?: Employee;
  };
  isProfile?: boolean;
  type: "CREATE" | "MODIFY";
  isLawyer?: boolean;
};

const HOME_PAGE = [
  "/home",
  "/my-company",
  "/items",
  "/companies",
  "/project",
  "/order",
  "/planning",
  "/warehouse",
  "/issues",
  "/admin",
];

const UserForm = ({ user, isProfile, type, isLawyer }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { watch, control } = useFormContext<UserFormValues>();
  const company = watch("company");

  const [companySearch, setCompanySearch] = useState<string>("");

  const { selectedRelTenant, account } = useSelector((state: RootState) => state.authentication);
  const { tenant, isTenantAdmin } = selectedRelTenant;

  const handleCompanySearchChange = useDebouncedCallback((value: string) => {
    if (value.length >= PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH || value.length === 0) {
      setCompanySearch(value);
    }
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  const companyListQuery = useQuery(
    ["user-form-company-list", tenant.id, companySearch],
    async () => {
      const { data } = await listCompanyByTenant(
        0,
        10,
        tenant.id,
        companySearch ? `nameSearch:${companySearch}` : "",
      );

      return data;
    },
  );

  const getAuthorizedHomePages = (homePages: string[]) => {
    let pages: string[] = [];
    homePages.forEach(page => {
      if (page === "/home") {
        pages.push(page);
      }
      if (
        page === "/my-company" &&
        hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "USER_ADMIN",
          "EMPLOYEE_GET",
          "EMPLOYEE_ADMIN",
          "WAREHOUSE_ADMIN",
          "WAREHOUSE_GET",
        ])
      ) {
        pages.push(page);
      }
      if (
        page === "/items" &&
        hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "ITEM_GET",
          "ITEM_ADMIN",
          "CATEGORY_ADMIN",
        ])
      ) {
        pages.push(page);
      }
      if (
        page === "/companies" &&
        hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "COMPANY_GET",
          "COMPANY_ADMIN",
        ])
      ) {
        pages.push(page);
      }
      if (
        page === "/project" &&
        hasAnyAuthority(account.user, account.permissions, selectedRelTenant, ["PROJECT_ADMIN"])
      ) {
        pages.push(page);
      }
      if (
        page === "/order" &&
        hasAnyAuthority(account.user, account.permissions, selectedRelTenant, ["ORDER_GET"])
      ) {
        pages.push(page);
      }
      if (
        page === "/planning" &&
        hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "VEHICLE_ADMIN",
          "VEHICLE_GET",
        ])
      ) {
        pages.push(page);
      }
      if (
        page === "/warehouse" &&
        hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "WAREHOUSE_ADMIN",
          "WAREHOUSE_GET",
        ])
      ) {
        pages.push(page);
      }
      if (
        page === "/issues" &&
        hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "ISSUE_GET",
          "ISSUE_ADMIN",
          "ISSUE_TEMPLATE_GET",
        ])
      ) {
        pages.push(page);
      }
      if (page === "/admin" && (account?.user?.isSuperAdmin || selectedRelTenant?.isTenantAdmin)) {
        pages.push(page);
      }
    });

    return pages;
  };

  const textAreaConfig = useMemo(() => {
    return defaultTextAreaConfig;
  }, []);

  return (
    <Container maxWidth="sm">
      <FormCard title={t("common:generalData")}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="name"
              defaultValue={user?.user?.name || ""}
              rules={{ required: t("validation.required").toString() }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true, required: true }}
                  label={t("registration.formValues.name")}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="email"
              defaultValue={user?.user?.email}
              rules={{ required: t("validation.required").toString() }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="email"
                  InputLabelProps={{ shrink: true, required: true }}
                  label={t("registration.formValues.email")}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          {tenant.schMonitoring && !isLawyer && (
            <Grid item xs={12}>
              <Controller
                name="monitoringUserId"
                defaultValue={user?.user?.monitoringUserId}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{ shrink: true }}
                    label={t("tool.formValues.userId")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          )}
          {type === "MODIFY" && (
            <Grid item xs={12}>
              <Controller
                name="homePage"
                defaultValue={user?.user?.homePage || "/home"}
                rules={{ required: t("validation.required").toString() }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{ shrink: true, required: true }}
                    label={t("home.title")}
                    select
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  >
                    <MenuItem value={user?.user?.homePage || ""}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="body2">Jelenlegi</Typography>
                        <Typography style={{ color: COLORS.greyWater }}>
                          {user?.user?.homePage}
                        </Typography>
                      </Box>
                    </MenuItem>
                    {getAuthorizedHomePages(HOME_PAGE).map(homePage => (
                      <MenuItem key={homePage} value={homePage}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="body2">{t(`homePage.${homePage}`)}</Typography>
                          <Typography style={{ color: COLORS.lightGrey }}>{homePage}</Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
          )}
          {!isProfile && (
            <>
              {!isLawyer && (
                <>
                  <Grid item xs={12}>
                    <Controller
                      name="company"
                      defaultValue={user?.user?.relUserCompany?.[0]?.company || null}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          {...field}
                          disabled={!!user?.employee}
                          onChange={(_, value) => {
                            field.onChange(value);
                            handleCompanySearchChange("");
                          }}
                          onInputChange={(event, newInputValue) => {
                            handleCompanySearchChange(newInputValue);
                          }}
                          options={companyListQuery.data?.page?.content || []}
                          getOptionLabel={(option: Company) => option.name}
                          getOptionSelected={option => option.id === field.value?.id}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label={t("user.relUserCompany")}
                              InputLabelProps={{ shrink: true }}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {account.user.isSuperAdmin && (
                      <Grid item xs={12} style={{ paddingLeft: 16 }}>
                        <Controller
                          name="isSuperAdmin"
                          defaultValue={!!user?.user?.isSuperAdmin}
                          render={({ field }) => (
                            <FormControlLabel
                              label={t("registration.formValues.isAdmin")}
                              control={
                                <Checkbox
                                  {...field}
                                  checked={field.value}
                                  onChange={e => field.onChange(e.target.checked)}
                                />
                              }
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {(isTenantAdmin || account.user.isSuperAdmin || !company) && (
                      <Grid item xs={12} style={{ paddingLeft: 16 }}>
                        <Controller
                          name="isTenantAdmin"
                          defaultValue={
                            !!(user?.user?.relUserTenants as RelUserTenants[])?.find(
                              value => tenant?.id === value?.tenant?.id && value.isTenantAdmin,
                            )
                          }
                          render={({ field }) => (
                            <FormControlLabel
                              label={t("registration.formValues.isTenantAdmin")}
                              control={
                                <Checkbox
                                  {...field}
                                  checked={field.value}
                                  onChange={e => field.onChange(e.target.checked)}
                                />
                              }
                            />
                          )}
                        />
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </FormCard>
      {isProfile && (
        <Grid item xs={12} style={{ padding: 8 }}>
          <Typography style={{ fontWeight: "bold", paddingBottom: 4, paddingLeft: 8 }}>
            Email aláírás
          </Typography>
          <Controller
            control={control}
            name="emailSign"
            defaultValue={user?.user?.emailSign || ""}
            render={({ field, fieldState }) => (
              <JoditEditor
                config={textAreaConfig}
                ref={field.ref}
                value={field.value}
                onBlur={content => {
                  field.onChange(content);
                }}
              />
            )}
          />
        </Grid>
      )}
      <Box display="flex" justifyContent="center" m={2} gridGap={8}>
        {isProfile ? (
          <Button
            color="primary"
            variant="text"
            onClick={() => {
              history.push("./details");
            }}
          >
            {t("common:button.cancel")}
          </Button>
        ) : (
          <Button color="primary" variant="text" onClick={history.goBack}>
            {t("common:button.cancel")}
          </Button>
        )}
        <Button type="submit" color="primary">
          {t("common:button.save")}
        </Button>
      </Box>
    </Container>
  );
};

export default UserForm;
