import { Box, Button, Container } from "@material-ui/core";
import Loading from "components/Loading";
import queryClient from "config/query";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SliceStatus } from "shared/common";
import { itemCreate } from "shared/network/item.api";
import { removeEmptyStrings } from "shared/util/removeEmptyStrings";
import ItemForm, { ItemFormValues } from "../components/ItemForm";
import CategoryCreateDialog from "./CategoryCreateDialog";
import { Item } from "shared/types";

type Props = {
  isLoading?: boolean;
  isDefaultPurchasable?: boolean;
  itemCreateState?: number | null;
  setItemCreateState?: Dispatch<SetStateAction<number | null>>;
  setNewItem?: (item: Item, itemCreateState: number | null | undefined) => void;
};

const ItemCreate = ({
  itemCreateState,
  setItemCreateState,
  isLoading,
  isDefaultPurchasable,
  setNewItem,
}: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);
  const form = useForm<ItemFormValues>();
  const [status, setStatus] = useState<SliceStatus>("idle");
  const [createOpen, setCreateOpen] = useState(false);

  const onSubmitCreate = async (values: ItemFormValues) => {
    const temp = removeEmptyStrings(values);
    try {
      setStatus("pending");
      const { data } = await itemCreate(tenant.id, {
        ...temp,
        itemPriceHistoryEntries: values.itemPriceHistoryEntries,
        supplierPriceHistoryEntries: values.supplierPriceHistoryEntries,
        item: {
          ...temp.item,
          isSerialItem: !!temp.item.isSerialItem,
          futurePriceHandling: !!temp.item.futurePriceHandling,
          compositeItems: values.compositeItems,
          showInvoiceSubItems: values.item?.isComposite ? values.showInvoiceSubItems : false,
          useCompositePrice: values.item?.isComposite ? !!values.useCompositePrice : false,
        },
        compositeItems: values.compositeItems,
      });
      enqueueSnackbar(t("common:notification.create.success", { subject: t("item.subject") }), {
        variant: "success",
      });
      setStatus("success");
      if (setItemCreateState) {
        if (setNewItem && data?.item) {
          setNewItem?.(data?.item, itemCreateState);
        }
        setItemCreateState(null);
        queryClient.invalidateQueries("invoiceItemList");
      } else {
        history.goBack();
      }
    } catch (error: any) {
      if (error.data.status === "MISSING_PRICE_HISTORY") {
        enqueueSnackbar(t("item.status.MISSING_PRICE_HISTORY"), {
          variant: "error",
        });
      } else if (error.data.status === "ITEM_PRODUCT_CODE_ALREADY_EXIST") {
        enqueueSnackbar(t("item.status.ITEM_PRODUCT_CODE_ALREADY_EXIST"), {
          variant: "error",
        });
      } else {
        enqueueSnackbar(
          t("common:notification.create.failure", {
            subject: t("item.subject"),
          }),
          { variant: "error" },
        );
      }
      setStatus("failure");
    }
  };

  return (
    <Container maxWidth="xl">
      <CategoryCreateDialog createOpen={createOpen} setCreateOpen={setCreateOpen} />
      <FormProvider {...form}>
        <form id="item-create-form" onSubmit={form.handleSubmit(onSubmitCreate)}>
          <Loading open={status === "pending"} />
          <ItemForm
            isModal={!!setItemCreateState}
            isLoading={status !== "idle" && status !== "failure"}
            isDefaultPurchasable={isDefaultPurchasable}
            setCreateOpen={setCreateOpen}
          />
          <Box display="flex" justifyContent="center" m={2} gridGap={8}>
            <Button
              color="primary"
              variant="text"
              onClick={() => {
                if (setItemCreateState) {
                  setItemCreateState(null);
                } else {
                  history.goBack();
                }
              }}
            >
              {t("common:button.cancel")}
            </Button>
            <Button form="item-create-form" type="submit" color="primary" disabled={isLoading}>
              {t("common:button.save")}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Container>
  );
};
export default ItemCreate;
