import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddBox, Close, Info } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import FormCard from "components/FormCard";
import Loading from "components/Loading";
import { NUMBER_FORMAT } from "config/constants";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useEffect } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { CustomField, getCustomFields } from "shared/network/custom-fields.api";
import { IssueTemplate } from "shared/types";
import { RelIssueTemplateFormValues } from "../IssueTemplateForm";

type Props = {
  issueTemplate?: IssueTemplate;
};

const useStyles = makeStyles({
  empty: {
    fontSize: 20,
    width: "100%",
  },
});

const CustomFieldCard = ({ issueTemplate }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { control, watch, setValue } = useFormContext<RelIssueTemplateFormValues>();

  const { append, fields, remove } = useFieldArray({
    control,
    name: "customFields",
    keyName: "key",
  });
  const customFields = watch("customFields");

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const listCustomFieldsQuery = useQuery(["listCustomFieldsQuery"], async () => {
    const { data } = await getCustomFields(tenant.id);
    return data.items;
  });

  useEffect(() => {
    setValue("customFields", issueTemplate?.relIssueTemplateCustomFields || []);
  }, [setValue, issueTemplate?.relIssueTemplateCustomFields]);

  return (
    <FormCard title="Egyedi mezők">
      {listCustomFieldsQuery.isFetching ? (
        <Loading open={true} />
      ) : fields.length ? (
        fields.map((field, index) => {
          const fieldIsRequired = customFields?.[index]?.customField?.htmlAttributes
            ? JSON.parse(customFields?.[index]?.customField?.htmlAttributes)?.required
            : false;

          return (
            <Grid
              key={field.key}
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={6} style={{ padding: "12px" }}>
                <Typography style={{ fontWeight: "bold" }}>{index + 1}. mező</Typography>
              </Grid>
              <Grid item xs={6} container justifyContent="flex-end">
                <Tooltip title={t("common:button.delete").toString()}>
                  <IconButton size="small" onClick={() => remove(index)}>
                    <Close />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={!!customFields?.[index]?.customField ? 6 : 12}>
                <Controller
                  name={`customFields.${index}.customField`}
                  rules={{
                    required: t("validation.required").toString(),
                  }}
                  defaultValue={field.customField || null}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={listCustomFieldsQuery.data || []}
                      getOptionLabel={(option: CustomField) => option.name}
                      getOptionSelected={option => option.id === field.value?.id}
                      onChange={(_, value) => field.onChange(value)}
                      renderInput={params => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <Tooltip
                                style={{
                                  paddingRight: "2px",
                                }}
                                title={t("tooltip.field").toString()}
                              >
                                <Info style={{ color: COLORS.lightBlue }} />
                              </Tooltip>
                            ),
                          }}
                          label="Mező neve"
                          InputLabelProps={{
                            shrink: true,
                            required: true,
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              {customFields?.[index]?.customField?.type === "TEXTAREA" && (
                <Grid item xs={6}>
                  <Controller
                    name={`customFields.${index}.defaultValue`}
                    defaultValue={field.defaultValue || ""}
                    rules={{
                      required: fieldIsRequired && t("validation.required").toString(),
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        multiline
                        minRows={
                          JSON.parse(customFields?.[index]?.customField?.htmlAttributes)?.rows
                        }
                        InputLabelProps={{ shrink: true, required: fieldIsRequired }}
                        label={t("customFieldsCard.defaultValue")}
                      />
                    )}
                  />
                </Grid>
              )}
              {customFields?.[index]?.customField?.type === "TEXTFIELD" && (
                <Grid item xs={6}>
                  <Controller
                    name={`customFields.${index}.defaultValue`}
                    defaultValue={field.defaultValue || ""}
                    rules={{
                      required: fieldIsRequired && t("validation.required").toString(),
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        InputLabelProps={{ shrink: true, required: fieldIsRequired }}
                        label={t("customFieldsCard.defaultValue")}
                      />
                    )}
                  />
                </Grid>
              )}
              {customFields?.[index]?.customField?.type === "NUMBER" && (
                <Grid item xs={6}>
                  <Controller
                    name={`customFields.${index}.defaultValue`}
                    defaultValue={field.defaultValue || ""}
                    rules={{
                      required: fieldIsRequired && t("validation.required").toString(),
                      validate: value => {
                        if (value && !value.match(NUMBER_FORMAT)) {
                          return t("common:validation.numberFormat").toString();
                        }
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        InputLabelProps={{ shrink: true, required: fieldIsRequired }}
                        label={t("customFieldsCard.defaultValue")}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
              )}
              {customFields?.[index]?.customField?.type === "DATE" && (
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={`customFields.${index}.defaultValue`}
                    defaultValue={field.defaultValue || new Date()}
                    rules={{
                      required: fieldIsRequired && t("validation.required").toString(),
                    }}
                    render={({ field }) => (
                      <KeyboardDatePicker
                        {...field}
                        ref={undefined}
                        variant="inline"
                        label={t("customFieldsCard.defaultValue")}
                        format="yyyy.MM.dd."
                        InputLabelProps={{ shrink: true, required: fieldIsRequired }}
                        autoOk
                      />
                    )}
                  />
                </Grid>
              )}
              {customFields?.[index]?.customField?.type === "TIME" && (
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={`customFields.${index}.defaultValue`}
                    defaultValue={field.defaultValue || new Date()}
                    rules={{
                      required: fieldIsRequired && t("validation.required").toString(),
                    }}
                    render={({ field }) => (
                      <KeyboardTimePicker
                        {...field}
                        ref={undefined}
                        ampm={false}
                        label={t("customFieldsCard.defaultValue")}
                        placeholder="08:00 AM"
                        mask="__:__ _M"
                        InputLabelProps={{ shrink: true, required: fieldIsRequired }}
                      />
                    )}
                  />
                </Grid>
              )}
              {customFields?.[index]?.customField?.type === "SELECT" && (
                <Grid item xs={6}>
                  <Controller
                    name={`customFields.${index}.defaultValue`}
                    rules={{
                      required: fieldIsRequired && t("validation.required").toString(),
                    }}
                    defaultValue={field.defaultValue || ""}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        InputLabelProps={{ shrink: true, required: fieldIsRequired }}
                        SelectProps={{ displayEmpty: true }}
                        label={t("customFieldsCard.defaultValue")}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        select
                      >
                        <MenuItem value="">{t("common:choose")}</MenuItem>
                        {JSON.parse(customFields?.[index]?.customField?.fieldValues).map(
                          (value: { value: string }, index: number) => (
                            <MenuItem key={index} value={value.value}>
                              {value.value}
                            </MenuItem>
                          ),
                        )}
                      </TextField>
                    )}
                  />
                </Grid>
              )}
              {customFields?.[index]?.customField?.type === "CHECKBOX" && (
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={`customFields.${index}.defaultValue`}
                    defaultValue={field.defaultValue || []}
                    render={({ field }) => (
                      <FormGroup>
                        <InputLabel shrink>Alapértemezett érték</InputLabel>
                        {JSON.parse(customFields?.[index]?.customField?.fieldValues).map(
                          (value: { value: string }, index: number) => (
                            <FormControlLabel
                              key={index}
                              labelPlacement="end"
                              label={value.value}
                              control={
                                <Checkbox
                                  {...field}
                                  onChange={event => {
                                    if (event.target.checked) {
                                      field.onChange([...(field.value as string[]), value.value]);
                                    } else {
                                      (field.value as string[]).splice(
                                        (field.value as string[]).findIndex(
                                          fasz => fasz === value.value,
                                        ),
                                        1,
                                      );
                                    }
                                  }}
                                />
                              }
                            />
                          ),
                        )}
                      </FormGroup>
                    )}
                  />
                </Grid>
              )}
            </Grid>
          );
        })
      ) : (
        <Grid item container xs={12} justifyContent="center">
          <Box textAlign="center">
            <Typography className={classes.empty} color="secondary">
              Nincs egyedi mező hozzáadva.
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <Button
            variant="outlined"
            color="primary"
            style={{ marginBottom: 8, marginTop: 16 }}
            onClick={event => {
              event.stopPropagation();
              append({});
            }}
            startIcon={<AddBox />}
          >
            Mező hozzáadása
          </Button>
        </Box>
      </Grid>
    </FormCard>
  );
};

export default CustomFieldCard;
