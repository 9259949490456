import DetailsCard from "components/DetailsCard";
import { useTranslation } from "react-i18next";
import { JobForm } from "shared/network/job-questionnaire.api";

type Props = {
  jobForm: JobForm;
};

const PersonalDataCard = ({ jobForm }: Props) => {
  const { t } = useTranslation();

  return (
    <DetailsCard
      title={t("jobForm.contactData.title")}
      columns="flex"
      columnFlexRule={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
      dataList={[
        {
          name: t("jobForm.contactData.email"),
          value: jobForm?.email || "-",
        },

        {
          name: t("jobForm.contactData.phone"),
          value: jobForm?.phone || "-",
        },
        {
          name: t("jobForm.contactData.phoneHu"),
          value: jobForm?.phoneHu || "-",
        },

        {
          name: t("jobForm.contactData.phoneForeign"),
          value: jobForm?.phoneForeign || "-",
        },
        {
          name: t("jobForm.contactData.viber"),
          value: jobForm?.viber || "-",
        },
        {
          name: t("jobForm.contactData.facebook"),
          value: jobForm?.facebook || "-",
        },
        {
          name: t("jobForm.contactData.instagram"),
          value: jobForm?.instagram || "-",
        },
      ]}
    />
  );
};

export default PersonalDataCard;
