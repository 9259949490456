//ISSUE_TEMPLATE_ADMIN
import axios from "config/axios";
import { GenericListResponse, GenericPageResponse, GenericResponse } from "shared/common";

export type IssueStatus = {
  id: number | string;
  tenantId: number;
  name?: string;
  isDeleted?: boolean;
  color?: string;
  status?: string;
};

const ENDPOINT = "/issue-status";

export const createIssueStatus = (param: IssueStatus, tenantId: number) =>
  axios.post(`${ENDPOINT}`, { param, tenantId }); //ISSUE_ADMIN

export const modifyIssueStatus = (param: IssueStatus, tenantId: number) =>
  axios.put(`${ENDPOINT}`, { param, tenantId }); //ISSUE_ADMIN

export const deleteIssueStatus = (param: string | number, tenantId: number) =>
  axios.delete(`${ENDPOINT}`, { data: { param, tenantId } }); //ISSUE_ADMIN

export const listIssueStatusByTenant = (tenantId: number) =>
  axios.post<GenericListResponse<IssueStatus>>(`${ENDPOINT}/list-by-tenant`, {
    tenantId,
  }); //ISSUE_GET

export const getIssueStatus = (param: number, tenantId: number) =>
  axios.post<GenericResponse<IssueStatus>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //ISSUE_GET

export const listIssueStatusPageable = (
  page: number,
  size: number,
  tenantId: number,
  search = "",
) =>
  axios.get<GenericPageResponse<IssueStatus>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}`,
  ); //ISSUE_TYPE_GET

export const listIssueStatusByTenantAndCompany = (companyId: number, tenantId: number) =>
  axios.post<GenericListResponse<IssueStatus>>(`${ENDPOINT}/list-by-tenant-and-company`, {
    companyId,
    tenantId,
  });
