import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Close, Info } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import { Autocomplete } from "@material-ui/lab";
import FormCard from "components/FormCard";
import { RootState } from "config/store";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getUserById, listUsers } from "shared/network/user.api";
import { IssueTemplate, User } from "shared/types";
import { RelIssueTemplateFormValues } from "../IssueTemplateForm";
import { useDebouncedCallback } from "use-debounce";
import {
  PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH,
  PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY,
} from "config/constants";
import { COLORS } from "config/theme";

const useStyles = makeStyles({
  empty: {
    fontSize: 20,
    width: "100%",
  },
});

type Props = {
  issueTemplate?: IssueTemplate;
};

export async function getUser(tenantId: number, id?: string) {
  try {
    if (id) {
      const user = (await getUserById(tenantId, id)).data.user;
      return user;
    } else {
      throw new Error();
    }
  } catch {
    return null;
  }
}

const UserListCard = ({ issueTemplate }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { control, setValue } = useFormContext<RelIssueTemplateFormValues>();

  const { append, fields, remove } = useFieldArray({
    control,
    name: "userList",
    keyName: "key",
  });

  const { tenant } = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant,
  );

  const [userSearch, setUserSearch] = useState<string>("");

  const userListQuery = useQuery(
    ["issueUserList", tenant.id, userSearch],
    async () => {
      const { data } = await listUsers(
        0,
        10,
        tenant.id,
        userSearch ? `name:${userSearch}` : ``,
      );
      return data.page;
    },
  );

  const handleUserSearchChange = useDebouncedCallback((value: string) => {
    if (
      value.length >= PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH ||
      value.length === 0
    ) {
      setUserSearch(value);
    }
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  useEffect(() => {
    //@ts-ignore
    setValue(
      "userList",
      issueTemplate?.relIssueTemplateUsers?.map(relUser => relUser.user) || [],
    );
  }, [issueTemplate?.relIssueTemplateUsers, setValue]);

  return (
    <FormCard title={t("issues.formValues.userCard.title")}>
      {fields.length ? (
        fields.map((field, index) => (
          <Grid key={field.key} container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <Typography variant="body2" style={{ paddingLeft: 8 }}>
                {t("issues.formValues.userCard.user", {
                  index: index + 1,
                })}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <Tooltip title={t("common:button.delete").toString()}>
                <IconButton size="small" onClick={() => remove(index)}>
                  <Close fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={`userList.${index}`}
                defaultValue={
                  issueTemplate?.relIssueTemplateUsers?.[index]?.user
                }
                rules={{ required: t("validation.required").toString() }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    style={{ marginBottom: 8 }}
                    options={userListQuery?.data?.content || []}
                    onInputChange={(event, newInputValue) => {
                      handleUserSearchChange(newInputValue);
                    }}
                    getOptionLabel={(option: User) => {
                      if (option.name) {
                        return `${option.name}`;
                      }
                      return "";
                    }}
                    getOptionSelected={option => option.id === field.value?.id}
                    onChange={(_, value) => {field.onChange(value)
                      handleUserSearchChange("");}}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <Tooltip
                              style={{
                                paddingRight: "2px",
                              }}
                              title={t(
                                "tooltip.user"
                              ).toString()}
                            >
                              <Info style={{ color: COLORS.lightBlue }} />
                            </Tooltip>
                          ),
                        }}
                        label={t("issues.formValues.userCard.userName")}
                        InputLabelProps={{
                          shrink: true,
                          required: true,
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        ))
      ) : (
        <Grid item container xs={12} justifyContent="center">
          <Box textAlign="center">
            <Typography className={classes.empty} color="secondary">
              {t("common:emptyUserList")}
            </Typography>
          </Box>
        </Grid>
      )}
      <Box display="flex" justifyContent="center" width="100%">
        <Button
          variant="outlined"
          color="primary"
          style={{ marginBottom: 8, marginTop: 16 }}
          onClick={event => {
            event.stopPropagation();
            append({});
          }}
          startIcon={<AddIcon />}
        >
          {t("issues.formValues.userCard.add")}
        </Button>
      </Box>
    </FormCard>
  );
};

export default UserListCard;
