import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddBox, Description } from "@material-ui/icons";
import { GridCellParams, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import supportedLocales from "config/supportedLocales";
import { COLORS } from "config/theme";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { hasAuthority } from "shared/authorization";
import { getCustomEmailList, getCustomEmailRelList } from "shared/network/company.api";
import { User } from "shared/types";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";
import CreateEmail from "./CreateEmail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines, faPenToSquare } from "@fortawesome/free-solid-svg-icons";

export function getUserFullName(user?: User, language?: string): string | undefined {
  if (user && user.name === "-") {
    return user.email;
  } else if (user && user.name) {
    switch (language) {
      case "EN":
        return user.name;
      default:
        return user.name;
    }
  }
}

const CustomEmail = () => {
  const { t, i18n } = useTranslation();
  const { setHeaderButtons } = useHeader();
  const [sortState, setSortState] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-custom-email-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-custom-email-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const [selectedEmail, setSelectedEmail] = useState<any>(null);
  const [selectedEmailForNew, setSelectedEmailForNew] = useState<any>(null);

  const { page, pageSize, filterValue } = tableState;
  const { selectedRelTenant, account } = useSelector((state: RootState) => state.authentication);

  const customEmailListQuery = useQuery(
    ["customEmailListQuery", page, pageSize, sortState, filterValue, selectedRelTenant],
    async () => {
      if (selectedRelTenant.tenant.id) {
        const { data } = await getCustomEmailList(
          page,
          pageSize,
          selectedRelTenant.tenant.id,
          filterValue,
          sortState,
        );
        return data;
      }
      return Promise.reject();
    },
  );

  const customEmailUserQuery = useQuery(
    ["customEmailQuery", page, pageSize, sortState, filterValue, selectedRelTenant],
    async () => {
      if (selectedRelTenant.tenant.id) {
        const { data } = await getCustomEmailRelList(
          page,
          pageSize,
          selectedRelTenant.tenant.id,
          filterValue,
          sortState,
        );
        return data;
      }
      return Promise.reject();
    },
  );

  const selectedEmailUsers = customEmailUserQuery?.data?.page?.content
    ?.filter((relUser: any) => relUser.marketingEmailId === selectedEmail?.id)
    ?.map((relUser: any) => relUser.email);

  const columns: GridColDef[] = [
    {
      field: "subject",
      headerName: t("customEmail.subject"),
      flex: 1,
    },
    // {
    //   field: "relCustomNotificationLogUsers",
    //   headerName: t("customEmail.to"),
    //   flex: 2,
    //   sortable: false,
    //   renderCell: ({ row }: GridRenderCellParams) =>
    //     row.relCustomNotificationLogUsers?.map(
    //       (value: any, index: number, array: any[]) =>
    //         getUserFullName(value.user, toUpper(i18n.language)) +
    //         (index !== array.length - 1 ? ", " : ""),
    //     ),
    // },
    {
      field: "campaignName",
      headerName: t("customEmail.campaign"),
      flex: 1,
    },
    {
      field: "createdOn",
      headerName: t("customEmail.createdOn"),
      flex: 0.75,
      type: "singleDateStart",
      renderCell: ({ row }: GridRenderCellParams) => (
        <>
          {row?.createdOn
            ? format(new Date(row.createdOn?.toString()), "Pp", {
                locale: supportedLocales[i18n.language],
              })
            : ""}
        </>
      ),
    },
    {
      field: "actions",
      headerName: " ",
      sortable: false,
      width: 160,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" alignItems="center" width="100%">
          <Tooltip title={t("common:button.show") as string}>
            <IconButton color="primary" onClick={() => setSelectedEmail(row)}>
              <FontAwesomeIcon icon={faFileLines} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("customEmail.newFrom") as string}>
            <IconButton
              color="primary"
              onClick={() => {
                setSelectedEmailForNew(row);
                setOpen(true);
              }}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["EMAIL_ADMIN"]) && (
          <Button
            variant="contained"
            startIcon={<AddBox />}
            style={{ margin: "auto" }}
            onClick={() => setOpen(true)}
          >
            {t("customEmail.send")}
          </Button>
        )}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant]); //eslint-disable-line

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      setSelectedEmail(params.row);
    }
  }

  return (
    <Container>
      {open && (
        <CreateEmail
          refetch={customEmailListQuery.refetch}
          open={open}
          setOpen={setOpen}
          selectedEmailForNew={selectedEmailForNew}
          setSelectedEmailForNew={setSelectedEmailForNew}
        />
      )}
      <Dialog open={!!selectedEmail} onClose={() => setSelectedEmail(null)}>
        <DialogTitle>
          <Grid container style={{ justifyContent: "space-between" }}>
            <Grid item xs={6}>
              <Typography variant="h2">{selectedEmail?.subject}</Typography>
            </Grid>
            <Grid item xs={6} style={{ justifyContent: "flex-start", textAlign: "right" }}>
              <Typography variant="h3" style={{ color: COLORS.greyWater, fontWeight: "initial" }}>
                {selectedEmail?.createdOn
                  ? format(new Date(selectedEmail?.createdOn?.toString()), "Pp", {
                      locale: supportedLocales[i18n.language],
                    })
                  : ""}
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="h3" style={{ color: COLORS.greyWater, fontWeight: "initial" }}>
            {selectedEmail?.campaignName}
          </Typography>
          {!!selectedEmailUsers?.length && (
            <Typography
              variant="h3"
              style={{ color: COLORS.greyWater, fontWeight: "initial", marginTop: 20 }}
            >
              {`${t("customEmail.to")}: `}
              {selectedEmailUsers?.map((email: string, index: number) => {
                if (index < selectedEmailUsers?.length - 1) {
                  return `${email}, `;
                } else {
                  return email;
                }
              })}
            </Typography>
          )}
        </DialogTitle>

        <DialogContent style={{ minWidth: 400 }}>
          <div dangerouslySetInnerHTML={{ __html: selectedEmail?.content }} />
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setSelectedEmail(null)}>
            {t("common:button.back")}
          </Button>
        </DialogActions>
      </Dialog>
      <PageableTable
        filterable={["subject", "campaignName"]}
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="custom-email"
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={customEmailListQuery}
        onCellClick={onCellClick}
        minWidth={{ value: "sm" }}
      />
    </Container>
  );
};

export default CustomEmail;
