import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { createRelTag, TAG_TYPE } from "shared/network/rel-tag.api.ts";
import { getTagPage, Tag } from "shared/network/tag.api";
import ColorPicker from "views/Issues/IssueType/ColorPicker";
import RelTagIcons from "./RelTagIcons";

type FormValues = {
  tag: string;
  color: string;
};

type Props = {
  selectedRow: number | null;
  setSelectedRow: Dispatch<SetStateAction<number | null>>;
  refetch: () => void;
  subjectType: TAG_TYPE;
};

const RelCreateTag = ({ selectedRow, setSelectedRow, refetch, subjectType }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const tenantId = useSelector(
    (state: RootState) => state.authentication?.selectedRelTenant?.tenant?.id,
  );
  const form = useForm();
  const { handleSubmit, watch } = form;
  const [loading, setLoading] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState("faCircleExclamation");
  const selectedColor = watch("color");

  async function onSubmit(values: FormValues) {
    setLoading(true);
    try {
      await createRelTag(
        {
          ...values,
          subjectType,
          subjectId: selectedRow,
          icon: selectedIcon,
        },
        tenantId,
      );
      refetch();
      enqueueSnackbar(t("common:notification.create.success"), { variant: "success" });
      setSelectedRow(null);
    } catch {
      enqueueSnackbar(t("common:notification.create.failure"), { variant: "error" });
    }
    setLoading(false);
  }

  const tagQuery = useQuery(["tagQuery", tenantId], async () => {
    const { data } = await getTagPage(
      tenantId,
      0,
      1000,
      `(tagType:${subjectType};(OR)tagType(IS_NULL)1;)`,
    );

    return data;
  });

  return (
    <>
      <Loading open={loading} />
      <Dialog fullWidth maxWidth="xs" open={!!selectedRow} onClose={() => setSelectedRow(null)}>
        <DialogTitle>{t("tags.addRel")}</DialogTitle>
        <DialogContent>
          <FormProvider {...form}>
            <form id="tag-form" onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="tag"
                    defaultValue={null}
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <Autocomplete
                        {...field}
                        onChange={(_, value) => field.onChange(value)}
                        options={tagQuery.data?.page?.content || []}
                        getOptionLabel={(option: Tag) => option.tag}
                        getOptionSelected={option => option.id === field.value?.id}
                        renderInput={params => (
                          <TextField
                            {...params}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("tags.relTag")}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="color"
                    defaultValue={"#FF0000"}
                    render={({ field }) => (
                      <ColorPicker
                        {...field}
                        //@ts-ignore
                        ref={undefined}
                        label={t("tags.color")}
                        value={field?.value}
                        setValue={field.onChange}
                        colors={[
                          "#FF0000",
                          "#FFA500",
                          "#FFD700",
                          "#008000",
                          "#0000FF",
                          "#FFB3E6",
                          "#800080",
                          "#000000",
                          "#A52A2A",
                          "#40E0D0",
                        ]}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RelTagIcons
                    selectedIcon={selectedIcon}
                    setSelectedIcon={setSelectedIcon}
                    selectedColor={selectedColor}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="text" onClick={() => setSelectedRow(null)}>
            {t("common:button.cancel")}
          </Button>
          <Button disabled={loading} form="tag-form" variant="contained" type="submit">
            {t("common:button.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RelCreateTag;
