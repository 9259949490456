import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddBox, Close, Info } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import FormCard from "components/FormCard";
import InputSkeleton from "components/InputSkeleton";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useEffect } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { listItems } from "shared/network/item.api";
import { IssueTemplate, Item } from "shared/types";
import { RelIssueTemplateFormValues } from "../IssueTemplateForm";

type Props = {
  issueTemplate?: IssueTemplate;
};

const useStyles = makeStyles({
  empty: {
    fontSize: 20,
    width: "100%",
  },
});

const ItemListCard = ({ issueTemplate }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { control, setValue } = useFormContext<RelIssueTemplateFormValues>();

  const { append, fields, remove } = useFieldArray({
    control,
    name: "itemList",
    keyName: "key",
  });

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const itemListQuery = useQuery(["itemListForIssueTemplateQuery"], async () => {
    const { data } = await listItems(0, 1000000, tenant.id, "");
    return data.page.content;
  });

  useEffect(() => {
    //@ts-ignore
    setValue("itemList", issueTemplate?.relIssueTemplateItems?.map(relItem => relItem.item) || []);
  }, [issueTemplate?.relIssueTemplateItems, setValue]);

  return (
    <FormCard title={t("common:items")}>
      {fields.length ? (
        fields.map((field, index) => (
          <Grid key={field.key} container justifyContent="space-between" alignItems="center">
            <Grid item xs={4} style={{ padding: "12px" }}>
              <Typography
                style={{
                  fontWeight: "bold",
                }}
              >
                {t("offer.relOfferItem.itemTitle", {
                  index: index + 1,
                })}
              </Typography>
            </Grid>
            <Grid item xs={2} container justifyContent="flex-end">
              <Tooltip title={t("common:button.delete").toString()}>
                <IconButton size="small" color="primary" onClick={() => remove(index)}>
                  <Close />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              {itemListQuery.isFetching ? (
                <InputSkeleton />
              ) : (
                <Controller
                  name={`itemList.${index}`}
                  rules={{
                    required: t("validation.required").toString(),
                  }}
                  defaultValue={issueTemplate?.relIssueTemplateItems?.[index]?.item}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={itemListQuery.data || []}
                      getOptionLabel={(option: Item) => {
                        if (option.name && option.productCode) {
                          return `${option.name} (${option.productCode})`;
                        } else {
                          return "";
                        }
                      }}
                      onChange={(_, value) => field.onChange(value)}
                      getOptionSelected={(option, value) => option.id === value?.id}
                      renderInput={params => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <Tooltip
                                style={{
                                  paddingRight: "2px",
                                }}
                                title={t("tool.formValues.itemNameOrProductNumber").toString()}
                              >
                                <Info style={{ color: COLORS.lightBlue }} />
                              </Tooltip>
                            ),
                          }}
                          label={t("order.formValues.itemName")}
                          InputLabelProps={{
                            shrink: true,
                            required: true,
                          }}
                        />
                      )}
                    />
                  )}
                />
              )}
            </Grid>
          </Grid>
        ))
      ) : (
        <Grid item container xs={12} justifyContent="center">
          <Box textAlign="center">
            <Typography className={classes.empty} color="secondary">
              {t("common:emptyItemList")}
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <Button
            variant="outlined"
            color="primary"
            style={{ marginBottom: 8, marginTop: 16 }}
            onClick={event => {
              event.stopPropagation();
              append({});
            }}
            startIcon={<AddBox />}
          >
            {t("offer.relOfferItem.add")}
          </Button>
        </Box>
      </Grid>
    </FormCard>
  );
};

export default ItemListCard;
