import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createTag } from "shared/network/tag.api";
import { TAG_TYPES } from "./TagList";

type FormValues = {
  tag: string;
  tagType: string;
};

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  refetch: () => void;
  selectedTagType: any;
  setSelectedTagType: any;
};

const CreateTag = ({ open, setOpen, refetch, selectedTagType, setSelectedTagType }: Props) => {
  const { t }: any = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const tenantId = useSelector(
    (state: RootState) => state.authentication?.selectedRelTenant?.tenant?.id,
  );
  const form = useForm();
  const { handleSubmit, setValue } = form;
  const [loading, setLoading] = useState(false);

  async function onSubmit(values: FormValues) {
    setLoading(true);
    try {
      await createTag(
        { ...values, tagType: values.tagType === "NONE" ? null : values.tagType },
        tenantId,
      );
      refetch();
      enqueueSnackbar(t("common:notification.create.success"), { variant: "success" });
      setOpen(false);
    } catch {
      enqueueSnackbar(t("common:notification.create.failure"), { variant: "error" });
    }
    setLoading(false);
  }

  useEffect(() => {
    if (selectedTagType) {
      setValue("tagType", selectedTagType);
    }
  }, [selectedTagType, setValue]);

  return (
    <>
      <Loading open={loading} />
      <Dialog fullWidth maxWidth="xs" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{t("tags.add")}</DialogTitle>
        <DialogContent>
          <FormProvider {...form}>
            <form id="tag-form" onSubmit={handleSubmit(onSubmit)}>
              <Grid item xs={12}>
                <Controller
                  name="tagType"
                  defaultValue=""
                  rules={{
                    required: t("validation.required").toString(),
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("tags.type")}
                      style={{ marginBottom: 15 }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      InputLabelProps={{ shrink: true, required: true }}
                      select
                    >
                      <MenuItem value="" disabled>
                        <>{t("common:choose")}</>
                      </MenuItem>
                      <MenuItem value="NONE">{t("tags.noType")}</MenuItem>
                      {TAG_TYPES.map((type, key) => (
                        <MenuItem key={key} value={type}>
                          {t(`tags.${type}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="tag"
                  rules={{
                    required: t("validation.required").toString(),
                  }}
                  defaultValue={null}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("tags.tag")}
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                      }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="text" onClick={() => setOpen(false)}>
            {t("common:button.cancel")}
          </Button>
          <Button disabled={loading} form="tag-form" variant="contained" type="submit">
            {t("common:button.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateTag;
