import { lazy } from "react";
import PersonalDataDetails from "views/Employee/PersonalDataDetails";
import PersonalDataModify from "views/Employee/PersonalDataModify";
import HaccpCheckTabs from "views/Haccp/HaccpCheck/HaccpCheckTabs";
import HaccpDepartmentTabs from "views/Haccp/HaccpDepartment/HaccpDepartmentTabs";
import HaccpDocumentsCreate from "views/Haccp/HaccpDocuments/HaccpDocumentsCreate";
import HaccpDocumentsTabs from "views/Haccp/HaccpDocuments/HaccpDocumentsTabs";
import HaccpItemDetails from "views/Haccp/HaccpItem/HaccpItemDetails";
import HaccpItemSet from "views/Haccp/HaccpItem/HaccpItemSet";
import HaccpItemTabs from "views/Haccp/HaccpItem/HaccpItemTabs";
import HaccpServingTabs from "views/Haccp/HaccpServing/HaccpServingTabs";
import SterilizationTabs from "views/Haccp/Sterilization/SterilizationTabs";
import JobFormDetailsTabs from "views/JobForms/JobFormDetails/JobFormDetailsTabs";
import JobForms from "views/JobForms/JobForms";
import JobFormCreate from "views/JobForms/JobFormsCreate/JobFormCreate";
import DailyReportCreate from "views/User/DailyReportCreate/DailyReportCreate";
import UserCreate from "views/User/UserCreate/UserCreate";
import UserCreateLawyer from "views/User/UserCreate/UserCreateLawyer";

const VehicleDetailsTabs = lazy(() => import("views/Vehicle/VehicleDetailsTabs"));
const VehicleCreate = lazy(() => import("views/Vehicle/VehicleCreate"));
const VehicleTabs = lazy(() => import("views/Vehicle/VehicleTabs"));
const VehicleModify = lazy(() => import("views/Vehicle/VehicleModify"));
const CargoCreate = lazy(() => import("views/Vehicle/Cargo/CargoCreate"));
const CargoRemove = lazy(() => import("views/Vehicle/Cargo/CargoRemove"));
const MyCompanyTabs = lazy(() => import("views/User/MyCompanyTabs"));
const UserDetails = lazy(() => import("views/User/UserDetails"));
const UserModify = lazy(() => import("views/User/UserModify"));
const EmployeeRegistration = lazy(() => import("views/Employee/EmployeeCreate"));
const EmployeeModify = lazy(() => import("views/Employee/EmployeeModify"));
const EmployeeDetailsTabs = lazy(() => import("views/Employee/EmployeeDetailsTabs"));
const CreatePersonalData = lazy(() => import("views/Employee/CreatePersonalData"));
const QualificationCreate = lazy(() => import("views/Qualifications/QualificationCreate"));
const EmployeeToEmployee = lazy(() => import("views/Employee/EmployeeToEmployee"));
const SalaryCreate = lazy(() => import("views/Salary/SalaryCreate"));
const SalaryModify = lazy(() => import("views/Salary/SalaryModify"));
const ToolCreate = lazy(() => import("views/Tools/ToolCreate"));
const ToolDetails = lazy(() => import("views/Tools/ToolDetails"));
const ToolModify = lazy(() => import("views/Tools/ToolModify"));
const AbsenceCreate = lazy(() => import("views/User/Absence/AbsenceCreate"));
const DepartmentCreate = lazy(() => import("views/Department/DepartmentCreate"));
const DepartmentModify = lazy(() => import("views/Department/DepartmentModify"));
const PdfTemplateForm = lazy(() => import("components/PdfTemplate/PdfTemplateForm"));

export const MY_COMPANY_PRIVATE_ROUTES = [
  {
    path: "/my-company",
    component: MyCompanyTabs,
    permissions: [
      "USER_ADMIN",
      "EMPLOYEE_GET",
      "EMPLOYEE_ADMIN",
      "WAREHOUSE_ADMIN",
      "WAREHOUSE_GET",
    ],
    routes: [
      {
        path: "/users",
        component: MyCompanyTabs,
        permissions: ["USER_ADMIN", "USER_GET"],
        routes: [
          {
            path: "/user-create",
            component: UserCreate,
            permissions: ["USER_ADMIN"],
          },
          {
            path: "/lawyer-create",
            component: UserCreateLawyer,
            permissions: ["USER_ADMIN"],
          },
          {
            path: "/user-modify",
            component: UserModify,
            permissions: ["USER_ADMIN"],
          },
          {
            path: "/user-details",
            component: UserDetails,
            permissions: ["USER_ADMIN"],
          },
        ],
      },
      {
        path: "/employees",
        component: MyCompanyTabs,
        permissions: ["EMPLOYEE_ADMIN", "EMPLOYEE_GET"],
        routes: [
          {
            path: "/employee-create",
            component: EmployeeRegistration,
            permissions: ["EMPLOYEE_ADMIN"],
          },
          {
            path: "/employee-modify",
            component: EmployeeModify,
            permissions: ["EMPLOYEE_ADMIN"],
          },
          {
            path: "/employee-details",
            component: EmployeeDetailsTabs,
            permissions: ["EMPLOYEE_GET"],
            routes: [
              {
                path: "/details",
                component: EmployeeDetailsTabs,
                permissions: ["EMPLOYEE_GET"],
              },
              {
                path: "/documents",
                component: EmployeeDetailsTabs,
                permissions: ["EMPLOYEE_ADMIN", "EMPLOYEE_GET"],
                routes: [
                  {
                    path: "/personal-data-create",
                    component: CreatePersonalData,
                    permissions: ["EMPLOYEE_ADMIN"],
                  },
                  {
                    path: "/personal-data-modify",
                    component: PersonalDataModify,
                    permissions: ["EMPLOYEE_ADMIN"],
                  },
                  {
                    path: "/personal-data-details",
                    component: PersonalDataDetails,
                    permissions: ["EMPLOYEE_ADMIN"],
                  },
                ],
              },
              {
                path: "/qualifications",
                component: EmployeeDetailsTabs,
                permissions: ["EMPLOYEE_ADMIN", "EMPLOYEE_GET"],
                routes: [
                  {
                    path: "/qualification-create",
                    component: QualificationCreate,
                    permissions: ["EMPLOYEE_ADMIN"],
                  },
                ],
              },
              {
                path: "/superiors",
                component: EmployeeDetailsTabs,
                permissions: ["EMPLOYEE_ADMIN", "EMPLOYEE_GET"],
                routes: [
                  {
                    path: "/superior-to-subordinate",
                    component: EmployeeToEmployee,
                    permissions: ["EMPLOYEE_ADMIN"],
                  },
                ],
              },
              {
                path: "/subordinates",
                component: EmployeeDetailsTabs,
                permissions: ["EMPLOYEE_ADMIN", "EMPLOYEE_GET"],
                routes: [
                  {
                    path: "/subordinate-to-superior",
                    component: EmployeeToEmployee,
                    permissions: ["EMPLOYEE_ADMIN"],
                  },
                ],
              },
              {
                path: "/salaries",
                component: EmployeeDetailsTabs,
                permissions: ["EMPLOYEE_ADMIN", "EMPLOYEE_GET"],
                routes: [
                  {
                    path: "/salary-create",
                    component: SalaryCreate,
                    permissions: ["EMPLOYEE_ADMIN"],
                  },
                  {
                    path: "/salary-modify",
                    component: SalaryModify,
                    permissions: ["EMPLOYEE_ADMIN"],
                  },
                ],
              },
              {
                path: "/department",
                component: EmployeeDetailsTabs,
                permissions: ["DEPARTMENT_ADMIN", "DEPARTMENT_GET"],
              },
              {
                path: "/absence",
                component: EmployeeDetailsTabs,
                permissions: ["EMPLOYEE_GET"],
                routes: [
                  {
                    path: "/absence-create",
                    component: AbsenceCreate,
                    permissions: ["ABSENCE_ADMIN"],
                  },
                ],
              },
              {
                path: "/tool",
                component: EmployeeDetailsTabs,
                permissions: ["EMPLOYEE_ADMIN"],
              },
            ],
          },
        ],
      },
      {
        path: "/departments",
        component: MyCompanyTabs,
        permissions: ["DEPARTMENT_ADMIN", "DEPARTMENT_GET"],
        routes: [
          {
            path: "/department-create",
            component: DepartmentCreate,
            permissions: ["DEPARTMENT_ADMIN"],
          },
          {
            path: "/department-modify",
            component: DepartmentModify,
            permissions: ["DEPARTMENT_ADMIN"],
          },
        ],
      },
      {
        path: "/absences",
        component: MyCompanyTabs,
        permissions: ["ABSENCE_GET"],
        routes: [
          {
            path: "/absence-create",
            component: AbsenceCreate,
            permissions: ["ABSENCE_ADMIN"],
          },
        ],
      },
      {
        path: "/tools",
        component: MyCompanyTabs,
        permissions: ["TOOL_GET"],
        routes: [
          {
            path: "/tool-create",
            component: ToolCreate,
            permissions: ["TOOL_ADMIN"],
          },
          {
            path: "/tool-modify",
            component: ToolModify,
            permissions: ["TOOL_ADMIN"],
          },
          {
            path: "/tool-details",
            component: ToolDetails,
            permissions: ["TOOL_GET"],
          },
        ],
      },
      {
        path: "/pdf-template",
        component: MyCompanyTabs,
        permissions: ["PDF_TEMPLATE_ADMIN"],
        routes: [
          {
            path: "/create",
            component: PdfTemplateForm,
            permissions: ["PDF_TEMPLATE_ADMIN"],
          },
          {
            path: "/modify",
            component: PdfTemplateForm,
            permissions: ["PDF_TEMPLATE_ADMIN"],
          },
        ],
      },
      {
        path: "/daily-report",
        component: MyCompanyTabs,
        routes: [
          {
            path: "/details",
            component: DailyReportCreate,
          },
          {
            path: "/create",
            component: DailyReportCreate,
          },
          {
            path: "/modify",
            component: DailyReportCreate,
          },
        ],
      },
    ],
  },
  {
    path: "/vehicles",
    component: VehicleTabs,
    permissions: ["VEHICLE_GET"],
    routes: [
      {
        path: "/list",
        component: VehicleTabs,
        permissions: ["VEHICLE_GET"],
        routes: [
          {
            path: "/vehicle-create",
            component: VehicleCreate,
            permissions: ["VEHICLE_ADMIN"],
          },
          {
            path: "/vehicle-details",
            component: VehicleDetailsTabs,
            permissions: ["VEHICLE_ADMIN", "VEHICLE_GET"],
            routes: [
              {
                path: "/items",
                component: VehicleDetailsTabs,
                permissions: ["VEHICLE_GET", "VEHICLE_ADMIN"],
                routes: [
                  {
                    path: "/cargo-create",
                    component: CargoCreate,
                    permissions: ["CARGO_ADMIN"],
                  },
                  {
                    path: "/cargo-remove",
                    component: CargoRemove,
                    permissions: ["CARGO_ADMIN"],
                  },
                ],
              },
              {
                path: "/details",
                component: VehicleDetailsTabs,
                permissions: ["VEHICLE_GET", "VEHICLE_ADMIN"],
              },
              {
                path: "/delivery",
                component: VehicleDetailsTabs,
                permissions: ["VEHICLE_GET", "VEHICLE_ADMIN"],
              },
              {
                path: "/vehicle-modify",
                component: VehicleModify,
                permissions: ["VEHICLE_ADMIN"],
              },
            ],
          },
          {
            path: "/vehicle-modify",
            component: VehicleModify,
            permissions: ["VEHICLE_ADMIN"],
          },
        ],
      },
      {
        path: "/cash-by-vehicle",
        component: VehicleTabs,
        permissions: ["VEHICLE_GET", "MILESTONE_ADMIN"],
        routes: [
          {
            path: "/vehicle-details",
            component: VehicleDetailsTabs,
            permissions: ["VEHICLE_GET"],
            routes: [
              {
                path: "/items",
                component: VehicleDetailsTabs,
                permissions: ["VEHICLE_GET", "VEHICLE_ADMIN"],
                routes: [
                  {
                    path: "/cargo-create",
                    component: CargoCreate,
                    permissions: ["CARGO_ADMIN"],
                  },
                ],
              },
              {
                path: "/details",
                component: VehicleDetailsTabs,
                permissions: ["VEHICLE_GET", "VEHICLE_ADMIN"],
              },
              {
                path: "/delivery",
                component: VehicleDetailsTabs,
                permissions: ["VEHICLE_GET", "VEHICLE_ADMIN"],
              },
              {
                path: "/vehicle-modify",
                component: VehicleModify,
                permissions: ["VEHICLE_ADMIN"],
              },
            ],
          },
        ],
      },
      {
        path: "/daily-delivery",
        component: VehicleTabs,
        permissions: ["WAREHOUSE_ADMIN", "DELIVERY_GET", "WAREHOUSE_GET"],
      },
      {
        path: "/departures",
        component: VehicleTabs,
        permissions: [],
      },
    ],
  },
];

export const HACCP_ITEM_ROUTES = [
  {
    path: "/haccp-item",
    component: HaccpItemTabs,
    permissions: [],
    routes: [
      {
        path: "/settings",
        component: HaccpItemTabs,
        permissions: [],
      },
      {
        path: "/qr",
        component: HaccpItemTabs,
        permissions: [],
      },
      {
        path: "/set",
        component: HaccpItemSet,
        permissions: [],
      },
      {
        path: "/check",
        component: HaccpItemTabs,
        permissions: [],
      },
      {
        path: "/details",
        component: HaccpItemDetails,
        permissions: [],
      },
    ],
  },
];

export const HACCP_CHECK_ROUTES = [
  {
    path: "/haccp-check",
    component: HaccpCheckTabs,
    permissions: [],
    routes: [
      {
        path: "/settings",
        component: HaccpCheckTabs,
        permissions: [],
      },
      {
        path: "/check",
        component: HaccpCheckTabs,
        permissions: [],
      },
    ],
  },
];

export const HACCP_DEPARTMENT_ROUTES = [
  {
    path: "/haccp-department",
    component: HaccpDepartmentTabs,
    permissions: [],
    routes: [
      {
        path: "/settings",
        component: HaccpDepartmentTabs,
        permissions: [],
      },
      {
        path: "/check",
        component: HaccpDepartmentTabs,
        permissions: [],
      },
    ],
  },
];

export const STERILIZATION_ROUTES = [
  {
    path: "/sterilization",
    component: SterilizationTabs,
    permissions: [],
    routes: [
      {
        path: "/list",
        component: SterilizationTabs,
        permissions: [],
      },
      {
        path: "/items",
        component: SterilizationTabs,
        permissions: [],
      },
    ],
  },
  {
    path: "/haccp-documents",
    component: HaccpDocumentsTabs,
    permissions: [],
    routes: [
      {
        path: "/documents",
        component: HaccpDocumentsTabs,
        permissions: [],
      },
      {
        path: "/settings",
        component: HaccpDocumentsTabs,
        permissions: [],
      },
      {
        path: "/create",
        component: HaccpDocumentsCreate,
        permissions: [],
      },
    ],
  },
  {
    path: "/haccp-serving",
    component: HaccpServingTabs,
    permissions: [],
    routes: [
      {
        path: "/arriving",
        component: HaccpServingTabs,
        permissions: [],
      },
      {
        path: "/locally",
        component: HaccpServingTabs,
        permissions: [],
      },
      {
        path: "/settings",
        component: HaccpServingTabs,
        permissions: [],
      },
    ],
  },
];

export const JOB_FORMS_ROUTES = [
  {
    path: "/job-forms",
    component: JobForms,
    permissions: [],
    routes: [
      {
        path: "/details",
        component: JobFormDetailsTabs,
        permissions: [],
      },
      {
        path: "/partners",
        component: JobFormDetailsTabs,
        permissions: [],
      },
      {
        path: "/create",
        component: JobFormCreate,
        permissions: [],
      },
    ],
  },
];
