import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { AddBox, Delete, Edit } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import ConfirmationButton from "components/ConfirmationButton";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { deleteTag, getTagPage } from "shared/network/tag.api";
import CreateTag from "./CreateTag";
import ModifyTag from "./ModifyTag";

export const TAG_TYPES = ["USER", "COMPANY", "JOB_QUESTIONNAIRE", "PROJECT", "ISSUE", "OFFER"];

const TagList = () => {
  const { t }: any = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenantId = useSelector(
    (state: RootState) => state.authentication?.selectedRelTenant?.tenant?.id,
  );
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [selectedTagType, setSelectedTagType] = useState("NONE");

  const tagQuery = useQuery(
    ["tagQuery", selectedRelTenant.tenant.id, page, selectedTagType],
    async () => {
      let search = "";
      if (selectedTagType === "NONE") {
        search = "tagType(IS_NULL)1;";
      } else {
        search = `tagType:${selectedTagType}`;
      }

      const { data } = await getTagPage(selectedRelTenant.tenant.id, page, 100, search);

      return data;
    },
  );

  return (
    <Card style={{ padding: 16 }}>
      <Loading open={loading} />
      <Box display="flex" justifyContent="center" gridGap={8} paddingBottom={1}>
        <Box>
          <TextField
            label="Típus"
            select
            style={{ width: 200 }}
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
            value={selectedTagType}
            onChange={event => setSelectedTagType(event.target.value)}
          >
            <MenuItem value="NONE">{t(`tags.NONE`)}</MenuItem>
            {TAG_TYPES.map((type, key) => (
              <MenuItem key={key} value={type}>
                {t(`tags.${type}`)}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Button
          variant="outlined"
          startIcon={<AddBox />}
          onClick={() => setCreateOpen(true)}
          style={{ padding: "0 16px" }}
        >
          Új címke
        </Button>
      </Box>
      <CreateTag
        open={createOpen}
        setOpen={setCreateOpen}
        refetch={tagQuery.refetch}
        selectedTagType={selectedTagType}
        setSelectedTagType={setSelectedTagType}
      />
      <ModifyTag selectedId={selectedId} setSelectedId={setSelectedId} refetch={tagQuery.refetch} />
      <Box display="flex" gridGap={8} flexWrap="wrap" width="100%">
        {tagQuery?.isFetching ? (
          <Box display="flex" justifyContent="center" width="100%" m={1}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {tagQuery?.data?.page?.numberOfElements === 0 ? (
              <Box display="flex" justifyContent="center" width="100%" m={1}>
                <Typography style={{ textAlign: "center" }}>Nincs ilyen típusú címke</Typography>
              </Box>
            ) : (
              tagQuery?.data?.page?.content?.map(tag => (
                <Card
                  key={tag?.id}
                  style={{ padding: "4px 12px 4px 16px", borderRadius: 50, cursor: "pointer" }}
                  onClick={event => {
                    event?.stopPropagation();
                    setSelectedId(tag?.id || null);
                  }}
                >
                  <Box display="flex" alignItems="center" gridGap={8}>
                    <Typography>{tag.tag}</Typography>
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={event => {
                        event?.stopPropagation();
                        setSelectedId(tag?.id || null);
                      }}
                    >
                      <Edit fontSize="small" />
                    </IconButton>
                    <ConfirmationButton
                      buttonType="ICON"
                      color="primary"
                      size="small"
                      onSubmit={async () => {
                        setLoading(true);
                        try {
                          if (tag?.id) {
                            await deleteTag(tag.id, tenantId);
                          }
                          tagQuery?.refetch();
                          enqueueSnackbar(t("common:notification.delete.success"), {
                            variant: "success",
                          });
                        } catch {
                          enqueueSnackbar(t("common:notification.delete.failure"), {
                            variant: "error",
                          });
                        }
                        setLoading(false);
                      }}
                      title={t("tags.deleteTitle")}
                      body={t("tags.deleteBody")}
                    >
                      <Delete fontSize="small" />
                    </ConfirmationButton>
                  </Box>
                </Card>
              ))
            )}
          </>
        )}
      </Box>
      {tagQuery.data && tagQuery?.data?.page.totalPages > 1 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: 8,
          }}
          count={tagQuery?.data?.page.totalPages}
          color="primary"
          page={tagQuery.data.page.number + 1}
          onChange={(_, page) => setPage(page - 1)}
        />
      )}
    </Card>
  );
};

export default TagList;
