import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import PageableTable from "components/PageableTable/PageableTable";
import { ITEM_TYPES, SUB_ITEM_TYPE } from "config/constants";
import { RootState } from "config/store";
import { format } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { listAllTransactionsPageable } from "shared/network/transactions.api";
import { TableState } from "views/DeliveryRound/DeliveryRoundList";
import { useGetSessionStorageKey } from "views/Comment/function";

const WarehouseTransactionList = () => {
  const { t } = useTranslation();
  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey(`rap-warehouse-transaction-list-page-number`),
      ) || JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey("rap-warehouse-transaction-list-page-size"),
      ) || JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const transactionListQuery = useQuery(
    ["transactionListQuery", page, pageSize, filterValue, sortState, selectedRelTenant.tenant.id],
    async () => {
      const { data } = await listAllTransactionsPageable(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        `(fromWarehouse(IS_NOT_NULL)$;(OR)toWarehouse(IS_NOT_NULL)$;)` +
          (filterValue ? `(AND)(${filterValue};` : ""),
        sortState,
      );
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "item.name",
      headerName: t("warehouse.transaction.item"),
      flex: 1,
      hide: true,
      valueGetter: ({ row }: GridValueGetterParams) => row.item.name,
    },
    {
      field: "item.productCode",
      headerName: t("item.productCode"),
      flex: 1,
      hide: true,
      valueGetter: ({ row }: GridValueGetterParams) => row.item.productCode,
      type: "stringContainsNumber",
    },
    {
      field: "fromWarehouse.name",
      headerName: t("warehouse.transaction.formValues.fromWarehouse"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.fromWarehouse?.name || "",
    },
    {
      field: "toWarehouse.name",
      headerName: t("warehouse.transaction.formValues.toWarehouse"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.toWarehouse?.name || "",
    },
    {
      field: "date",
      headerName: t("warehouse.transaction.date"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => format(new Date(row.date), "yyyy.MM.dd."),
      type: "date",
    },
    {
      field: "item",
      headerName: t("warehouse.transaction.item"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.item.name + ` (${row.item.productCode})`,
    },
    {
      field: "itemSerial.factoryNumber",
      headerName: t("item.serialNumber"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.itemSerial?.factoryNumber || "-",
      type: "stringContainsNumber",
    },
    {
      field: "item.type",
      headerName: t("item.type"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => t(`item.types.${row.item.type}`),
      type: "select",
    },
    {
      field: "quantity",
      headerName: t("warehouse.formValues.quantity"),
      flex: 1,
    },
    {
      field: "deliveryInvoiceNumber",
      headerName: t("warehouse.transaction.formValues.deliveryInvoiceNumber"),
      flex: 1,
      type: "stringContainsNumber",
    },
    {
      field: "toVehicle.licensePlateNumber",
      headerName: t("warehouse.transaction.formValues.deliveryVehicle"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.toVehicle?.licensePlateNumber,
    },
  ];

  return (
    <PageableTable
      filterOptions={[
        {
          columnName: "item.type",
          options: ITEM_TYPES.map(value => {
            return {
              translated: t(`item.types.${value}`),
              value,
              subItem: SUB_ITEM_TYPE.includes(value),
            };
          }),
        },
      ]}
      filterable={[
        "item.name",
        "item.productCode",
        "unitPrice",
        "quantity",
        "deliveryInvoiceNumber",
        "toVehicle.licensePlateNumber",
        "fromWarehouse.name",
        "toWarehouse.name",
        "item.type",
        "itemSerial.factoryNumber",
      ]}
      sortState={sortState}
      setSortState={setSortState}
      sessionStorageKey="warehouse-transaction-list"
      tableState={tableState}
      setTableState={setTableState}
      columns={columns}
      query={transactionListQuery}
    />
  );
};

export default WarehouseTransactionList;
