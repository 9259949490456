import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import FormCard from "components/FormCard";
import JoditEditor from "jodit-react";
import { useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  CUSTOM_NOTIFICATION_EVENT_TYPES,
  CustomNotification,
} from "shared/network/custom-notification.api";
import { defaultTextAreaConfig } from "views/Reminder/ReminderForm";

type Props = {
  customNotification?: CustomNotification | null;
};

export type CustomNotificationFormValues = {
  customNotification: CustomNotification | null;
};

const CustomNotificationForm = ({ customNotification }: Props) => {
  const { t }: any = useTranslation();
  const history = useHistory();
  const form = useFormContext();
  const { control } = form;
  const eventType = form.watch("eventType");

  const textAreaConfig = useMemo(() => {
    return defaultTextAreaConfig;
  }, []);

  useEffect(() => {}, []);

  return (
    <>
      <Container maxWidth="md">
        <FormCard title={t("drawer./admin/email-templates/email-template-create")}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="title"
                defaultValue={customNotification?.title || ""}
                rules={{ required: t("validation.required").toString() }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{ shrink: true, required: true }}
                    label={t("customNotification.title")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="eventType"
                defaultValue={customNotification?.eventType || ""}
                rules={{
                  required: t("validation.required").toString(),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label={t("pdfTemplate.formValues.type")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    InputLabelProps={{ shrink: true, required: true }}
                    select
                  >
                    <MenuItem value="" disabled>
                      {t("common:choose")}
                    </MenuItem>
                    {CUSTOM_NOTIFICATION_EVENT_TYPES.map((type, key) => (
                      <MenuItem key={key} value={type}>
                        {t(`customNotification.eventTypes.${type}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold", paddingBottom: 4, fontSize: 18 }}>
                {t(`customNotification.content`)}:
              </Typography>
              <Typography style={{ fontWeight: "bold" }}>
                {t(`customNotification.help1`)}
              </Typography>
              <Typography style={{ paddingBottom: 8 }}>{t(`customNotification.help2`)}</Typography>
              <Box display="flex" gridGap={8} flexWrap="wrap">
                <Chip
                  label={t(`customNotification.companyName`)}
                  onClick={() =>
                    form.setValue("content", form.getValues("content") + "<p>##CompanyName##</p>")
                  }
                />
                <Chip
                  label={t(`customNotification.email`)}
                  onClick={() =>
                    form.setValue(
                      "content",
                      form.getValues("content") + "<p>##NotificationEmail##</p>",
                    )
                  }
                />
                <Chip
                  label={t(`customNotification.bankAccount`)}
                  onClick={() =>
                    form.setValue("content", form.getValues("content") + "<p>##BankAccount##</p>")
                  }
                />
                {eventType !== "OFFER" && (
                  <Chip
                    label={t(`customNotification.orderId`)}
                    onClick={() =>
                      form.setValue("content", form.getValues("content") + "<p>##OrderNumber##</p>")
                    }
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="content"
                defaultValue={customNotification?.content || ""}
                render={({ field }) => (
                  <JoditEditor
                    config={textAreaConfig}
                    ref={field.ref}
                    value={field.value}
                    onBlur={content => {
                      field.onChange(content);
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormCard>
        <Box display="flex" justifyContent="center" m={2.5} gridGap={8}>
          <Button color="primary" variant="text" onClick={history.goBack}>
            {t("common:button.cancel")}
          </Button>
          <Button type="submit" color="primary">
            {t("common:button.save")}
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default CustomNotificationForm;
