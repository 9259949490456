import axios from "config/axios";
import { GenericListResponse } from "shared/common";
import { Comment } from "shared/types";

const ENDPOINT = "/comment";

export const createComment = (
  tenantId: number,
  type: string,
  subjectType: string,
  subjectId: string,
  note: string,
  file?: File | null,
  isReportVisibleByReporter?: boolean,
  isReportVisibleByCompany?: boolean,
) => {
  const formData = new FormData();
  formData.set("type", type);
  formData.set("tenantId", tenantId.toString());
  formData.set("subjectId", subjectId);
  formData.set("subjectType", subjectType);
  formData.set("note", note);
  formData.set("isReportVisibleByReporter", !!isReportVisibleByReporter + "");
  formData.set("isReportVisibleByCompany", !!isReportVisibleByCompany + "");
  file && formData.set("file", file);
  return axios.post(`${ENDPOINT}/with-file`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}; //COMMENT_ADMIN

export const deleteComment = (id: number, tenantId: number) =>
  axios.delete(`${ENDPOINT}?id=${id}&tenantId=${tenantId}`);
//COMMENT_ADMIN

export const listComment = (
  tenantId: number,
  subjectType: string,
  subjectId: number | string,
  parentId?: number | string,
  isVisibleForCompany?: boolean,
) =>
  axios.post<GenericListResponse<Comment>>(`${ENDPOINT}/list-by-subject`, {
    tenantId,
    subjectType,
    subjectId,
    parentId,
    isVisibleForCompany,
  }); //COMMENT_GET

export const createPublicComment = (
  tenantId: number,
  type: string,
  subjectType: string,
  subjectId: string,
  note: string,
  token: string,
  email: string,
  file?: File | null,
  isReportVisibleByReporter?: boolean,
  isReportVisibleByCompany?: boolean,
) => {
  const formData = new FormData();
  formData.set("type", type);
  formData.set("tenantId", tenantId.toString());
  formData.set("subjectId", subjectId);
  formData.set("subjectType", subjectType);
  formData.set("token", token);
  formData.set("email", email);
  formData.set("note", note);
  formData.set("isReportVisibleByReporter", !!isReportVisibleByReporter + "");
  formData.set("isReportVisibleByCompany", !!isReportVisibleByCompany + "");
  file && formData.set("file", file);
  return axios.post(`${ENDPOINT}/with-file-for-report`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const listPublicComment = (
  token: string,
  email: string,
  tenantId: number,
  subjectType: string,
  subjectId: number | string,
  reportId: number | string,
) =>
  axios.post<GenericListResponse<Comment>>(`${ENDPOINT}/list-by-subject-for-report`, {
    token,
    email,
    tenantId,
    subjectType,
    subjectId,
    reportId,
  });

export const uploadCv = (userId: number, tenantId: number, file?: File | null) => {
  const formData = new FormData();
  formData.set("tenantId", tenantId.toString());
  formData.set("subjectId", userId.toString());
  file && formData.set("file", file);
  return axios.post(`${ENDPOINT}/upload-cv`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
