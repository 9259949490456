import axios from "config/axios";
import { GenericPageResponse, GenericResponse } from "shared/common";
import { User } from "shared/types";

export type Tag = {
  id?: number;
  tag: string;
  tenantId?: number;
};

const ENDPOINT = "/tag-controller";

export const createTag = (param: any, tenantId: number) =>
  axios.post<GenericResponse<User>>(`${ENDPOINT}/create`, { param, tenantId });

export const modifyTag = (param: string, tenantId: number) =>
  axios.put(`${ENDPOINT}/modify`, { param, tenantId });

export const deleteTag = (param: number | string, tenantId: number) =>
  axios.delete(`${ENDPOINT}`, { data: { params: [param], tenantId } });

export const getTagById = (id: number, tenantId: number) =>
  axios.post(`${ENDPOINT}/get-by-id`, { param: id, tenantId });

export const getTagPage = (
  tenantId: number,
  page = 0,
  size = 10,
  search: string = "",
  sort: string = "",
) => {
  return axios.get<GenericPageResponse<Tag>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${
      search || ""
    }&sort=${sort || ""}`,
  );
};
