import { Box, Button, Grid, Typography } from "@material-ui/core";
import { AddBox } from "@material-ui/icons";
import FormCard from "components/FormCard";
import { Dispatch, SetStateAction, useEffect } from "react";
import { FieldArrayMethodProps, FieldArrayWithId } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Issue } from "shared/types";
import { IssueFormItem, IssueFromValues } from "../IssueCreate";
import ItemRow from "./ItemRow";

type Props = {
  issue?: Issue;
  append: (
    value: Partial<IssueFormItem> | Partial<IssueFormItem>[],
    options?: FieldArrayMethodProps | undefined,
  ) => void;
  fields: FieldArrayWithId<IssueFromValues, "items", "key">[];
  remove: (index?: number | number[] | undefined) => void;
  replace: (value: Partial<IssueFormItem> | Partial<IssueFormItem>[]) => void;
  id?: string;
  setItemCreateState?: Dispatch<SetStateAction<number | null>>;
};

const ItemCard = ({ issue, append, fields, remove, replace, setItemCreateState }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      append({});
    }
  }, [location.state, append]);

  useEffect(() => {
    if (!location.state && issue?.items) {
      const itemListData: IssueFormItem[] = [];
      issue?.items?.forEach(item => {
        itemListData.push({
          id: item.id,
          item: item.item,
          quantity: (item.quantity || 0).toString(),
          netPrice: item?.netPrice || "",
          currency: item?.currency || "",
          fromWarehouse: item.fromWarehouse || null,
          itemSerial: item.itemSerial || null,
          totalOperatingHours: item.totalOperatingHours || null,
          activeDeliveryTime: item.activeDeliveryTime || null,
          passiveDeliveryTime: item.passiveDeliveryTime || null,
          numberOfBanknotes: item.numberOfBanknotes || null,
        });
      });
      replace(itemListData);
    }
  }, [issue?.items, location.state, replace]);

  return (
    <FormCard title={"Alkatrészek"}>
      {fields.length ? (
        fields.map((field, index) => (
          <ItemRow
            key={index}
            field={field}
            index={index}
            remove={remove}
            setItemCreateState={setItemCreateState}
          />
        ))
      ) : (
        <Grid item container xs={12} justifyContent="center">
          <Box textAlign="center">
            <Typography color="secondary" style={{ fontSize: 20, width: "100%" }}>
              {t("common:emptyItemList3")}
            </Typography>
          </Box>
        </Grid>
      )}

      <Box display="flex" justifyContent="center" width="100%">
        <Button
          variant="outlined"
          color="primary"
          style={{ marginBottom: 8, marginTop: 16 }}
          onClick={event => {
            event.stopPropagation();
            append({});
          }}
          startIcon={<AddBox />}
        >
          {t("projectTemplate.addName")}
        </Button>
      </Box>
    </FormCard>
  );
};

export default ItemCard;
