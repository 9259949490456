import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  GridSize,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { ExpandLess, ExpandMore, OpenInNew } from "@material-ui/icons";
import { Link } from "components/Router";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { CSSProperties, Fragment, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { hasAnyAuthority } from "shared/authorization";

type DataItem = {
  name: string;
  value: ReactNode;
  linkTo?: string;
  ignoreColumns?: boolean;
  needPermission?: string[];
  hide?: boolean;
};

type Props = {
  title: ReactNode;
  columns: 1 | 2 | 3 | 4 | "flex";
  dataList: DataItem[];
  dataUniqueFields?: DataItem[];
  description?: DataItem[];
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  itemkeyPrefix?: string;
  customData?: ReactNode;
  headerButtons?: ReactNode;
  style?: CSSProperties;
  subTitle?: ReactNode;
  columnFlexRule?: {
    xs?: 1 | 2 | 3 | 4;
    sm?: 1 | 2 | 3 | 4;
    md?: 1 | 2 | 3 | 4;
    lg?: 1 | 2 | 3 | 4;
    xl?: 1 | 2 | 3 | 4;
  };
  collapsible?: boolean;
};

const useStyles = makeStyles({
  card: {
    width: "100%",
  },
  content: {
    "&.MuiCardContent-root": {
      paddingBottom: 16,
    },
    padding: 16,
  },
});

const DetailsCard = ({
  title,
  columns,
  dataList,
  dataUniqueFields,
  description,
  itemkeyPrefix,
  customData,
  headerButtons,
  style,
  subTitle,
  columnFlexRule,
  collapsible,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.only("xs"));
  const matchesSm = useMediaQuery(theme.breakpoints.only("sm"));
  const matchesMd = useMediaQuery(theme.breakpoints.only("md"));
  const matchesLg = useMediaQuery(theme.breakpoints.only("lg"));
  const matchesXl = useMediaQuery(theme.breakpoints.only("xl"));

  const [collapse, setCollapse] = useState(false);
  const [columnCount, setColumnCount] = useState<GridSize>(12);

  const columnRule = {
    xs: columnFlexRule?.xs || 2,
    sm: columnFlexRule?.sm || 2,
    md: columnFlexRule?.md || 2,
    lg: columnFlexRule?.lg || 3,
    xl: columnFlexRule?.xl || 4,
  };

  useEffect(() => {
    if (columns) {
      if (columns === "flex") {
        if (matchesXl) {
          setColumnCount((12 / columnRule.xl) as GridSize);
        } else if (matchesLg) {
          setColumnCount((12 / columnRule.lg) as GridSize);
        } else if (matchesMd) {
          setColumnCount((12 / columnRule.md) as GridSize);
        } else if (matchesSm) {
          setColumnCount((12 / columnRule.sm) as GridSize);
        } else if (matchesXs) {
          setColumnCount((12 / columnRule.xs) as GridSize);
        }
      } else {
        setColumnCount((12 / columns) as GridSize);
      }
    }
  }, [matchesXs, matchesSm, matchesMd, matchesLg, matchesXl, setColumnCount]); //eslint-disable-line

  return (
    <Box style={style}>
      <Card
        className={classes.card}
        style={{ backgroundColor: "rgba(255, 255, 255, 0.6)", height: "100%" }}
      >
        <CardHeader
          style={{ paddingBottom: 0 }}
          title={
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
              gridGap={8}
            >
              {title}
              <Box>{headerButtons}</Box>
              {collapsible && (
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => {
                    setCollapse(!collapse);
                  }}
                >
                  {collapse ? <ExpandMore /> : <ExpandLess />}
                </IconButton>
              )}
            </Box>
          }
          subheader={
            <Typography variant="body2" style={{ fontSize: 16 }}>
              {subTitle}
            </Typography>
          }
        />
        <CardContent className={classes.content} style={{ height: "100%" }}>
          {!collapse && (
            <Grid container spacing={1}>
              {dataList.map((entry, index) => {
                return (!entry.needPermission ||
                  hasAnyAuthority(
                    account.user,
                    account.permissions,
                    selectedRelTenant,
                    entry.needPermission,
                  )) &&
                  !entry.hide ? (
                  <Fragment key={(itemkeyPrefix || "") + index}>
                    {entry.ignoreColumns && (
                      <Box width="100%">
                        <Divider style={{ marginBottom: "10px", marginTop: "10px" }} />
                      </Box>
                    )}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={entry.ignoreColumns ? 12 : columnCount}
                      container
                    >
                      <Grid
                        item
                        xs={12}
                        sm={entry.name === t("issues.formValues.description") ? 12 : 5}
                        md={entry.name === t("issues.formValues.description") ? 12 : 5}
                        container
                        alignContent="center"
                        style={{ color: COLORS.lightGrey }}
                      >
                        <Typography variant="body2">{entry.name + ":"}</Typography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={entry.name === t("issues.formValues.description") ? 12 : 7}
                        md={entry.name === t("issues.formValues.description") ? 12 : 7}
                        container
                        alignContent="center"
                      >
                        <span style={{ fontWeight: "bold" }}>{entry.value}</span>
                        {!!entry.linkTo && (
                          <IconButton
                            component={"a"}
                            href={entry.linkTo}
                            size="small"
                            color="primary"
                            style={{
                              height: 20,
                              width: 20,
                              marginLeft: 4,
                              color: COLORS.lightGrey,
                            }}
                          >
                            <OpenInNew style={{ height: 14, width: 14 }} />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  </Fragment>
                ) : null;
              })}
            </Grid>
          )}
          {!!dataUniqueFields?.length && (
            <Divider style={{ marginBottom: "10px", marginTop: "10px" }} />
          )}
          <Grid container>
            {dataUniqueFields?.map((entry, index) => {
              return (
                (!entry.needPermission ||
                  hasAnyAuthority(
                    account.user,
                    account.permissions,
                    selectedRelTenant,
                    entry.needPermission,
                  )) &&
                !entry.hide && (
                  <Grid
                    item
                    sm={entry.ignoreColumns ? 12 : columnCount}
                    container
                    key={(itemkeyPrefix || "") + index}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={4}
                      container
                      alignContent="center"
                      style={{ color: COLORS.lightGrey, marginBottom: "4px" }}
                    >
                      <Typography variant="body2">{entry.name + ":"}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={8} md={8} container alignContent="center">
                      <Typography variant="body2">{entry.value}</Typography>
                      {!!entry.linkTo && (
                        <IconButton
                          component={Link}
                          to={entry.linkTo}
                          size="small"
                          color="primary"
                          style={{
                            height: 20,
                            width: 20,
                            marginLeft: 4,
                            color: COLORS.lightGrey,
                          }}
                        >
                          <OpenInNew style={{ height: 14, width: 14 }} />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                )
              );
            })}
          </Grid>
          {!!customData && <Box marginTop={2}>{customData}</Box>}
          {!!description?.length && (
            <>
              {description?.map(value => {
                return (
                  <>
                    {(!value?.needPermission ||
                      hasAnyAuthority(
                        account.user,
                        account.permissions,
                        selectedRelTenant,
                        value?.needPermission,
                      )) &&
                      !value?.hide && (
                        <Fragment>
                          {value?.ignoreColumns && (
                            <Box width="100%">
                              <Divider style={{ marginBottom: "10px", marginTop: "10px" }} />
                            </Box>
                          )}
                          <Grid item xs={12} sm={12} md={12} lg={12} container>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              container
                              alignContent="center"
                              style={{ color: COLORS.lightGrey }}
                            >
                              <Typography variant="body2" style={{ paddingBottom: 4 }}>
                                {value?.name + ":"}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} container alignContent="center">
                              <Typography variant="body2" style={{ whiteSpace: "pre-wrap" }}>
                                {value?.value}
                              </Typography>
                              {!!value?.linkTo && (
                                <IconButton
                                  component={Link}
                                  to={value?.linkTo}
                                  size="small"
                                  color="primary"
                                  style={{
                                    height: 20,
                                    width: 20,
                                    marginLeft: 4,
                                    color: COLORS.lightGrey,
                                  }}
                                >
                                  <OpenInNew style={{ height: 14, width: 14 }} />
                                </IconButton>
                              )}
                            </Grid>
                          </Grid>
                        </Fragment>
                      )}
                  </>
                );
              })}
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default DetailsCard;
