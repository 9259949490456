import { Box, CircularProgress, Container } from "@material-ui/core";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SliceStatus } from "shared/common";
import { getCompanyById } from "shared/network/company.api";
import { createIssueType } from "shared/network/issue-type.api";
import IssueTypeForm, { IssueTypeFormValues } from "./IssueTypeForm";
import { useLocation } from "react-router-dom";
import { IssueType } from "shared/types";
import queryClient from "config/query";

type Props = {
  setIssueTypeOpen?: Dispatch<SetStateAction<boolean>>;
  setNewIssueType?: (issueType: IssueType) => void;
  isDialog: boolean;
};

const IssueTypeCreate = ({ setIssueTypeOpen, setNewIssueType, isDialog }: Props) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const idNullable = query.get("id");
  const id = idNullable ? idNullable : "";
  const { t } = useTranslation();
  const history = useHistory();

  const form = useForm<IssueTypeFormValues>();
  const { enqueueSnackbar } = useSnackbar();

  const [status, setStatus] = useState<SliceStatus>("idle");

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);
  const selectedCompany = useQuery(["companyIssueType", id], async () => {
    if (id) {
      const { data } = await getCompanyById(Number.parseInt(id), tenant.id);
      return data.item;
    } else {
      return null;
    }
  });

  const onSubmitCreate = async (values: IssueTypeFormValues) => {
    try {
      setStatus("pending");
      const { data } = await createIssueType(
        {
          ...values,
          companyId: values.company ? values.company.id : "",
          tenantId: tenant.id,
        },
        tenant.id,
      );
      setStatus("success");
      enqueueSnackbar(
        t("common:notification.create.success", {
          subject: t("issueType.subject"),
        }),
        {
          variant: "success",
        },
      );
      if (setIssueTypeOpen) {
        if (setNewIssueType && (data as any)?.item) {
          setNewIssueType?.((data as any)?.item);
        }
        setIssueTypeOpen(false);
        queryClient.invalidateQueries("issueIssueTypeListQuery");
      } else {
        history.goBack();
      }
    } catch (e) {
      enqueueSnackbar(
        t("common:notification.create.failure", {
          subject: t("issueType.subject"),
        }),
        { variant: "error" },
      );
      setStatus("failure");
    }
  };

  return (
    <Container maxWidth="lg" style={{ padding: isDialog ? 0 : undefined }}>
      <form onSubmit={form.handleSubmit(onSubmitCreate)}>
        {status === "pending" ? (
          <Box display="flex" alignItems="center" justifyContent="center" height="300px">
            <CircularProgress />
          </Box>
        ) : (
          <FormProvider {...form}>
            <IssueTypeForm
              selectedCompany={selectedCompany.data}
              isDialog={isDialog}
              setIssueTypeOpen={setIssueTypeOpen}
            />
          </FormProvider>
        )}
      </form>
    </Container>
  );
};

export default IssueTypeCreate;
