import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";

type Props = {
  onSubmit: () => void;
  title: string;
  body: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const NewSignatureConfirm = ({ title, body, onSubmit, open, setOpen }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth="sm"
      onClick={event => event.stopPropagation()}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{body}</DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            setOpen(false);
          }}
        >
          {t("common:button.cancel")}
        </Button>
        <Button
          onClick={() => {
            setOpen(false);
            onSubmit();
          }}
        >
          {t("common:button.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewSignatureConfirm;
