import { Box, Divider, MenuItem, Typography } from "@material-ui/core";
import supportedLocales from "config/supportedLocales";
import { format } from "date-fns";
import i18n from "i18n";
import { Dispatch, SetStateAction } from "react";
import { Reminder } from "shared/network/reminder.api";

type Props = {
  reminder: Reminder;
  isSelected: boolean;
  setSelectedReminder: Dispatch<SetStateAction<Reminder | null>>;
};

const ReminderViewerListItem = ({ reminder, isSelected, setSelectedReminder }: Props) => {
  return (
    <Box key={reminder?.id}>
      <MenuItem onClick={() => setSelectedReminder(reminder)} selected={isSelected}>
        <Box>
          <Typography style={{ fontWeight: "bold" }}>{reminder?.user?.name || "-"}</Typography>
          {reminder?.when && (
            <Typography style={{ fontSize: 12 }}>
              {format(new Date(reminder?.when), "Pp", {
                locale: supportedLocales[i18n.language],
              })}
            </Typography>
          )}
        </Box>
      </MenuItem>
      <Divider />
    </Box>
  );
};

export default ReminderViewerListItem;
