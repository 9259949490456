import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Assignment, HighlightOff } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import { RootState } from "config/store";
import supportedLocales from "config/supportedLocales";
import { format } from "date-fns";
import i18n from "i18n";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getPageCashRegisterAmountHistory } from "shared/network/cash-register-amount-history.api";
import { CashRegisterAmount } from "shared/network/cash-register-amount.api";
import CashCommentDialog from "./CashCommentDialog";

type Props = {
  selectedAmount: CashRegisterAmount | null;
  setSelectedAmount: Dispatch<SetStateAction<CashRegisterAmount | null>>;
};

const CashRegisterAmountHistoryDialog = ({ selectedAmount, setSelectedAmount }: Props) => {
  const { t } = useTranslation();
  const tenantId = useSelector(
    (state: RootState) => state.authentication?.selectedRelTenant?.tenant?.id,
  );
  const [page, setPage] = useState(0);
  const [selectedHistory, setSelectedHistory] = useState<any>(null);

  const cashRegisterAmountHistoryList = useQuery(
    ["cashRegisterAmountHistoryList", page, tenantId, selectedAmount?.cashRegister.id],
    async () => {
      const { data } = await getPageCashRegisterAmountHistory({
        page,
        size: 50,
        search: `cashRegister.id:${selectedAmount?.cashRegister.id};currency.id:${selectedAmount?.currency?.id}`,
        tenantId,
      });
      return data;
    },
    { enabled: !!selectedAmount },
  );

  return (
    <>
      <CashCommentDialog
        selectedHistory={selectedHistory}
        setSelectedHistory={setSelectedHistory}
      />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={!!selectedAmount}
        onClose={() => setSelectedAmount(null)}
      >
        <DialogTitle style={{ paddingBottom: 0 }}>{t("cashRegister.history")}</DialogTitle>
        <Box position="absolute" right={8} top={8}>
          <IconButton onClick={() => setSelectedAmount(null)}>
            <HighlightOff />
          </IconButton>
        </Box>
        <Box p={2}>
          <Typography>
            <span style={{ fontWeight: "bold", padding: 4 }}>{t("cashRegister.currency")}: </span>
            {selectedAmount?.currency?.name}
          </Typography>
        </Box>
        <DialogContent style={{ padding: "0 16px 16px 16px" }}>
          {!!cashRegisterAmountHistoryList.data?.page?.content?.length && (
            <>
              <Grid container style={{ padding: 4 }}>
                <Grid item xs={4}>
                  <Typography style={{ fontWeight: "bold" }}>{t("cashRegister.time")}</Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: "right" }}>
                  <Typography style={{ fontWeight: "bold" }}>{t("cashRegister.change")}</Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: "right" }}>
                  <Typography style={{ fontWeight: "bold", paddingRight: 26 }}>
                    {t("cashRegister.new")}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
            </>
          )}
          {cashRegisterAmountHistoryList?.isFetching ? (
            <Box p={2} display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : !!cashRegisterAmountHistoryList.data?.page?.content?.length ? (
            cashRegisterAmountHistoryList.data?.page?.content?.map(amount => (
              <Box key={amount.id}>
                <Grid container style={{ padding: 4 }}>
                  <Grid item xs={4}>
                    <Typography>
                      {!!amount.createdOn &&
                        format(new Date(amount.createdOn), "Pp", {
                          locale: supportedLocales[i18n.language],
                        })}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography style={{ textAlign: "right" }}>
                      {amount?.currency?.prefix}{" "}
                      {t("common:currency", {
                        num: amount?.amountDiff,
                        cur: amount?.currency?.postfix,
                      })}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Typography style={{ textAlign: "right" }}>
                      {amount?.currency?.prefix}{" "}
                      {t("common:currency", {
                        num: amount?.quantity,
                        cur: amount?.currency?.postfix,
                      })}
                    </Typography>
                    <Tooltip title="Részletek">
                      <IconButton
                        size="small"
                        color="primary"
                        disabled={!amount?.commentNumber}
                        onClick={() => setSelectedHistory(amount)}
                      >
                        <Assignment fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Divider />
              </Box>
            ))
          ) : (
            <Grid container style={{ padding: 4 }}>
              <Typography>{t("tool.formValues.noData")}</Typography>
            </Grid>
          )}
          {cashRegisterAmountHistoryList.data &&
            cashRegisterAmountHistoryList?.data?.page.totalPages > 1 && (
              <Pagination
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "24px 0",
                }}
                count={cashRegisterAmountHistoryList?.data?.page.totalPages}
                color="primary"
                page={cashRegisterAmountHistoryList.data.page.number + 1}
                onChange={(e, page) => setPage(page - 1)}
              />
            )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CashRegisterAmountHistoryDialog;
