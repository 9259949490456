import { Box, Checkbox, FormControlLabel, MenuItem, Typography } from "@material-ui/core";
import { RootState } from "config/store";
import i18n from "i18n";
import { toUpper } from "lodash";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getCompanyUsersById } from "shared/network/company.api";
import { listUsers } from "shared/network/user.api";
import { User } from "shared/types";
import { getUserFullName } from "./CustomEmail";

type Props = {
  selectedUsers: User[];
  setSelectedUsers: Dispatch<SetStateAction<User[]>>;
  selectedEmails: string[];
  setSelectedEmails: Dispatch<SetStateAction<string[]>>;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  companyId: any;
};

const CreateEmailUsers = ({
  selectedUsers,
  setSelectedUsers,
  selectedEmails,
  setSelectedEmails,
  search,
  setSearch,
  companyId,
}: Props) => {
  const { t } = useTranslation();

  const tenantId = useSelector(
    (state: RootState) => state.authentication?.selectedRelTenant?.tenant?.id,
  );

  const listUsersQuery = useQuery(
    ["listUsersQuery", tenantId],
    async () => {
      const { data } = await listUsers(0, 2000, tenantId, "", "");
      return data;
    },
    { enabled: !companyId },
  );

  const companyUsersQuery = useQuery(
    ["companyUsersQueryEmail"],
    async () => {
      if (companyId) {
        const { data } = await getCompanyUsersById(Number.parseInt(companyId), tenantId);
        return data.items?.map(item => item) || [];
      }
      return Promise.reject();
    },
    { enabled: !!companyId },
  );

  const filteredList = !companyId
    ? listUsersQuery.data?.page?.content?.filter(user =>
        getUserFullName(user, toUpper(i18n.language))
          ?.toUpperCase()
          ?.includes(search.toUpperCase()),
      )
    : (companyUsersQuery.data as any[])?.filter(user =>
        getUserFullName(user, toUpper(i18n.language))
          ?.toUpperCase()
          ?.includes(search.toUpperCase()),
      );

  return (
    <Box overflow="auto" height={400}>
      {!!filteredList?.length ? (
        filteredList?.map((user, index) => {
          const fullname = getUserFullName(user, toUpper(i18n.language));

          return (
            <Box key={index} width="100%">
              <MenuItem
                style={{ height: 50 }}
                onClick={() => {
                  const users = [...selectedUsers];
                  if (selectedUsers?.find(us => us.id === user.id)) {
                    setSelectedUsers(users.filter(us => us.id !== user.id));
                  } else {
                    users.push(user);
                    setSelectedUsers(users);
                  }
                }}
              >
                <FormControlLabel
                  label=""
                  control={
                    <Checkbox
                      size="small"
                      color="primary"
                      checked={selectedUsers?.indexOf(user) > -1}
                    />
                  }
                />
                <Box>
                  <Typography style={{ fontWeight: "bold" }}>{fullname}</Typography>
                  <Typography>{user?.email}</Typography>
                </Box>
              </MenuItem>
            </Box>
          );
        })
      ) : (
        <Typography style={{ padding: 16 }}>A lista üres</Typography>
      )}
    </Box>
  );
};

export default CreateEmailUsers;
