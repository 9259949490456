import {
  Box,
  Button,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddBox, Delete, Info } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import FormCard from "components/FormCard";
import NumberField from "components/NumberField";
import { NUMBER_FORMAT } from "config/constants";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, UseFieldArrayReturn, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { listCurrencyByTenant } from "shared/network/currency.api";
import { inactivatePriceHistory } from "shared/network/item.api";
import { BaseCurrency, Item, ItemPriceHistory } from "shared/types";
import { useDebouncedCallback } from "use-debounce";
import { ItemFormValues, useStyles } from "./ItemForm";

type Props = {
  item?: Item;
  itemPriceHistoryEntryFieldArray: UseFieldArrayReturn<
    ItemFormValues,
    "itemPriceHistoryEntries",
    "key"
  >;
};

const ItemFormSellPrice = ({ item, itemPriceHistoryEntryFieldArray }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { control, watch, setValue } = useFormContext<ItemFormValues>();
  const formItem = watch("item");
  const useCompositePrice = watch("useCompositePrice");

  const { append, fields, remove, replace } = itemPriceHistoryEntryFieldArray;

  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const { tenant } = selectedRelTenant;

  const [currencySearch, setCurrencySearch] = useState<string>("");

  const currencyListQuery = useQuery(["currencyList", tenant.id, currencySearch], async () => {
    const { data } = await listCurrencyByTenant(
      0,
      10,
      tenant.id,
      currencySearch ? `name:${currencySearch}` : "",
    );
    return data.page;
  });

  const inactivatePrice = async (param: ItemPriceHistory, index: number) => {
    try {
      if (param.id) {
        await inactivatePriceHistory(tenant.id, param);
      }
      remove(index);
      setValue(
        "itemPriceHistoryEntries",
        fields.filter((_, fieldIndex) => index !== fieldIndex),
      );
    } catch (error: any) {
      if (error.data.status === "AT_LEAST_ONE_ACTIVE") {
        enqueueSnackbar("Legalább egy ár megadása kötelező!", {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Sikertelen törlés!", {
          variant: "error",
        });
      }
    }
  };

  const handleCurrencySearchChange = useDebouncedCallback((value: string) => {
    setCurrencySearch(value);
  }, 300);

  useEffect(() => {
    setValue(
      "itemPriceHistoryEntries",
      item?.itemPriceHistories?.filter(history => history.isActive) || [],
    );
    replace(item?.itemPriceHistories?.filter(history => history.isActive) || []);
  }, [item?.itemPriceHistories, replace, setValue]);

  return (
    <FormCard title={t("item.sellPrice")}>
      <Grid container spacing={2}>
        <Grid item xs={12} container justifyContent="center">
          <Controller
            name="item.isScalingPricing"
            control={control}
            defaultValue={item?.isScalingPricing || false}
            render={({ field: { onChange, value, ref } }) => (
              <FormControlLabel
                className={classes.switch}
                label={
                  <Box display="flex" alignItems="center" gridGap={4}>
                    {t("item.isScalingPricing")}
                    <Tooltip
                      style={{
                        paddingRight: "2px",
                      }}
                      title={t("item.isScalingPricingHelp").toString()}
                    >
                      <Info style={{ color: COLORS.lightBlue }} />
                    </Tooltip>
                  </Box>
                }
                labelPlacement="start"
                control={
                  <Switch
                    size="small"
                    onChange={onChange}
                    checked={value}
                    inputRef={ref}
                    color="primary"
                  />
                }
              />
            )}
          />
        </Grid>
      </Grid>
      <Collapse in={formItem?.isScalingPricing}>
        <Grid container item spacing={2} style={{ paddingTop: 8 }}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="item.scalingXName"
              defaultValue={item?.scalingXName || ""}
              rules={{
                required: formItem?.isScalingPricing && t("validation.required").toString(),
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  margin="none"
                  label={t("item.scalingXName")}
                  InputLabelProps={{ shrink: true, required: formItem?.isScalingPricing }}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="item.scalingYName"
              defaultValue={item?.scalingYName || ""}
              rules={{
                required: formItem?.isScalingPricing && t("validation.required").toString(),
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  margin="none"
                  label={t("item.scalingYName")}
                  InputLabelProps={{ shrink: true, required: formItem?.isScalingPricing }}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </Collapse>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      {fields.map((field, index) => (
        <Grid key={field.key} container spacing={1} style={{ marginBottom: 8 }}>
          <Grid item xs={6}>
            <Typography style={{ fontSize: 16, fontWeight: "bold" }}>{index + 1}.</Typography>
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            <IconButton onClick={() => inactivatePrice(field, index)} color="primary" size="small">
              <Delete fontSize="small" />
            </IconButton>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              name={`itemPriceHistoryEntries.${index}.price`}
              defaultValue=""
              rules={{
                required: t("validation.required").toString(),
                validate: value => {
                  if (value && !value.toString().match(NUMBER_FORMAT)) {
                    return t("common:validation.numberFormat").toString();
                  }
                },
              }}
              render={({ field, fieldState }) => (
                <NumberField
                  field={field}
                  disabled={useCompositePrice}
                  fieldState={fieldState}
                  label={t("item.price")}
                  InputLabelProps={{ shrink: true, required: true }}
                  numberLimits={{ step: "0.01", min: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              name={`itemPriceHistoryEntries.${index}.monthlyRentalPrice`}
              defaultValue=""
              rules={{
                validate: value => {
                  if (value && !value.toString().match(NUMBER_FORMAT)) {
                    return t("common:validation.numberFormat").toString();
                  }
                },
              }}
              render={({ field, fieldState }) => (
                <NumberField
                  field={field}
                  fieldState={fieldState}
                  label={t("item.monthlyRentalPrice")}
                  InputLabelProps={{ shrink: true }}
                  numberLimits={{ step: "0.01", min: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              name={`itemPriceHistoryEntries.${index}.currency`}
              defaultValue={null}
              rules={{
                required: t("validation.required").toString(),
              }}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  disabled={useCompositePrice}
                  onChange={(_, value) => {
                    field.onChange(value);
                    handleCurrencySearchChange("");
                  }}
                  onInputChange={(event, newInputValue) => {
                    handleCurrencySearchChange(newInputValue);
                  }}
                  options={currencyListQuery.data?.content || []}
                  getOptionLabel={(option: BaseCurrency) => option.name}
                  getOptionSelected={option => option.id === field.value?.id}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={t("currency.title")}
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                      }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          {formItem?.futurePriceHandling && (
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name={`itemPriceHistoryEntries.${index}.validFrom`}
                defaultValue={(item && item?.itemPriceHistories[index]?.validFrom) || null}
                render={({ field, fieldState }) => (
                  <KeyboardDateTimePicker
                    {...field}
                    ref={undefined}
                    variant="inline"
                    label={t("supplierPriceHistory.formValues.validFrom")}
                    format="yyyy. MM. dd. HH:mm:ss"
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      ))}
      <Box display="flex" justifyContent="center">
        <Button
          size="small"
          variant="outlined"
          color="primary"
          startIcon={<AddBox />}
          onClick={() => append({ currency: null, monthlyRentalPrice: "", price: "" })}
          disabled={useCompositePrice}
        >
          {t("common:button.add")}
        </Button>
      </Box>
    </FormCard>
  );
};

export default ItemFormSellPrice;
