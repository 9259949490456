import { Box, Card, Grid, Typography } from "@material-ui/core";
import { COLORS } from "config/theme";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { JobForm } from "shared/network/job-questionnaire.api";

type Props = {
  jobForm: JobForm;
};

const JobDataCard = ({ jobForm }: Props) => {
  const { t } = useTranslation();

  return (
    <Card style={{ padding: 16 }}>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontSize: 20,
          fontWeight: "bold",
          paddingBottom: 8,
        }}
      >
        {t("jobForm.jobData.title")}
      </Typography>
      {jobForm?.jobExperienceEntries?.map((field, index) => {
        return (
          <Grid key={field.id} container spacing={1} style={{ marginBottom: 8 }}>
            <Grid item xs={12}>
              <Box display="flex">
                <Typography style={{ fontWeight: "bold", fontSize: 16, color: COLORS.mainLight }}>
                  {t("jobForm.jobData.row", { number: index + 1 })}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography style={{ fontWeight: "bold" }}>
                {!!field.fromDate && format(new Date(field.fromDate), "yyyy. MM.")}
                {" - "}
                {!!field.toDate && format(new Date(field.toDate), "yyyy. MM.")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography>
                <strong>{t("jobForm.jobData.jobTitleShort")}: </strong>
                {field?.jobTitle}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography>
                <strong>{t("jobForm.jobData.experience")}: </strong>
                {field?.experience}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography>
                <strong>{t("jobForm.jobData.reference")}: </strong>
                {field?.referencePersonName}
                {" ("}
                {field?.referencePersonEmail}, {field?.referencePersonPhone}
                {")"}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </Card>
  );
};

export default JobDataCard;
