import {
  faBell,
  faBookOpenReader,
  faCalendar,
  faCircleCheck,
  faCircleExclamation,
  faCircleXmark,
  faClipboardQuestion,
  faComment,
  faEnvelopeOpenText,
  faFlag,
  faFlagCheckered,
  faHourglass,
  faNoteSticky,
  faThumbsDown,
  faThumbsUp,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Typography } from "@material-ui/core";
import { COLORS } from "config/theme";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  selectedIcon: any | null;
  setSelectedIcon: Dispatch<SetStateAction<any | null>>;
  selectedColor: string;
};

export function getIcon(icon: string) {
  if (icon === "faCircleExclamation") {
    return faCircleExclamation;
  } else if (icon === "faCircleXmark") {
    return faCircleXmark;
  } else if (icon === "faTriangleExclamation") {
    return faTriangleExclamation;
  } else if (icon === "faCircleCheck") {
    return faCircleCheck;
  } else if (icon === "faEnvelopeOpenText") {
    return faEnvelopeOpenText;
  } else if (icon === "faBell") {
    return faBell;
  } else if (icon === "faFlag") {
    return faFlag;
  } else if (icon === "faFlagCheckered") {
    return faFlagCheckered;
  } else if (icon === "faNoteSticky") {
    return faNoteSticky;
  } else if (icon === "faComment") {
    return faComment;
  } else if (icon === "faBookOpenReader") {
    return faBookOpenReader;
  } else if (icon === "faHourGlass") {
    return faHourglass;
  } else if (icon === "faClipboardQuestion") {
    return faClipboardQuestion;
  } else if (icon === "faCalendar") {
    return faCalendar;
  } else if (icon === "faThumbsUp") {
    return faThumbsUp;
  } else if (icon === "faThumbsDown") {
    return faThumbsDown;
  } else return faCircleExclamation;
}

const RelTagIcons = ({ selectedIcon, setSelectedIcon, selectedColor }: Props) => {
  const { t } = useTranslation();

  const icons = [
    { name: "faCircleExclamation", component: faCircleExclamation },
    { name: "faCircleXmark", component: faCircleXmark },
    { name: "faTriangleExclamation", component: faTriangleExclamation },
    { name: "faCircleCheck", component: faCircleCheck },
    { name: "faEnvelopeOpenText", component: faEnvelopeOpenText },
    { name: "faBell", component: faBell },
    { name: "faFlag", component: faFlag },
    { name: "faFlagCheckered", component: faFlagCheckered },
    { name: "faNoteSticky", component: faNoteSticky },
    { name: "faComment", component: faComment },
    { name: "faBookOpenReader", component: faBookOpenReader },
    { name: "faHourGlass", component: faHourglass },
    { name: "faClipboardQuestion", component: faClipboardQuestion },
    { name: "faCalendar", component: faCalendar },
    { name: "faThumbsUp", component: faThumbsUp },
    { name: "faThumbsDown", component: faThumbsDown },
  ];

  return (
    <>
      <Typography style={{ fontWeight: "bold" }}>{t("tags.icon")}:</Typography>
      {icons?.map(icon => (
        <IconButton onClick={() => setSelectedIcon(icon.name)} style={{ width: 48, height: 48 }}>
          <FontAwesomeIcon
            icon={icon.component}
            style={{
              width: 24,
              height: 24,
              color: selectedIcon === icon.name ? selectedColor : COLORS.lightGrey,
            }}
          />
        </IconButton>
      ))}
    </>
  );
};

export default RelTagIcons;
