import {
  Box,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Backspace, Info } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import FormCard from "components/FormCard";
import { ITEM_TYPES, PRODUCT_UNIT, SUB_ITEM_TYPE, VTSZ_SZJ_TYPE } from "config/constants";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { isEmpty, uniqBy } from "lodash";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getTaxEntryListPageable } from "shared/network/tax-api";
import {
  BaseCurrency,
  Company,
  CompositeItem,
  Item,
  ItemCategory,
  ItemPriceHistory,
  ItemTaxHistory,
  LedgerNumber,
  TaxEntry,
} from "shared/types";
import CompositeItemForm from "../CompositeItemForm";
import ItemFormCategories from "./ItemFormCategories";
import ItemFormSellPrice from "./ItemFormSellPrice";
import SupplierPriceHistoryForm from "./SupplierPriceHistoryForm";

export const useStyles = makeStyles({
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  switch: {
    "&.MuiFormControlLabel-labelPlacementStart": {
      marginLeft: 0,
      marginRight: 0,
      flexDirection: "row-reverse",
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
  },
  dataContainer: {
    border: `1px solid ${COLORS.mainGrey}`,
    borderRadius: 4,
    padding: 8,
    marginBottom: 10,
    width: "100%",
  },
  cell: {
    padding: 8,
  },
});

export type ItemFormValues = {
  item: any; // Item;
  itemTaxHistoryEntry: ItemTaxHistory;
  itemPriceHistoryEntries: ItemPriceHistory[];
  ledgerNumberEntry: LedgerNumber;
  categories: ItemCategory[];
  compositeItems: any; // CompositeItem[];
  useCompositePrice?: boolean;
  showInvoiceSubItems?: boolean;
  ledgerNumbersOpen: boolean;
  supplierPriceHistoryEntries: {
    company: Company | null;
    price: string;
    currency: BaseCurrency | null;
  }[];
  serviceDetails: any;
};

type Props = {
  item?: Item;
  isLoading?: boolean;
  isModal?: boolean;
  isDefaultPurchasable?: boolean;
  setCreateOpen: Dispatch<SetStateAction<boolean>>;
};

const ItemForm = ({ item, isModal, isDefaultPurchasable, setCreateOpen }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const { tenant } = selectedRelTenant;

  const { control, watch, register, setValue } = useFormContext<ItemFormValues>();

  const itemPriceHistoryEntryFieldArray = useFieldArray({
    control,
    name: "itemPriceHistoryEntries",
    keyName: "key",
  });
  const { fields, replace } = itemPriceHistoryEntryFieldArray;

  const supplierPriceHistoryEntryFieldArray = useFieldArray({
    control,
    name: "supplierPriceHistoryEntries",
    keyName: "key",
  });

  const [useCompositePrice, compositeItems, itemPriceHistoryEntries] = watch([
    "useCompositePrice",
    "compositeItems",
    "itemPriceHistoryEntries",
  ]);
  const formItem = watch("item");

  const [selectedCategories, setSelectedCategories] = useState<ItemCategory[]>([]);

  const hasAnySellPrice =
    !isEmpty(itemPriceHistoryEntries) && !!itemPriceHistoryEntries?.find(entry => entry.price);

  const avaibleTaxTypeQuery = useQuery(
    ["avaibleTaxTypeQuery", tenant.id],
    async () => {
      const { data } = await getTaxEntryListPageable(0, 100000000, tenant.id);
      return data.page.content;
    },
    {
      onSuccess: data => {
        if (!item) {
          setValue(
            "itemTaxHistoryEntry.tax",
            data.find(entry => entry.percent === 27) || data.find(entry => entry.id || 1) || null,
          );
        }
      },
    },
  );

  const calculatePriceFromComposites = useCallback(() => {
    const distinctCurrencies = uniqBy(
      (compositeItems as CompositeItem[])
        ?.map(item => item.buildingItem?.itemPriceHistories)
        .flat()
        .filter(item => item?.isActive),
      "currency.id",
    ).map(history => history?.currency);

    const pricesByCurrency = distinctCurrencies.map(currency => {
      return {
        currency: currency,
        price: (compositeItems as CompositeItem[])
          .map(item => {
            let temp = item.buildingItem?.itemPriceHistories.find(history => {
              return history?.currency?.id === currency?.id && history.isActive;
            });
            if (temp) {
              return ((temp.price as number) || 0) * (item.quantity || 0);
            } else {
              return null;
            }
          })
          // @ts-ignore
          .reduce((acc, a) => acc + a, 0),
      };
    });
    if (useCompositePrice) {
      pricesByCurrency.forEach((value, index) => {
        setValue(`itemPriceHistoryEntries.${index}.currency`, value?.currency || null);
        setValue(`itemPriceHistoryEntries.${index}.price`, value?.price || "");
      });
      replace(
        pricesByCurrency.map(value => {
          return { currency: value?.currency || null, price: value?.price || "" };
        }),
      );
    }
  }, [compositeItems, replace, setValue, useCompositePrice]);

  useEffect(() => {
    if (!hasAnySellPrice && !item?.purchasable) {
      setValue("item.purchasable", false);
    }
    if (hasAnySellPrice && isDefaultPurchasable) {
      setValue("item.purchasable", true);
    }
  }, [hasAnySellPrice, itemPriceHistoryEntries, fields, setValue]); //eslint-disable-line

  return (
    <>
      <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-start">
        <Grid container item xs={12} sm={12} md={6} style={{ marginBottom: 12 }}>
          <FormCard title={t("common:itemData")}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {!!item?.id && <input type="hidden" value={item?.id} {...register("item.id")} />}
                <Controller
                  control={control}
                  name="item.name"
                  defaultValue={item?.name || ""}
                  rules={{
                    required: t("validation.required").toString(),
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("item.name")}
                      InputLabelProps={{ shrink: true, required: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              {tenant.isUseManufacturer && (
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="item.manufacturer"
                    defaultValue={item?.manufacturer || ""}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={t("item.manufacturer")}
                        InputLabelProps={{ shrink: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="item.type"
                  rules={{
                    required: t("validation.required").toString(),
                  }}
                  defaultValue={item?.type || ""}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("item.type")}
                      InputLabelProps={{ shrink: true, required: true }}
                      select
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    >
                      <MenuItem value="" disabled>
                        <>{t("common:choose")}</>
                      </MenuItem>
                      {ITEM_TYPES.map(type => (
                        <MenuItem
                          key={type}
                          value={type}
                          style={{
                            marginLeft: SUB_ITEM_TYPE.includes(type) ? 20 : undefined,
                          }}
                        >
                          <>{t(`item.types.${type}`)}</>
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="item.invoiceItemName"
                  defaultValue={item?.invoiceItemName || ""}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("item.invoiceItemName")}
                      InputLabelProps={{ shrink: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="item.productCode"
                  defaultValue={item?.productCode || ""}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("item.productCode")}
                      InputLabelProps={{ shrink: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="item.oldItem"
                  defaultValue={item?.oldItem || ""}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("item.oldItem")}
                      InputLabelProps={{ shrink: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Collapse in={formItem?.productUnit !== "OWN"}>
                  <Controller
                    control={control}
                    name="item.productUnit"
                    defaultValue={item?.productUnit || "PIECE"}
                    rules={{
                      required: t("validation.required").toString(),
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={t("item.productUnit")}
                        InputLabelProps={{ shrink: true, required: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        select
                      >
                        <MenuItem value="" disabled>
                          <>{t("common:choose")}</>
                        </MenuItem>
                        {PRODUCT_UNIT.map(unit => (
                          <MenuItem key={unit} value={unit}>
                            <>{t(`item.unit.${unit}`)}</>
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Collapse>
                <Collapse in={formItem?.productUnit === "OWN"}>
                  <Controller
                    control={control}
                    name="item.customUnitTypeName"
                    defaultValue={item?.customUnitTypeName || ""}
                    rules={{
                      required:
                        formItem?.productUnit === "OWN"
                          ? t("validation.required").toString()
                          : undefined,
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={t("item.customUnitTypeName")}
                        InputLabelProps={{ shrink: true, required: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        InputProps={{
                          endAdornment: (
                            <Tooltip title={t("common:button.back").toString()}>
                              <IconButton
                                onClick={() => {
                                  setValue("item.productUnit", "");
                                  setValue("item.customUnitTypeName", "");
                                }}
                              >
                                <Backspace />
                              </IconButton>
                            </Tooltip>
                          ),
                        }}
                      />
                    )}
                  />
                </Collapse>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="itemTaxHistoryEntry.tax"
                  defaultValue={item?.itemTaxHistories.find(item => item.isActive)?.tax || null}
                  rules={{
                    required: t("validation.required").toString(),
                  }}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      onChange={(_, value) => field.onChange(value)}
                      options={avaibleTaxTypeQuery.data || []}
                      getOptionLabel={(option: TaxEntry) => `${option.name} (${option.percent})`}
                      getOptionSelected={option => option.id === field.value?.id}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={t("item.tax")}
                          InputLabelProps={{
                            shrink: true,
                            required: true,
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={`item.VtszSzjType`}
                  defaultValue={item?.vtszSzjType || ""}
                  render={({ field }) => (
                    <TextField
                      select
                      {...field}
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                      label={t("invoice.invoiceItem.vtszSzj.title")}
                    >
                      <MenuItem disabled value="">
                        <>{t("common:choose")}</>
                      </MenuItem>
                      {VTSZ_SZJ_TYPE.map(type => (
                        <MenuItem key={type} value={type}>
                          <>{t(`invoice.invoiceItem.vtszSzj.${type}`)}</>
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={`item.vtszSzjTeszorNumber`}
                  defaultValue={item?.vtszSzjTeszorNumber || ""}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      InputLabelProps={{ shrink: true }}
                      label={t("item.vtszSzjTeszorNumber")}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={`item.color`}
                  defaultValue={item?.color || ""}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      InputLabelProps={{ shrink: true }}
                      label={t("item.color")}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={`item.size`}
                  defaultValue={item?.size || ""}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      InputLabelProps={{ shrink: true }}
                      label={t("item.size")}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={`item.sizeType`}
                  defaultValue={item?.sizeType || ""}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      InputLabelProps={{ shrink: true }}
                      label={t("item.sizeType")}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={`item.plusDetail`}
                  defaultValue={item?.plusDetail || ""}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      InputLabelProps={{ shrink: true }}
                      label={t("item.plusDetail")}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <Controller
                  name="ledgerNumbersOpen"
                  control={control}
                  defaultValue={false}
                  render={({ field: { onChange, value, ref } }) => (
                    <FormControlLabel
                      className={classes.switch}
                      label={"Főkönyvi számok megadása"}
                      labelPlacement="start"
                      control={
                        <Switch
                          size="small"
                          onChange={onChange}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid>
              {!!watch("ledgerNumbersOpen") && (
                <>
                  <Grid item xs={6} sm={6}>
                    <Controller
                      control={control}
                      name="ledgerNumberEntry.inlandLedgerNumber"
                      defaultValue={item?.ledgerNumber?.inlandLedgerNumber || ""}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label={t("item.inlandLedgerNumber")}
                          InputLabelProps={{ shrink: true }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Controller
                      control={control}
                      name="ledgerNumberEntry.foreignLedgerNumber"
                      defaultValue={item?.ledgerNumber?.foreignLedgerNumber || ""}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label={t("item.foreignLedgerNumber")}
                          InputLabelProps={{ shrink: true }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Controller
                      control={control}
                      name="ledgerNumberEntry.acquisitionLedgerNumber"
                      defaultValue={item?.ledgerNumber?.acquisitionLedgerNumber || ""}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label={t("item.acquisitionLedgerNumber")}
                          InputLabelProps={{ shrink: true }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Controller
                      control={control}
                      name="ledgerNumberEntry.stockReductionLedgerNumber"
                      defaultValue={item?.ledgerNumber?.stockReductionLedgerNumber || ""}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label={t("item.stockReductionLedgerNumber")}
                          InputLabelProps={{ shrink: true }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="item.description"
                  defaultValue={item?.description || ""}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      multiline
                      minRows={4}
                      InputLabelProps={{ shrink: true }}
                      label={t("item.description")}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <Controller
                  name="item.purchasable"
                  control={control}
                  defaultValue={item?.purchasable || !!isDefaultPurchasable}
                  render={({ field: { onChange, value, ref } }) => (
                    <FormControlLabel
                      className={classes.switch}
                      label={t("item.purchasable.title")}
                      labelPlacement="start"
                      disabled={!hasAnySellPrice}
                      control={
                        <Switch
                          size="small"
                          onChange={onChange}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <Controller
                  name="item.isSerialItem"
                  control={control}
                  defaultValue={item?.isSerialItem || false}
                  render={({ field: { onChange, value, ref } }) => (
                    <FormControlLabel
                      className={classes.switch}
                      label={t("item.isSerialItem")}
                      labelPlacement="start"
                      control={
                        <Switch
                          size="small"
                          onChange={onChange}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <Controller
                  name="item.futurePriceHandling"
                  control={control}
                  defaultValue={item?.futurePriceHandling}
                  render={({ field: { onChange, value, ref } }) => (
                    <FormControlLabel
                      className={classes.switch}
                      label={t("item.futurePriceHandling")}
                      labelPlacement="start"
                      control={
                        <Switch
                          size="small"
                          onChange={onChange}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid>
              {!isModal && (
                <Grid item xs={12} container justifyContent="center">
                  <Controller
                    name="item.isComposite"
                    control={control}
                    defaultValue={item?.isComposite || false}
                    render={({ field: { onChange, value, ref } }) => (
                      <FormControlLabel
                        className={classes.switch}
                        label={
                          <Box display="flex" alignItems="center" gridGap={4}>
                            {t("item.isComposite")}
                            <Tooltip
                              style={{
                                paddingRight: "2px",
                              }}
                              title={t("item.compositeDescription").toString()}
                            >
                              <Info style={{ color: COLORS.lightBlue }} />
                            </Tooltip>
                          </Box>
                        }
                        labelPlacement="start"
                        control={
                          <Switch
                            size="small"
                            onChange={onChange}
                            checked={value}
                            inputRef={ref}
                            color="primary"
                          />
                        }
                      />
                    )}
                  />
                </Grid>
              )}
              {formItem?.isComposite && (
                <>
                  <Grid item xs={12} container justifyContent="center">
                    <Controller
                      name="useCompositePrice"
                      control={control}
                      defaultValue={item?.useCompositePrice || false}
                      render={({ field: { onChange, value, ref } }) => (
                        <FormControlLabel
                          className={classes.switch}
                          label={t("item.useCompositePrice")}
                          labelPlacement="start"
                          control={
                            <Switch
                              size="small"
                              onChange={onChange}
                              checked={value}
                              inputRef={ref}
                              color="primary"
                            />
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} container justifyContent="center">
                    <Controller
                      name="showInvoiceSubItems"
                      control={control}
                      defaultValue={item?.showInvoiceSubItems || false}
                      render={({ field: { onChange, value, ref } }) => (
                        <FormControlLabel
                          className={classes.switch}
                          label={t("item.showInvoiceSubItems")}
                          labelPlacement="start"
                          control={
                            <Switch
                              size="small"
                              onChange={onChange}
                              checked={value}
                              inputRef={ref}
                              color="primary"
                            />
                          }
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </FormCard>
        </Grid>
        <Grid container item xs={12} sm={12} md={6} justifyContent="flex-start">
          {!item && (
            <Box pb={2} width="100%">
              <SupplierPriceHistoryForm
                supplierPriceHistoryEntryFieldArray={supplierPriceHistoryEntryFieldArray}
                itemPriceHistoryEntryFieldArray={itemPriceHistoryEntryFieldArray}
              />
            </Box>
          )}
          <Box pb={2} width="100%">
            <ItemFormSellPrice
              item={item}
              itemPriceHistoryEntryFieldArray={itemPriceHistoryEntryFieldArray}
            />
          </Box>
          <ItemFormCategories
            item={item}
            isModal={!!isModal}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            setCreateOpen={setCreateOpen}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Collapse in={formItem?.isComposite} style={{ width: "100%" }}>
          <CompositeItemForm
            item={item}
            calculatePriceFromComposites={calculatePriceFromComposites}
          />
        </Collapse>
      </Grid>
    </>
  );
};

export default ItemForm;
