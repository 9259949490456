import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Loading from "components/Loading";
import queryClient from "config/query";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { listCompanyByTenant } from "shared/network/company.api";
import { JobForm } from "shared/network/job-questionnaire.api";
import { createJobReferredTo } from "shared/network/job-referred-to.api";
import { Company } from "shared/types";
import { useDebouncedCallback } from "use-debounce";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  jobForm: JobForm;
};
type FormValues = {
  company: Company;
};

const JobReferredToDialog = ({ open, setOpen, jobForm }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const tenantId = useSelector(
    (state: RootState) => state?.authentication?.selectedRelTenant?.tenant?.id,
  );
  const form = useForm();
  const { control, handleSubmit } = form;
  const [loading, setLoading] = useState(false);

  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const tenant = selectedRelTenant.tenant;

  const [companySearch, setCompanySearch] = useState<string>("");

  const handleCompanySearchChange = useDebouncedCallback((value: string) => {
    if (value.length >= 3 || value.length === 0) {
      setCompanySearch(value);
    }
  }, 300);

  const companyListQuery = useQuery(
    ["issueCompanyListQuery", tenant.id, companySearch],
    async () => {
      const { data } = await listCompanyByTenant(
        0,
        20,
        tenant.id,
        companySearch ? `nameSearch:${companySearch}` : ``,
      );
      return data;
    },
  );

  async function onSubmit(values: FormValues) {
    setLoading(true);
    try {
      await createJobReferredTo(
        {
          ...values,
          user: jobForm?.user || null,
          jobQuestionnaireId: jobForm?.id || null,
        },
        tenantId,
      );
      queryClient.invalidateQueries(["jobFormQuery"]);
      enqueueSnackbar(t("common:notification.save.success"), { variant: "success" });
    } catch {
      enqueueSnackbar(t("common:notification.save.failure"), { variant: "error" });
    }
    setLoading(false);
  }

  return (
    <>
      <Loading open={loading} />
      <Dialog fullWidth maxWidth="xs" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Partnerhez rendelés</DialogTitle>
        <DialogContent style={{ paddingTop: 0 }}>
          <FormProvider {...form}>
            <form id="form-to-partner" onSubmit={handleSubmit(onSubmit)}>
              <Box pb={2} pl={1}>
                <Typography style={{ fontWeight: "bold", fontSize: 16 }}>
                  {jobForm?.user?.name || "-"}
                </Typography>
                <Typography>{jobForm?.user?.email || "-"}</Typography>
              </Box>
              <Controller
                control={control}
                name="company"
                defaultValue={null}
                rules={{ required: t("validation.required").toString() }}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value);
                      handleCompanySearchChange("");
                    }}
                    onInputChange={(_, newInputValue) => {
                      handleCompanySearchChange(newInputValue);
                    }}
                    options={companyListQuery.data?.page?.content || []}
                    getOptionLabel={(option: Company) => option.name}
                    getOptionSelected={option => option.id === field.value?.id}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={t("company.subject")}
                        InputLabelProps={{ shrink: true, required: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setOpen(false)}>
            {t("common:button.cancel")}
          </Button>
          <Button form="form-to-partner" type="submit" disabled={loading}>
            {t("common:button.ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default JobReferredToDialog;
