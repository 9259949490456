import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Company, Tenant } from "shared/types";

type Props = {
  tenant?: Tenant;
  isPartner: boolean;
  company?: Company;
};

const TenantFormLinks = ({ tenant, isPartner, company }: Props) => {
  const { t }: any = useTranslation();
  const { control } = useFormContext();
  const [linkOpen, setLinkOpen] = useState(false);

  return (
    <Card style={{ position: "relative" }}>
      <CardHeader
        title={t("tenant.links")}
        titleTypographyProps={{
          variant: "h2",
        }}
        style={{ paddingBottom: 16 }}
        onClick={() => setLinkOpen(!linkOpen)}
      />
      <Box style={{ position: "absolute", top: 4, right: 4 }}>
        <Tooltip title={t(`common:button.${linkOpen ? "hide" : "show"}`).toString()}>
          <IconButton onClick={() => setLinkOpen(!linkOpen)}>
            {linkOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </Tooltip>
      </Box>
      <Collapse in={linkOpen}>
        <CardContent>
          <Box display="flex" flexDirection="column" gridGap={16}>
            <Controller
              control={control}
              name="webpage"
              defaultValue={isPartner ? company?.webpage || "" : tenant?.webpage || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  label={t("tenant.formValues.webpage")}
                />
              )}
            />
            {!isPartner && (
              <>
                <Typography style={{ fontWeight: "bold" }}>Szabályzatok és feltételek</Typography>
                <Controller
                  control={control}
                  name="tos"
                  defaultValue={tenant?.tos || ""}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      InputLabelProps={{ shrink: true }}
                      label={t("tenant.formValues.TOS")}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="gdpr"
                  defaultValue={tenant?.gdpr || ""}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      InputLabelProps={{ shrink: true }}
                      label={t("tenant.formValues.GDPR")}
                    />
                  )}
                />
              </>
            )}
            <Typography style={{ fontWeight: "bold" }}>Közösségi média</Typography>
            <Controller
              control={control}
              name="viber"
              defaultValue={isPartner ? company?.viber || "" : tenant?.viber || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  label={t("tenant.formValues.viber")}
                />
              )}
            />
            <Controller
              control={control}
              name="facebook"
              defaultValue={isPartner ? company?.facebook || "" : tenant?.facebook || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  label={t("tenant.formValues.facebook")}
                />
              )}
            />
            <Controller
              control={control}
              name="instagram"
              defaultValue={isPartner ? company?.instagram || "" : tenant?.instagram || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  label={t("tenant.formValues.instagram")}
                />
              )}
            />
            <Controller
              control={control}
              name="linkedin"
              defaultValue={isPartner ? company?.linkedin || "" : tenant?.linkedin || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  label={t("tenant.formValues.linkedin")}
                />
              )}
            />
            <Controller
              control={control}
              name="tiktok"
              defaultValue={isPartner ? company?.tiktok || "" : tenant?.tiktok || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  label={t("tenant.formValues.tiktok")}
                />
              )}
            />
            <Controller
              control={control}
              name="youtube"
              defaultValue={isPartner ? company?.youtube || "" : tenant?.youtube || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  label={t("tenant.formValues.youtube")}
                />
              )}
            />
            <Controller
              control={control}
              name="pinterest"
              defaultValue={isPartner ? company?.pinterest || "" : tenant?.pinterest || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  label={t("tenant.formValues.pinterest")}
                />
              )}
            />
            <Controller
              control={control}
              name="twitter"
              defaultValue={isPartner ? company?.twitter || "" : tenant?.twitter || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  label={t("tenant.formValues.twitter")}
                />
              )}
            />
          </Box>
        </CardContent>
      </Collapse>
    </Card>
  );
};
export default TenantFormLinks;
