import { Box, Button, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import FormCard from "components/FormCard";
import NumberField from "components/NumberField";
import {
  NUMBER_FORMAT,
  PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY,
  PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH,
} from "config/constants";
import { RootState } from "config/store";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { listCompanyByTenant } from "shared/network/company.api";
import { listUsers } from "shared/network/user.api";
import { Company, Issue, Project, TimeEntry, User } from "shared/types";
import { useDebouncedCallback } from "use-debounce";
import { ListboxComponent } from "views/Project/components/ProjectOfferAddDialog";
import { TimeEntryFormValues } from "./TimeEntryCreate";

type Props = {
  timeEntry?: TimeEntry | null;
  project?: Project;
  issue?: Issue;
  inout?: "IN" | "OUT" | null;
  onClose?: () => void;
};

const TimeEntryForm = ({ timeEntry, project, issue, inout, onClose }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const tenant = useSelector((state: RootState) => state.authentication.selectedRelTenant?.tenant);
  const { control } = useFormContext<TimeEntryFormValues>();

  const [userSearch, setUserSearch] = useState<string>("");
  const [companySearch, setCompanySearch] = useState<string>("");

  const { data: dataUser, fetchNextPage: fetchNextPageUser } = useInfiniteQuery(
    [`timeEntryUserListQuery`, tenant.id, userSearch],
    async ({ pageParam = 0 }) => {
      const { data } = await listUsers(
        pageParam,
        20,
        tenant.id,
        userSearch ? `name:${userSearch}` : "",
      );
      return data;
    },
    {
      getNextPageParam: lastGroup => {
        const nextPage = lastGroup.page.number + 1;
        if (nextPage <= lastGroup.page.totalPages - 1) {
          return nextPage;
        }
        return false;
      },
    },
  );

  const flatPagesUser = dataUser?.pages.map(page => page.page?.content).flat();
  const hasNextPageUser =
    dataUser && flatPagesUser && dataUser?.pages?.[0]?.page?.totalElements > flatPagesUser?.length;

  const handleUserSearchChange = useDebouncedCallback((value: string) => {
    if (value.length >= PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH || value.length === 0) {
      setUserSearch(value);
    }
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  const { data, fetchNextPage } = useInfiniteQuery(
    [`timeEntryCompanyListQuery`, tenant.id, companySearch],
    async ({ pageParam = 0 }) => {
      const { data } = await listCompanyByTenant(
        pageParam,
        20,
        tenant.id,
        `isSupplier:true` + (companySearch ? `;nameSearch:${companySearch}` : ""),
      );
      return data;
    },
    {
      getNextPageParam: lastGroup => {
        const nextPage = lastGroup.page.number + 1;
        if (nextPage <= lastGroup.page.totalPages - 1) {
          return nextPage;
        }
        return false;
      },
    },
  );

  const flatPages = data?.pages.map(page => page.page?.content).flat();
  const hasNextPage =
    data && flatPages && data?.pages?.[0]?.page?.totalElements > flatPages?.length;

  const handleCompanySearchChange = useDebouncedCallback((value: string) => {
    setCompanySearch(value);
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  return (
    <>
      <FormCard title={t("common:timeEntry")} style={{ boxShadow: "unset", border: "unset" }}>
        <Grid container spacing={2}>
          {!!project ? (
            <>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  rules={{ required: !!project && t("validation.required").toString() }}
                  name="projectId"
                  defaultValue={project?.id + "" || ""}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      disabled
                      label={t("timeEntry.projectId")}
                      InputLabelProps={{ shrink: true, required: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              {!!issue && (
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    rules={{ required: !project && t("validation.required").toString() }}
                    name="issueId"
                    defaultValue={issue?.id?.toString() || ""}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        disabled
                        label={t("timeEntry.issueId")}
                        InputLabelProps={{ shrink: true, required: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                {inout === "OUT" ? (
                  <Controller
                    control={control}
                    name="company"
                    defaultValue={timeEntry?.company || issue?.contractorCompany || null}
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <Autocomplete
                        {...field}
                        onChange={(_, value) => {
                          field.onChange(value);
                          handleCompanySearchChange("");
                        }}
                        onInputChange={(event, newInputValue) => {
                          handleCompanySearchChange(newInputValue);
                        }}
                        options={flatPages || []}
                        getOptionLabel={(option: Company) => option.name}
                        getOptionSelected={option => option.id === field.value?.id}
                        ListboxComponent={props => (
                          <ListboxComponent
                            {...props}
                            hasNextPage={hasNextPage}
                            fetchNextPage={fetchNextPage}
                          />
                        )}
                        renderInput={params => (
                          <TextField
                            {...params}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("companySite.formValues.company")}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    )}
                  />
                ) : (
                  <Controller
                    control={control}
                    name="user"
                    defaultValue={timeEntry?.user || null}
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <Autocomplete
                        {...field}
                        onChange={(_, value) => {
                          field.onChange(value);
                          handleUserSearchChange("");
                        }}
                        onInputChange={(event, newInputValue) => {
                          handleUserSearchChange(newInputValue);
                        }}
                        options={flatPagesUser || []}
                        getOptionLabel={(option: User) => option.name}
                        getOptionSelected={option => option.id === field.value?.id}
                        ListboxComponent={props => (
                          <ListboxComponent
                            {...props}
                            hasNextPage={hasNextPageUser}
                            fetchNextPage={fetchNextPageUser}
                          />
                        )}
                        renderInput={params => (
                          <TextField
                            {...params}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("user.formValues.name")}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    )}
                  />
                )}
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Controller
                control={control}
                rules={{ required: !project && t("validation.required").toString() }}
                name="issueId"
                defaultValue=""
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label={t("timeEntry.issueId")}
                    InputLabelProps={{ shrink: true, required: true }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              defaultValue={timeEntry?.workDate ? new Date(timeEntry.workDate) : new Date()}
              name="workDate"
              render={({ field: { ref, ...field } }) => (
                <KeyboardDatePicker
                  {...field}
                  label={t("timeEntry.workDate")}
                  format="yyyy.MM.dd."
                  onChange={date => field.onChange(date)}
                  InputLabelProps={{ shrink: true, required: true }}
                  style={{ alignContent: "flex-end" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              defaultValue={timeEntry?.workTime || 0}
              rules={{
                required: t("validation.required").toString(),
                validate: value => {
                  if (value && !value.toString().match(NUMBER_FORMAT)) {
                    return t("common:validation.numberFormat").toString();
                  }
                },
              }}
              name="workTime"
              render={({ field, fieldState }) => (
                <NumberField
                  field={field}
                  fieldState={fieldState}
                  label={t("timeEntry.workTime")}
                  InputLabelProps={{ shrink: true, required: true }}
                  numberLimits={{ step: "0.01", min: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              defaultValue={timeEntry?.description || ""}
              name="description"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  multiline
                  minRows={4}
                  label={t("timeEntry.description")}
                  InputLabelProps={{ shrink: true }}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </FormCard>
      <Box display="flex" justifyContent="center" m={2} gridGap={8}>
        <Button
          color="primary"
          variant="text"
          onClick={() => {
            if (onClose) {
              onClose();
            } else {
              history.goBack();
            }
          }}
        >
          {t("common:button.cancel")}
        </Button>
        <Button type="submit" color="primary">
          {timeEntry ? t("common:button.save") : t("common:button.create")}
        </Button>
      </Box>
    </>
  );
};

export default TimeEntryForm;
