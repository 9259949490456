import { Box, Button, Typography } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  id: string;
  offsetTop: number;
  offsetLeft: number;
  optional?: boolean;
};

const SignatureCanvas = ({ id = "signature", offsetTop, offsetLeft, optional }: Props) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLCanvasElement | null>(null);

  const [drawing, setDrawing] = useState(false);
  const [{ ctx, rect }, setState] = useState<{
    ctx?: null | CanvasRenderingContext2D;
    rect?: null | DOMRect;
  }>({ ctx: null, rect: null });

  useEffect(() => {
    setState({
      ctx: ref.current?.getContext("2d"),
      rect: ref.current?.getBoundingClientRect(),
    });
  }, [ref.current]); //eslint-disable-line

  useEffect(() => {
    if (ctx) {
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, 100000, 100000);
      ctx.strokeStyle = "black";
      ctx.lineCap = "round";
      ctx.lineWidth = 2;
    }
  }, [ctx]);

  return (
    <>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={"8px 0px 8px 8px"}
        >
          <Typography variant="h3">{t("common:signature.subject")}:</Typography>
          <Button
            startIcon={<Delete />}
            size="small"
            variant="outlined"
            onClick={() => {
              if (ctx) {
                ctx.fillStyle = "white";
                ctx.fillRect(0, 0, 100000, 100000);
              }
            }}
          >
            {t("common:button.delete")}
          </Button>
        </Box>
        <canvas
          id={id}
          ref={ref}
          onMouseDown={e => {
            if (ctx) {
              ctx.beginPath();
              ctx.moveTo(
                e.clientX + offsetLeft - (rect?.left ?? 0),
                e.clientY + offsetTop - (rect?.top ?? 0),
              );
              setDrawing(true);
            }
          }}
          onTouchStart={e => {
            if (ctx) {
              ctx.beginPath();
              ctx.moveTo(
                e.touches[e.touches.length - 1].clientX + offsetLeft - (rect?.left ?? 0),
                e.touches[e.touches.length - 1].clientY + offsetTop - (rect?.top ?? 0),
              );
              setDrawing(true);
            }
          }}
          onMouseMove={e => {
            if (drawing && ctx) {
              ctx.lineTo(
                e.clientX + offsetLeft - (rect?.left ?? 0),
                e.clientY + offsetTop - (rect?.top ?? 0),
              );
              ctx.stroke();
            }
          }}
          onTouchMove={e => {
            if (drawing && ctx) {
              ctx.lineTo(
                e.touches[e.touches.length - 1].clientX + offsetLeft - (rect?.left ?? 0),
                e.touches[e.touches.length - 1].clientY + offsetTop - (rect?.top ?? 0),
              );
              ctx.stroke();
            }
          }}
          onMouseUp={() => {
            ctx?.closePath();
            setDrawing(false);
          }}
          onMouseOut={() => {
            ctx?.closePath();
            setDrawing(false);
          }}
          width={350}
          height={250}
          style={{
            border: "1px solid rgb(196,196,196)",
            touchAction: "none",
            borderRadius: 8,
          }}
        />
      </Box>
    </>
  );
};

export default SignatureCanvas;
