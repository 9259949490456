import { faFileCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  ButtonBase,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { GetApp } from "@material-ui/icons";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { listComment, uploadCv } from "shared/network/comment.api";
import { getFileByPath } from "shared/network/file.api";
import { JobForm } from "shared/network/job-questionnaire.api";
import { fileDownload } from "shared/util/fileDownload";

const CVDataCard = ({ jobForm }: { jobForm: JobForm }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenantId = selectedRelTenant?.tenant?.id;
  const form = useForm();
  const { register, watch, handleSubmit } = form;
  const cvImage = watch("cvFile")?.[0];
  const [loading, setLoading] = useState(false);

  const cvListQuery = useQuery(["cvListQuery", tenantId, jobForm?.id], async () => {
    if (jobForm?.id) {
      const { data } = await listComment(tenantId, "USER_CV", jobForm?.id);
      return data.items;
    }
    return Promise.reject();
  });

  async function onSubmit(values: { cvFile: any }) {
    setLoading(true);
    try {
      if (values.cvFile?.[0] && jobForm?.id) {
        await uploadCv(jobForm?.id, tenantId ? Number(tenantId) : 1, values.cvFile[0]);
      }
      cvListQuery?.refetch();
      form?.reset();
      enqueueSnackbar("Sikeres feltöltés", { variant: "success" });
    } catch (err: any) {
      enqueueSnackbar("Sikertelen feltöltés", { variant: "error" });
    }
    setLoading(false);
  }

  return (
    <Card style={{ padding: 16, marginBottom: 16 }}>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontSize: 20,
          fontWeight: "bold",
          paddingBottom: 8,
        }}
      >
        {t("jobForm.details.cvs")}
      </Typography>
      <Box style={{ marginBottom: 8 }}>
        {cvListQuery?.data?.map((field, index, array) => {
          return (
            <Box key={field.id} display="flex" alignItems="center" gridGap={4}>
              <Typography>
                {array?.length > 1 && (
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                      color: COLORS.mainLight,
                      paddingLeft: 4,
                      paddingRight: 4,
                    }}
                  >
                    {index + 1}.
                  </span>
                )}
                {field.originalFileName}
              </Typography>
              <Tooltip title={t("comment.download")}>
                <IconButton
                  color="primary"
                  size="small"
                  onClick={async event => {
                    event.preventDefault();
                    if (field?.id) {
                      try {
                        const { data } = await getFileByPath(field.filePath, tenantId);
                        fileDownload(data, `${jobForm.name} CV`);
                      } catch (e) {
                        enqueueSnackbar(
                          t("common:notification.download.failure", {
                            subject: t("comment.subject"),
                          }),
                          {
                            variant: "error",
                          },
                        );
                      }
                    }
                  }}
                >
                  <GetApp />
                </IconButton>
              </Tooltip>
            </Box>
          );
        })}
      </Box>
      {!!cvListQuery?.data?.length && <Divider />}
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box pt={1}>
            <Typography style={{ fontWeight: "bold", fontSize: 16, paddingBottom: 4 }}>
              {t("common:button.upload")}:
            </Typography>
            {!!cvImage?.name && (
              <Typography style={{ paddingBottom: 8 }}>{cvImage?.name}</Typography>
            )}
            <ButtonBase component="label" style={{ borderRadius: 10, marginRight: 8 }}>
              <input
                style={{ display: "none" }}
                type="file"
                {...register("cvFile", {
                  required: {
                    value: false,
                    message: t("personalData.formValues.fileRequired"),
                  },
                  validate: value => {
                    if (value?.[0]?.size >= 5242880) {
                      return t("validation.maxSize", {
                        size: 5,
                        unit: "MB",
                      }).toString();
                    }
                  },
                })}
              />
              <Card variant="outlined">
                <Box display="flex" alignItems="center" gridGap={12} p={"8px 16px"}>
                  <FontAwesomeIcon icon={faFileCirclePlus} style={{ width: 24, height: 24 }} />
                  <Typography style={{ textTransform: "uppercase", fontWeight: "bold" }}>
                    {t("jobForm.personalData.selectCv")}
                  </Typography>
                </Box>
              </Card>
            </ButtonBase>
            <Button type="submit" disabled={!cvImage || loading} style={{ height: 40 }}>
              {loading ? (
                <CircularProgress size={24} style={{ margin: "0 24px" }} />
              ) : (
                t("common:button.upload")
              )}
            </Button>
            {!!form?.formState?.errors?.cvFile?.message && (
              <Typography style={{ paddingTop: 8, color: COLORS.red }}>
                {form?.formState?.errors?.cvFile?.message}
              </Typography>
            )}
          </Box>
        </form>
      </FormProvider>
    </Card>
  );
};

export default CVDataCard;
