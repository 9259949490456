import { Box, Button, CircularProgress, MenuItem, Popover, Typography } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { hasAuthority } from "shared/authorization";
import { exportQrCode } from "shared/network/item.api";
import { b64toBlob } from "shared/util/fileDownload";

type Props = {
  toSelectedList: number[];
  setToSelectedList: Dispatch<SetStateAction<number[]>>;
  selectionOpen: boolean;
  setSelectionOpen: Dispatch<SetStateAction<boolean>>;
  warehouseId?: string | number;
};

const QrDownloadButton = ({
  toSelectedList,
  setToSelectedList,
  selectionOpen,
  setSelectionOpen,
  warehouseId,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const urlCreator = window.URL || window.webkitURL;

  const [loading, setLoading] = useState(false);
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  async function downloadQrList(ids: number[], tenantId: number) {
    setLoading(true);
    setAnchorEl(null);
    try {
      const { data } = await exportQrCode(ids, tenantId, warehouseId);
      const link = document.createElement("a");
      link.href = urlCreator.createObjectURL(b64toBlob(data?.item?.content, "application/pdf"));
      link.setAttribute("download", data?.item?.fileName ?? "");
      document.body.appendChild(link);
      link.click();
      link.remove();
      setSelectionOpen(false);
      setToSelectedList([]);
      enqueueSnackbar(t("common:notification.download.success"), {
        variant: "success",
      });
    } catch {
      enqueueSnackbar(t("common:notification.download.failure"), {
        variant: "error",
      });
    }
    setLoading(false);
  }

  return (
    <Box>
      {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ITEM_ADMIN"]) && (
        <>
          {selectionOpen ? (
            <>
              <Button
                style={{ marginRight: 8 }}
                variant="contained"
                size="small"
                startIcon={
                  loading ? (
                    <Box display="flex" marginRight="3px" marginLeft="3px">
                      <CircularProgress style={{ width: 14, height: 14 }} />
                    </Box>
                  ) : (
                    <GetApp />
                  )
                }
                disabled={loading || !toSelectedList?.length}
                onClick={() => downloadQrList(toSelectedList, tenant?.id)}
              >
                {t("item.qrSelect")}
              </Button>
              <Button
                variant="text"
                size="small"
                disabled={loading}
                onClick={() => {
                  setSelectionOpen(false);
                  setToSelectedList([]);
                }}
              >
                {t("common:button.cancel")}
              </Button>
            </>
          ) : (
            <Button
              variant="outlined"
              size="small"
              startIcon={
                loading ? (
                  <Box display="flex" marginRight="3px" marginLeft="3px">
                    <CircularProgress style={{ width: 14, height: 14 }} />
                  </Box>
                ) : (
                  <GetApp />
                )
              }
              disabled={loading}
              onClick={event => {
                setAnchorEl(event.currentTarget);
              }}
            >
              {t("item.qr")}
            </Button>
          )}
        </>
      )}

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div>
          <MenuItem
            onClick={() => {
              setSelectionOpen(open => !open);
              setAnchorEl(null);
            }}
            style={{ whiteSpace: "break-spaces" }}
          >
            <>{t("item.qrItemSelect")}</>
          </MenuItem>
          <MenuItem
            style={{ whiteSpace: "break-spaces" }}
            disabled={loading}
            onClick={() => downloadQrList(toSelectedList, tenant?.id)}
          >
            <>{t("item.qrItemAll")}</>
          </MenuItem>
        </div>
      </Popover>
    </Box>
  );
};
export default QrDownloadButton;
