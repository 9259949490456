import { faFileLines } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Container, IconButton, Tooltip } from "@material-ui/core";
import { Share } from "@material-ui/icons";
import { GridCellParams, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import supportedLocales from "config/supportedLocales";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { hasAuthority } from "shared/authorization";
import { getJobFormPage, JobForm } from "shared/network/job-questionnaire.api";
import { getTagPage } from "shared/network/tag.api";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/DeliveryRound/DeliveryRoundList";
import RelCreateTag from "views/Tag/RelCreateTag";
import RelModifyTag from "views/Tag/RelModifyTag";
import TagIcon from "views/Tag/TagIcon";
import { getTagColumnWidth } from "views/User/UserList";

const JobForms = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-job-form-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-job-form-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });

  const { page, pageSize, filterValue } = tableState;
  const { selectedRelTenant, account } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant?.tenant;
  const user = account?.user;
  const permissions = account?.permissions;

  const jobForms = useQuery(
    ["jobForms", page, pageSize, sortState, filterValue, selectedRelTenant],
    async () => {
      if (selectedRelTenant.tenant.id) {
        const { data } = await getJobFormPage(
          page,
          pageSize,
          selectedRelTenant.tenant.id,
          selectedRelTenant.isTenantAdmin ||
            user?.isSuperAdmin ||
            hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "JOB_QUESTIONNAIRE_GET",
            ])
            ? filterValue
            : `createdBy:${user?.id};${filterValue}`,
          sortState,
        );
        return data;
      }
      return Promise.reject();
    },
  );

  const columns: GridColDef[] = [
    {
      field: "tagSearch",
      headerName: t("tags.title"),
      width: getTagColumnWidth(jobForms?.data?.page?.content || []),
      sortable: false,
      filterable: true,
      type: "select",
      hide: !(
        selectedRelTenant?.isTenantAdmin ||
        account?.user?.isSuperAdmin ||
        !!permissions?.length
      ),
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" width="100%">
          <TagIcon
            row={row}
            relTagList={row?.relTagSubjectEntryList}
            setSelectedRow={setSelectedRow}
            setSelectedToModify={setSelectedToModify}
          />
        </Box>
      ),
    },
    {
      field: "name",
      headerName: t("jobForm.personalData.name"),
      flex: 1,
    },
    {
      field: "email",
      headerName: t("jobForm.contactData.email"),
      flex: 1,
    },
    {
      field: "phone",
      sortable: false,
      headerName: t("jobForm.contactData.phone"),
      type: "stringContainsNumber",
      flex: 1,
    },
    {
      field: "orderNumber",
      headerName: t("jobForm.personalData.orderNumberShort"),
      flex: 1,
    },
    {
      field: "createdOn",
      headerName: t("jobForm.createdOn"),
      flex: 0.75,
      type: "dateTime",
      sortable: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <>
          {row?.createdOn
            ? format(new Date(row.createdOn?.toString()), "Pp", {
                locale: supportedLocales[i18n.language],
              })
            : ""}
        </>
      ),
    },
    {
      field: "actions",
      headerName: " ",
      sortable: false,
      width: 160,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" alignItems="center" width="100%">
          <Tooltip title={t("common:button.show") as string}>
            <IconButton
              color="primary"
              onClick={() => history.push(`/job-forms/details?id=${row.id}`)}
            >
              <FontAwesomeIcon icon={faFileLines} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions" && params.field !== "tagSearch") {
      history.push(`/job-forms/details?id=${params.row.id}`);
    }
  }

  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [selectedToModify, setSelectedToModify] = useState<JobForm | null>(null);
  const ref = useRef<null | HTMLSpanElement>(null);

  const tagQuery = useQuery(
    ["tagJOB_QUESTIONNAIREQuery", selectedRelTenant?.tenant?.id],
    async () => {
      const { data } = await getTagPage(
        selectedRelTenant?.tenant?.id,
        0,
        1000,
        `(tagType:JOB_QUESTIONNAIRE;(OR)tagType(IS_NULL)1;)`,
      );

      return data;
    },
  );

  return (
    <Container>
      <Box display="flex" justifyContent="center" pb={1}>
        <Button
          size="small"
          variant="outlined"
          startIcon={<Share />}
          onClick={() => {
            const element = ref.current;
            if (element) {
              navigator.clipboard.writeText(element.textContent || "").then(
                () => {
                  enqueueSnackbar(t("jobForm.shareSuccess"), { variant: "success" });
                },
                err => {
                  enqueueSnackbar(t("jobForm.shareFailure"), { variant: "error" });
                },
              );
            }
          }}
        >
          {t("jobForm.share")}
        </Button>
        <span ref={ref} style={{ opacity: 0, height: 0, width: 0 }}>
          {`${window.location.origin}/job-form?tenantId=${tenant?.id}`}
        </span>
      </Box>
      {!!selectedRow && (
        <RelCreateTag
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          refetch={jobForms.refetch}
          subjectType="JOB_QUESTIONNAIRE"
        />
      )}
      {!!selectedToModify && (
        <RelModifyTag
          selectedToModify={selectedToModify}
          setSelectedToModify={setSelectedToModify}
          refetch={jobForms.refetch}
          subjectType="JOB_QUESTIONNAIRE"
        />
      )}
      <PageableTable
        filterOptions={[
          {
            columnName: "tagSearch",
            options: tagQuery?.data?.page?.content?.map(value => {
              return {
                translated: value?.tag || "",
                value: "x" + value?.id + "y",
              };
            }),
          },
        ]}
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="job-form"
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={jobForms}
        onCellClick={onCellClick}
        minWidth={{ value: "md" }}
      />
    </Container>
  );
};

export default JobForms;
