import { Button, Grid } from "@material-ui/core";
import { AddBox } from "@material-ui/icons";
import { GridCellParams, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import SingleQueryTable from "components/PageableTable/SingleQueryTable";
import { RootState } from "config/store";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { listQuestionnaire, listQuestionnaireByCompany } from "shared/network/questionnaire.api";
import { useGetSessionStorageKey } from "views/Comment/function";

export type TableState = {
  page: number;
  pageSize: number;
  filterOpen: boolean;
  filterValue: string;
};

const MolEnergeticList = () => {
  const { t } = useTranslation();
  const { setHeaderButtons } = useHeader();
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-mol-energetic-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-mol-energetic-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const { account } = useSelector((state: RootState) => state.authentication);

  const companyId = account.user?.relUserCompany?.[0]?.company.id;

  const listQuestionnaireQuery = useQuery(["listQuestionnaireQuery", tenant.id], async () => {
    if (tenant.id) {
      const { data } = await listQuestionnaire(tenant.id);
      return data;
    }
    return Promise.reject();
  });

  const companyListQuery = useQuery(
    ["companyListQuery", page, pageSize, tenant?.id, companyId, sortState, filterValue],
    async () => {
      if (tenant.id && companyId) {
        const { data } = await listQuestionnaireByCompany(
          page,
          pageSize,
          tenant?.id,
          companyId,
          filterValue + `company.id:${companyId}`,
          sortState,
        );

        return data;
      }
      return Promise.reject();
    },
  );

  const history = useHistory();

  useEffect(() => {
    setHeaderButtons(
      <Button
        component={Link}
        to={`/energetic/create?tenantId=${tenant.id}`}
        startIcon={<AddBox />}
      >
        {t("common:button:create")}
      </Button>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, []); //eslint-disable-line

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      history.push(`/energetic/details?id=${params.row.id}`);
    }
  }

  const columns: GridColDef[] = [
    {
      field: "company.name",
      headerName: t("userList.company"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row?.company ? row.company?.name : row?.companyName,
    },
    {
      field: "company.address",
      headerName: "Cég címe",
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row?.companyAddress ? row?.companyAddress : "-",
    },
    {
      field: "createdOn",
      headerName: "Kitöltés dátuma",
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row?.createdOn ? format(new Date(row?.createdOn), "yyyy. MM. dd.") : "-",
    },
  ];

  return (
    <Grid style={{ margin: 16 }}>
      {companyId ? (
        <PageableTable
          query={companyListQuery}
          sortState={sortState}
          setSortState={setSortState}
          columns={columns}
          setTableState={setTableState}
          tableState={tableState}
          sessionStorageKey="mol-energetic"
          onCellClick={onCellClick}
        />
      ) : (
        <SingleQueryTable
          query={listQuestionnaireQuery}
          columns={columns}
          setTableState={setTableState}
          tableState={tableState}
          sessionStorageKey="mol-energetic"
          onCellClick={onCellClick}
        />
      )}
    </Grid>
  );
};

export default MolEnergeticList;
