import { Box, CircularProgress, Container } from "@material-ui/core";
import { RootState } from "config/store";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SliceStatus } from "shared/common";
import { createIssueTemplate } from "shared/network/issue-template.api";
import { createRelIssueTemplateCustomFields } from "shared/network/rel-issue-template-custom-fields.api";
import { removeEmptyStrings } from "shared/util/removeEmptyStrings";
import IssueTemplateForm, { RelIssueTemplateFormValues } from "./IssueTemplateForm";
import queryClient from "config/query";

type Props = {
  setIssueTemplateOpen: any;
  setNewIssueTemplate: any;
  isDialog: any;
};

const IssueTemplateCreate = ({ setIssueTemplateOpen, setNewIssueTemplate, isDialog }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState<SliceStatus>("idle");

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenantId = tenant.id;

  const form = useForm<RelIssueTemplateFormValues>();

  const onSubmitCreate = async (values: RelIssueTemplateFormValues) => {
    setStatus("pending");
    try {
      const { data } = await createIssueTemplate(
        removeEmptyStrings(values.issueTemplateEntry),
        values.itemList,
        values.toolList,
        values.userList,
        tenantId,
      );
      if (account.user.isSuperAdmin || selectedRelTenant.isTenantAdmin) {
        await createRelIssueTemplateCustomFields({
          issueTemplateId: data.item?.id,
          relIssueTemplateCustomFieldsEntries: values.customFields.map((_, index) => {
            return {
              customField: values.customFields[index].customField,
              orderNumber: index + 1,
              defaultValue:
                values.customFields[index].defaultValue &&
                typeof values.customFields[index].defaultValue === "object"
                  ? values.customFields[index].customField.type === "DATE"
                    ? format(values.customFields[index].defaultValue as Date, "yyyy-MM-dd")
                    : values.customFields[index].customField.type === "TIME"
                    ? format(values.customFields[index].defaultValue as Date, "Pp")
                    : JSON.stringify(values.customFields[index].defaultValue)
                  : values.customFields[index].defaultValue,
            };
          }),
          tenantId,
        });
      }

      enqueueSnackbar(
        t("common:notification.create.success", {
          subject: t("issueTemplate.subject"),
        }),
        { variant: "success" },
      );
      setStatus("success");
      if (setIssueTemplateOpen) {
        if (setNewIssueTemplate && (data as any)?.item) {
          setNewIssueTemplate?.((data as any)?.item);
        }
        setIssueTemplateOpen(false);
        queryClient.invalidateQueries("issueIssueTemplateQuery");
      } else {
        history.goBack();
      }
    } catch (e) {
      enqueueSnackbar(
        t("common:notification.create.failure", {
          subject: t("issueTemplate.subject"),
        }),
        { variant: "error" },
      );
      setStatus("failure");
    }
  };

  return (
    <Container maxWidth="lg">
      <form onSubmit={form.handleSubmit(onSubmitCreate)}>
        {status === "pending" ? (
          <Box display="flex" alignItems="center" justifyContent="center" height="300px">
            <CircularProgress />
          </Box>
        ) : (
          <FormProvider {...form}>
            <IssueTemplateForm
              isLoading={status !== "idle" && status !== "failure"}
              setIssueTemplateOpen={setIssueTemplateOpen}
            />
          </FormProvider>
        )}
      </form>
    </Container>
  );
};

export default IssueTemplateCreate;
