import {
  Box,
  Checkbox,
  createStyles,
  InputAdornment,
  makeStyles,
  Popover,
  TextField,
  Theme,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import { ReactNode, useState } from "react";
import { BlockPicker, ChromePicker } from "react-color";

type Props = {
  label: ReactNode;
  value: string;
  setValue: (value: string) => void;
  colors?: string[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    endAdornment: {
      paddingRight: 8,
    },
  }),
);

const ColorPicker = ({ label, setValue, value, colors }: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [showCustomColor, setShowCustomColor] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowCustomColor(event.target.checked);
  };

  return (
    <>
      {value != null && (
        <TextField
          size="small"
          variant="outlined"
          label={label}
          value={value}
          onClick={handleClick}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  style={{
                    backgroundColor: value,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    width: "24px",
                    height: "24px",
                  }}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <ArrowDropDown />
              </InputAdornment>
            ),
            classes: {
              adornedEnd: classes.endAdornment,
            },
          }}
        />
      )}
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {!showCustomColor && (
          <BlockPicker
            color={value}
            colors={
              colors || [
                "#B3D7FF",
                "#A8E6A1",
                "#FFD699",
                "#FFE599",
                "#E6A8D7",
                "#B8E186",
                "#D9D9D9",
                "#FFB3E6",
                "#99E6E6",
                "#D1E0A5",
              ]
            }
            onChangeComplete={(color: any) => {
              setValue(color.hex);
            }}
          />
        )}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Checkbox checked={showCustomColor} onChange={handleCheckboxChange} />
            <span>Egyéni szín</span>
          </Box>
        </Box>
        {showCustomColor && (
          <ChromePicker
            color={value}
            onChangeComplete={(color: any) => {
              setValue(color.hex);
            }}
          />
        )}
      </Popover>
    </>
  );
};

export default ColorPicker;
