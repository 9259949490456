import { IconButton, Tooltip } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Assignment } from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { getJobReferredToPage } from "shared/network/job-referred-to.api";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/DeliveryRound/DeliveryRoundList";

const JobFormPartners = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);
  const subjectId = query.get("id");

  const tenantId = useSelector(
    (state: RootState) => state?.authentication?.selectedRelTenant?.tenant?.id,
  );

  const isJobForm = window.location.pathname?.includes("job-forms");
  const isPartner = window.location.pathname?.includes("company-details");
  const isUser = window.location.pathname?.includes("user-details");

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey(
          `rap-job-form-${
            isJobForm ? "job-form" : isPartner ? "company" : "user"
          }-${subjectId}-page-number`,
        ),
      ) || JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey(
          `rap-job-form-${
            isJobForm ? "job-form" : isPartner ? "company" : "user"
          }-${subjectId}-page-size`,
        ),
      ) || JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const getJobReferredToQuery = useQuery(
    ["getJobReferredToQuery", page, pageSize, filterValue, tenantId, sortState],
    async () => {
      let search = "";
      if (isJobForm) {
        search = `jobQuestionnaireId:${subjectId};${filterValue || ""}`;
      }
      if (isPartner) {
        search = `company?.id:${subjectId};${filterValue || ""}`;
      }
      if (isUser) {
        search = `user?.id:${subjectId};${filterValue || ""}`;
      }
      const { data } = await getJobReferredToPage(tenantId, page, pageSize, search, sortState);
      return data;
    },
    { enabled: !!subjectId },
  );

  const columns: GridColDef[] = [
    {
      field: "company.nameSearch",
      headerName: "Partner neve",
      flex: 1,
      renderCell: ({ row }: GridValueGetterParams) => row.company.name,
    },
    {
      field: "user.name",
      headerName: "Felhasználó neve",
      flex: 1,
      renderCell: ({ row }: GridValueGetterParams) => row.user.name,
    },
    {
      field: "user.email",
      headerName: "Felhasználó e-mail címe",
      flex: 1,
      renderCell: ({ row }: GridValueGetterParams) => row.user.email,
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      hide: isJobForm,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Tooltip title={t("delivery.showItems").toString()}>
            <IconButton
              size="small"
              color="primary"
              style={{ margin: "0 8px" }}
              onClick={() => history.push(`/job-forms/details?id=${row?.jobQuestionnaireId}`)}
            >
              <Assignment />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  function onCellClick(params: GridCellParams) {
    if (params.field === "comapny.nameSearch") {
      history.push(`/companies/list-all/company-details/details?id=${params.row.id}`);
    }
    if (params.field === "user.name" || params.field === "user.email") {
      history.push(`/my-company/users/user-details?id=${params?.row?.user?.id}`);
    }
  }

  return (
    <Box pt={1}>
      <PageableTable
        onCellClick={onCellClick}
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey={`rap-job-form-${
          isJobForm ? "job-form" : isPartner ? "company" : "user"
        }-${subjectId}`}
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={getJobReferredToQuery}
        minWidth={{ value: "md" }}
      />
    </Box>
  );
};

export default JobFormPartners;
