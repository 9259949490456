import { Box, Checkbox, FormControlLabel, MenuItem, Typography } from "@material-ui/core";
import { RootState } from "config/store";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getCompanyById } from "shared/network/company.api";
import { listContactByTenant } from "shared/network/contact.api";
import { Contact } from "shared/types";

type Props = {
  selectedContacts: Contact[];
  setSelectedContacts: Dispatch<SetStateAction<Contact[]>>;
  selectedEmails: string[];
  setSelectedEmails: Dispatch<SetStateAction<string[]>>;
  search: string;
  companyId?: number | string;
};

const CreateEmailContacts = ({
  selectedContacts,
  setSelectedContacts,
  selectedEmails,
  setSelectedEmails,
  search,
  companyId,
}: Props) => {
  const { t } = useTranslation();
  const tenantId = useSelector(
    (state: RootState) => state.authentication?.selectedRelTenant?.tenant?.id,
  );

  const companyQuery = useQuery(["companyByIdEmailQuery", companyId], async () => {
    if (companyId) {
      const { data } = await getCompanyById(Number(companyId), tenantId);
      return data.item;
    }
    return Promise.reject();
  });
  const companyEmail = companyQuery.data?.notificationEmail;

  const contactListByTenantQuery = useQuery(
    ["contactListByTenantQuery", tenantId, companyId],
    async () => {
      const { data } = await listContactByTenant(0, 2000, tenantId, `companyId:${companyId}`);
      return data;
    },
  );

  const filteredList = contactListByTenantQuery.data?.page?.content?.filter(contact =>
    contact?.name?.toUpperCase()?.includes(search.toUpperCase()),
  );

  return (
    <Box overflow="auto" height={400}>
      {!!companyEmail && (
        <MenuItem
          onClick={() => {
            if (selectedEmails.find(email => email === companyEmail)) {
              setSelectedEmails(selectedEmails.filter(email => email !== companyEmail));
            } else {
              let emails = [...selectedEmails];
              emails.push(companyEmail);
              setSelectedEmails(emails);
            }
          }}
        >
          <FormControlLabel
            label=""
            control={
              <Checkbox
                checked={
                  selectedEmails.findIndex(
                    email => email.toLowerCase() === companyEmail.toLowerCase(),
                  ) !== -1
                }
                color="primary"
              />
            }
          />
          <Box>
            <Typography style={{ fontWeight: "bold" }}>{t("company.formValues.email")}</Typography>
            <Typography style={{ flex: 1 }}>{companyEmail}</Typography>
          </Box>
        </MenuItem>
      )}
      {!!filteredList?.length
        ? filteredList?.map((contact, index) => {
            return (
              <Box key={index} width="100%">
                <MenuItem
                  style={{ height: 50 }}
                  onClick={() => {
                    const contacts = [...selectedContacts];
                    if (selectedContacts?.find(co => co.id === contact.id)) {
                      setSelectedContacts(contacts.filter(us => us.id !== contact.id));
                    } else {
                      contacts.push(contact);
                      setSelectedContacts(contacts);
                    }
                  }}
                >
                  <FormControlLabel
                    label=""
                    control={
                      <Checkbox
                        size="small"
                        color="primary"
                        checked={selectedContacts?.indexOf(contact) > -1}
                      />
                    }
                  />
                  <Box>
                    <Typography style={{ fontWeight: "bold" }}>{contact?.name}</Typography>
                    <Typography>{contact?.email}</Typography>
                  </Box>
                </MenuItem>
              </Box>
            );
          })
        : !companyEmail && <Typography style={{ padding: 16 }}>A lista üres</Typography>}
    </Box>
  );
};

export default CreateEmailContacts;
