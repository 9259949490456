import { Box, Button, Container, IconButton, Tooltip } from "@material-ui/core";
import {
  AddBox,
  Assignment,
  Autorenew,
  Close,
  Description,
  Done,
  Edit,
  ListAlt,
} from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { OFFER_STATUSES } from "config/constants";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listOfferByTenant } from "shared/network/offer.api";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";
import OfferStatusChangeModal from "./OfferStatusChangeModal";
import TagIcon from "views/Tag/TagIcon";
import { getTagColumnWidth } from "views/User/UserList";
import { Offer } from "shared/types";
import RelCreateTag from "views/Tag/RelCreateTag";
import RelModifyTag from "views/Tag/RelModifyTag";
import { getTagPage } from "shared/network/tag.api";

const OfferList = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isCompany = location.pathname.includes("/companies");
  const isAll = location.pathname.includes("/list-all");
  const isCustomer = location.pathname.includes("/list-customer");
  const query = new URLSearchParams(location.search);
  const companyId = query.get("id");
  const isIncoming =
    location.pathname.includes("/offers/in") || location.pathname.includes("/offer/in");
  const history = useHistory();
  const { setHeaderButtons } = useHeader();

  const [confirmation, setConfirmation] = useState<{
    type: string;
    id: number;
  }>({ type: "", id: 0 });

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey(`rap-tenant-offerlist-${companyId}-${isIncoming}-number`),
      ) || JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey(`rap-tenant-offerlist-${companyId}-${isIncoming}-size`),
      ) || JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const getIncomingOfferList = useQuery(
    [
      "getOfferList",
      selectedRelTenant.tenant.id,
      page,
      pageSize,
      filterValue,
      sortState,
      isIncoming,
    ],
    async () => {
      const { data } = await listOfferByTenant(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        companyId
          ? `company.id:${companyId};isIncoming:${isIncoming};`
          : account.user.relUserCompany?.[0]?.company?.id
          ? `company.id:${account.user.relUserCompany?.[0]?.company?.id};isIncoming:${isIncoming};`
          : `isIncoming:${isIncoming};` + filterValue,
        sortState,
      );
      return data;
    },
  );

  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [selectedToModify, setSelectedToModify] = useState<Offer | null>(null);

  const columns: GridColDef[] = [
    {
      field: "tagSearch",
      headerName: t("tags.title"),
      width: getTagColumnWidth(getIncomingOfferList?.data?.page?.content || []),
      sortable: false,
      filterable: true,
      type: "select",
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" width="100%">
          <TagIcon
            row={row}
            relTagList={row?.relTagSubjectEntryList}
            setSelectedRow={setSelectedRow}
            setSelectedToModify={setSelectedToModify}
          />
        </Box>
      ),
    },
    {
      field: "identifier",
      headerName: t("offer.formValues.identifier"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.identifier || "-",
    },
    {
      field: "company.nameSearch",
      headerName: t("offer.formValues.companyName"),
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams) => row.company?.name || "-",
    },
    {
      field: "name",
      headerName: t("offer.formValues.name"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.name || "-",
    },
    {
      field: "currency.name",
      headerName: t("offer.formValues.currency"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => (row.currency ? row.currency.name : "-"),
    },
    {
      field: "validTo",
      headerName: t("offer.formValues.validTo"),
      flex: 1,
      type: "singleDateStart",
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.validTo ? format(new Date(row.validTo), "yyyy. MM.dd.") : "-",
    },
    {
      field: "status",
      headerName: t("offer.formValues.status"),
      width: 100,
      sortable: false,
      align: "center",
      headerAlign: "center",
      type: "select",
      renderCell: ({ row }: GridRenderCellParams) => (
        <Tooltip title={t(`offer.statuses.${row.status}`).toString()}>
          {row.status === "ACCEPTED" ? (
            <Done style={{ color: COLORS.green }} />
          ) : row.status === "DECLINED" ? (
            <Close style={{ color: COLORS.red }} />
          ) : (
            <Autorenew />
          )}
        </Tooltip>
      ),
    },
    {
      field: "isValidated",
      headerName: t("offer.formValues.acknowledged"),
      width: 100,
      sortable: false,
      align: "center",
      headerAlign: "center",
      type: "boolean",
      renderCell: ({ row }: GridRenderCellParams) => (
        <Tooltip
          title={(row.isValidated
            ? t(`offer.isValidated.ACCEPTED`)
            : t(`offer.isValidated.DECLINED`)
          ).toString()}
        >
          {row.isValidated ? (
            <Done style={{ color: COLORS.green }} />
          ) : (
            <Close style={{ color: COLORS.red }} />
          )}
        </Tooltip>
      ),
    },
    {
      field: "actions",
      headerName: " ",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ORDER_ADMIN"]) && (
            <Tooltip title={t("offer.makeAContract").toString()}>
              <span>
                <IconButton
                  color={row.status !== "ACCEPTED" ? "secondary" : "primary"}
                  onClick={() => {
                    history.push(`/contract/create?offerId=${row.id}`, row);
                  }}
                  size="small"
                  disabled={row.status !== "ACCEPTED"}
                >
                  <Description />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ORDER_ADMIN"]) && (
            <Tooltip title={t("offer.makeAnOrder").toString()}>
              <span>
                <IconButton
                  color={row.status !== "ACCEPTED" ? "secondary" : "primary"}
                  onClick={() => {
                    history.push(`/order/list/fast-create?offerId=${row.id}`);
                  }}
                  size="small"
                  disabled={row.status !== "ACCEPTED"}
                >
                  <ListAlt />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["OFFER_ADMIN"]) &&
            row.status !== "ACCEPTED" && (
              <Tooltip title={t("offer.accept").toString()}>
                <span>
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => setConfirmation({ type: "ACCEPT", id: row.id })}
                  >
                    <Done />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["OFFER_ADMIN"]) &&
            row.status !== "DECLINED" && (
              <Tooltip title={t("offer.decline").toString()}>
                <span>
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => setConfirmation({ type: "DECLINE", id: row.id })}
                  >
                    <Close />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["OFFER_ADMIN"]) &&
            row.status !== "PENDING" && (
              <Tooltip title={t("offer.reset").toString()}>
                <span>
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => setConfirmation({ type: "RESET", id: row.id })}
                  >
                    <Autorenew />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["OFFER_ADMIN"]) && (
            <Tooltip title={t("offer.details").toString()}>
              <IconButton
                component={Link}
                to={
                  isCompany
                    ? `/companies/${
                        isAll ? "list-all" : isCustomer ? "list-customer" : "list-supplier"
                      }/company-details/offers/${isIncoming ? "in" : "out"}/offer-details?id=${
                        row.id
                      }&backId=${companyId}`
                    : `/offer/${isIncoming ? "in" : "out"}/details?id=${row.id}`
                }
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
              >
                <Assignment color="primary" />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["OFFER_ADMIN"]) && (
            <Tooltip title={t("offer.modify").toString()}>
              <span>
                <IconButton
                  component={Link}
                  color={row.status !== "PENDING" ? "secondary" : "primary"}
                  to={
                    isCompany
                      ? `/companies/${
                          isAll ? "list-all" : isCustomer ? "list-customer" : "list-supplier"
                        }/company-details/offers/${isIncoming ? "in" : "out"}/offer-modify?id=${
                          row.id
                        }&backId=${companyId}`
                      : `/offer/${isIncoming ? "in" : "out"}/modify?id=${row.id}`
                  }
                  size="small"
                  disabled={row.status !== "PENDING"}
                >
                  <Edit />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions" && params.field !== "tagSearch") {
      history.push(
        isCompany
          ? `/companies/${
              isAll ? "list-all" : isCustomer ? "list-customer" : "list-supplier"
            }/company-details/offers/${isIncoming ? "in" : "out"}/offer-details?id=${
              params.row.id
            }&backId=${companyId}`
          : `/offer/${isIncoming ? "in" : "out"}/details?id=${params.row.id}`,
      );
    }
  }

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["OFFER_ADMIN"]) && (
          <Button
            component={Link}
            to={
              isCompany
                ? `/companies/${
                    isAll ? "list-all" : isCustomer ? "list-customer" : "list-supplier"
                  }/company-details/offers/${
                    isIncoming ? "in" : "out"
                  }/offer-create?id=${companyId}&isIncoming=${isIncoming}`
                : `/offer/${isIncoming ? "in" : "out"}/create?isIncoming=${isIncoming}`
            }
            variant="contained"
            startIcon={<AddBox />}
          >
            {isIncoming ? t(`offer.in.create`) : t("offer.out.create")}
          </Button>
        )}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant, isIncoming]); //eslint-disable-line

  function getDefaultFilters(columns: GridColDef[]) {
    const statusColumn = columns?.find(column => column.field === "status");
    if (statusColumn) {
      return [
        {
          column: statusColumn,
          isNegate: true,
          value: "DECLINED",
        },
      ];
    } else {
      return [];
    }
  }

  const tagQuery = useQuery(["tagOFFERQuery", selectedRelTenant?.tenant?.id], async () => {
    const { data } = await getTagPage(
      selectedRelTenant?.tenant?.id,
      0,
      1000,
      `(tagType:OFFER;(OR)tagType(IS_NULL)1;)`,
    );

    return data;
  });

  return (
    <Container maxWidth="xl">
      {!!selectedRow && (
        <RelCreateTag
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          refetch={getIncomingOfferList.refetch}
          subjectType="OFFER"
        />
      )}
      {!!selectedToModify && (
        <RelModifyTag
          selectedToModify={selectedToModify}
          setSelectedToModify={setSelectedToModify}
          refetch={getIncomingOfferList.refetch}
          subjectType="OFFER"
        />
      )}
      <PageableTable
        defaultFilters={getDefaultFilters(columns)}
        filterOptions={[
          {
            columnName: "status",
            options: OFFER_STATUSES.map(value => {
              return {
                translated: t(`offer.statuses.${value}`),
                value,
              };
            }),
          },
          {
            columnName: "tagSearch",
            options: tagQuery?.data?.page?.content?.map(value => {
              return {
                translated: value?.tag || "",
                value: "x" + value?.id + "y",
              };
            }),
          },
        ]}
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey={`offerlist-${companyId}-${isIncoming}`}
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={getIncomingOfferList}
        onCellClick={onCellClick}
      />
      <OfferStatusChangeModal
        confirmation={confirmation}
        setConfirmation={setConfirmation}
        refetch={getIncomingOfferList.refetch}
      />
    </Container>
  );
};

export default OfferList;
