import { Box, IconButton, Tooltip } from "@material-ui/core";
import { Assignment, Edit, Visibility } from "@material-ui/icons";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import PageableTable from "components/PageableTable/PageableTable";
import { DELIVERY_TYPE } from "config/constants";
import { RootState } from "config/store";
import { format } from "date-fns/esm";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { hasAuthority } from "shared/authorization";
import { getAllDelivery } from "shared/network/delivery.api";
import { getDeliveryNoteByDeliveryId } from "shared/network/deliveryNote.api";
import { getDeliveryNoteFileURL } from "shared/network/files.api";
import { Delivery } from "shared/types";
import displayAddress from "shared/util/displayAddress";
import { deliveryNoteLinkOpen } from "shared/util/fileDownload";
import { TableState } from "views/Company/CompanyList";
import DeliveryItemDialog from "views/Delivery/DeliveryItemDialog";
import DeliveryStatusDialog from "views/Delivery/DeliveryStatusDialog";
import AllDeliveryStatusCell from "./AllDeliveryStatusCell";
import { useGetSessionStorageKey } from "views/Comment/function";

const TenantAllDelivery = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [statusDelivery, setStatusDelivery] = useState<Delivery | null>(null);
  const [deliveryId, setDeliveryId] = useState<number | null>(null);
  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-tenant-delivery-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-tenant-delivery-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const getAllDeliveryListQuery = useQuery(
    [
      "getAllDeliveryListQuery",
      selectedRelTenant.tenant.id,
      page,
      pageSize,
      filterValue,
      sortState,
    ],
    async () => {
      const { data } = await getAllDelivery(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        filterValue,
        sortState,
      );
      return data;
    },
  );

  async function getDeliveryNote(deliveryId: number) {
    const { data } = await getDeliveryNoteByDeliveryId(deliveryId, selectedRelTenant.tenant.id);
    return data.item;
  }

  async function viewDeliveryNote(deliveryId: number) {
    try {
      const deliveryNoteId = (await getDeliveryNote(deliveryId)).id;
      try {
        if (deliveryNoteId) {
          deliveryNoteLinkOpen(
            getDeliveryNoteFileURL(deliveryNoteId.toString(), selectedRelTenant.tenant.id),
          );
        } else {
          enqueueSnackbar(
            t("common:notification.deliveryNote.notExist", {
              subject: t("deliveryNote.subject"),
            }),
            {
              variant: "error",
            },
          );
        }
      } catch (e: any) {
        if (e.data?.status === "RESOURCE_ERROR") {
          enqueueSnackbar(
            t("common:notification.resource.failure", {
              subject: t("commmon:resource"),
            }),
            {
              variant: "error",
            },
          );
        } else {
          enqueueSnackbar(
            t("common:notification.download.failure", {
              subject: t("deliveryNote.subject"),
            }),
            {
              variant: "error",
            },
          );
        }
      }
    } catch (e: any) {
      if (e.data?.status === "RESOURCE_ERROR") {
        enqueueSnackbar(
          t("common:notification.resource.failure", {
            subject: t("commmon:resource"),
          }),
          {
            variant: "error",
          },
        );
      } else {
        enqueueSnackbar(
          t("common:notification.deliveryNote.notExist", {
            subject: t("deliveryNote.subject"),
          }),
          {
            variant: "error",
          },
        );
      }
    }
  }

  const columns: GridColDef[] = [
    {
      field: "order.orderId",
      headerName: t("delivery.orderId"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.order?.orderId,
    },
    {
      field: "company.nameSearch",
      headerName: t("companySite.formValues.company"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.company ? row.company?.name : row.deliveryRound ? row.deliveryRound.name : "",
    },
    {
      field: "date",
      headerName: t("deliveryNote.formValues.date"),
      flex: 1,
      type: "singleDateStart",
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.date ? format(new Date(row.date), "yyyy.MM.dd") : "-",
    },
    {
      field: "vehicle.licensePlateNumber",
      headerName: t("vehicle.title"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.vehicle?.licensePlateNumber,
    },
    {
      field: "sourceAddress",
      headerName: t("delivery.formValues.sourceAddress"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.sourceAddress ? displayAddress(row.sourceAddress) : "-",
    },
    {
      field: "targetAddress",
      headerName: t("delivery.formValues.destinationAddress"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.targetAddress ? displayAddress(row.targetAddress) : "-",
    },
    {
      field: "fromWarehouse.name",
      headerName: t("delivery.formValues.fromWarehouse"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.fromWarehouse ? row.fromWarehouse.name : "-",
    },
    {
      field: "toWarehouse.name",
      headerName: t("delivery.formValues.toWarehouse"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.toWarehouse ? row.toWarehouse.name : "-",
    },
    {
      field: "status",
      headerName: t("delivery.formValues.status"),
      headerAlign: "center",
      flex: 2,
      minWidth: 200,
      type: "select",

      renderCell: ({ row }: GridRenderCellParams) =>
        !row.isInsideDelivery && (
          <AllDeliveryStatusCell
            delivery={row as Delivery}
            refetch={getAllDeliveryListQuery.refetch}
            tenant={selectedRelTenant.tenant}
          />
        ),
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {!row.isInsideDelivery &&
            hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "DELIVERY_ADMIN",
            ]) && (
              <Tooltip title={t("delivery.modifyStatus").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  onClick={() => setStatusDelivery(row as Delivery)}
                  disabled={
                    ["DELIVERED", "BILLED"].includes((row as Delivery).status?.toString()) &&
                    !hasAuthority(account.user, account.permissions, selectedRelTenant, [
                      "TENANT_ADMIN",
                    ])
                  }
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "DELIVERY_NOTE_ADMIN",
          ]) && (
            <Tooltip title={t("deliveryNote.deliveryNoteView").toString()}>
              <IconButton
                size="small"
                color="primary"
                onClick={async () => {
                  viewDeliveryNote(row.id);
                }}
              >
                <Visibility />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "DELIVERY_ADMIN",
          ]) && (
            <Tooltip title={t("delivery.showItems").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                onClick={() => setDeliveryId(row.id)}
              >
                <Assignment />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  return (
    <>
      <DeliveryItemDialog deliveryId={deliveryId} setDeliveryId={setDeliveryId} />
      <DeliveryStatusDialog
        selectedDelivery={statusDelivery}
        setSelectedDelivery={setStatusDelivery}
        refetch={getAllDeliveryListQuery.refetch}
      />
      <PageableTable
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="tenant-delivery"
        filterOptions={[
          {
            columnName: "status",
            options: DELIVERY_TYPE.map(value => {
              return {
                translated: t(`delivery.status.${value}`),
                value,
              };
            }),
          },
        ]}
        filterable={[
          "company.nameSearch",
          "fromWarehouse.name",
          "toWarehouse.name",
          "status",
          "order.orderId",
          "date",
        ]}
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={getAllDeliveryListQuery}
      />
    </>
  );
};

export default TenantAllDelivery;
