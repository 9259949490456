import axios from "config/axios";
import { GenericPageResponse, GenericResponse } from "shared/common";
import { Company, User } from "shared/types";

export type JobReferredTo = {
  id: number | null;
  tenantId: number | null;
  jobQuestionnaireId: number | null;
  user: User | null;
  company: Company | null;
};

const ENDPOINT = "/job-referred-to";

export const createJobReferredTo = (param: Partial<JobReferredTo>, tenantId: number) =>
  axios.post<GenericResponse<JobReferredTo>>(`${ENDPOINT}`, { param, tenantId });

export const getJobReferredToById = (id: number, tenantId: number) =>
  axios.post(`${ENDPOINT}/get-by-id`, { param: id, tenantId });

export const getJobReferredToPage = (
  tenantId: number,
  page = 0,
  size = 10,
  search: string = "",
  sort: string = "",
) => {
  return axios.get<GenericPageResponse<JobReferredTo>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${
      search || ""
    }&sort=${sort || ""}`,
  );
};
