import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid, MenuItem, Popover, TextField, Typography } from "@material-ui/core";
import { COLORS } from "config/theme";
import { Dispatch, SetStateAction } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

type Props = {
  anchorEl: HTMLDivElement | HTMLButtonElement | null;
  onClose: () => void;
  setArrangeValue: Dispatch<SetStateAction<string>>;
};

const KanbanArrangeModal = ({ setArrangeValue, anchorEl, onClose }: Props) => {
  const { t } = useTranslation();
  const { control, watch } = useForm();
  const arrangeValue = watch("arrangeValue");
  const value = watch("value");

  return (
    <Popover open={!!anchorEl} anchorEl={anchorEl} onClose={onClose}>
      <Box display="flex" alignItems="center" style={{ margin: 10 }} gridGap={12}>
        <FontAwesomeIcon
          icon={faSort}
          style={{
            marginLeft: 4,
            width: 14,
          }}
        />
        <Typography variant="h6" style={{ color: COLORS.main }}>
          {t("common:arrangement.title")}
        </Typography>
      </Box>
      <Grid container spacing={2} style={{ width: 250, margin: 10, marginBottom: 0 }}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="value"
            rules={{ required: t("validation.required").toString() }}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t("common:filter.formValues.value")}
                InputLabelProps={{ shrink: true, required: true }}
                SelectProps={{
                  displayEmpty: true,
                }}
                select
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              >
                <MenuItem disabled value="">
                  <>{t("common:choose")}</>
                </MenuItem>
                <MenuItem value="endDate">
                  <>{t("issues.formValues.endDate")}</>
                </MenuItem>
                <MenuItem value="priority">
                  <>{t("issues.formValues.priority")}</>
                </MenuItem>
                <MenuItem value={`id`}>
                  <>{t("issues.formValues.id")}</>
                </MenuItem>
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="arrangeValue"
            rules={{ required: t("validation.required").toString() }}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t("common:arrangement.formValues.order")}
                InputLabelProps={{ shrink: true, required: true }}
                SelectProps={{
                  displayEmpty: true,
                }}
                select
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              >
                <MenuItem disabled value="">
                  <>{t("common:choose")}</>
                </MenuItem>
                <MenuItem value="ASC">
                  <>{t("common:ASC")}</>
                </MenuItem>
                <MenuItem value="DESC">
                  <>{t("common:DESC")}</>
                </MenuItem>
              </TextField>
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box width="100%" display="flex" justifyContent="space-around">
          <Button
            disableElevation
            onClick={onClose}
            style={{
              color: COLORS.mainLight,
              backgroundColor: COLORS.mainGrey,
              width: "100%",
              borderRadius: "unset",
            }}
          >
            {t("common:button.cancel")}
          </Button>
          <Button
            disableElevation
            onClick={() => {
              setArrangeValue(`${value},${arrangeValue}`);
              onClose();
            }}
            style={{
              width: "100%",
              backgroundColor: COLORS.mainLight,
              borderRadius: "unset",
            }}
          >
            {t("common:arrangement.title")}
          </Button>
        </Box>
      </Grid>
    </Popover>
  );
};

export default KanbanArrangeModal;
