import { Box, Button, Container, IconButton } from "@material-ui/core";
import { AddBox, Edit } from "@material-ui/icons";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getFoodPage } from "shared/network/food.api";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/DeliveryRound/DeliveryRoundList";
import HaccpServingSettingsDialog from "./HaccpServingSettingsDialog";

const HaccpServingSettings = () => {
  const { t } = useTranslation();
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any | null>(null);
  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey("rap-haccp-serving-settings-page-number"),
      ) || JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey("rap-haccp-serving-settings-page-size"),
      ) || JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("haccpItem.name"),
      flex: 2,
    },
    {
      field: "status",
      type: "select",
      headerName: t("haccpItem.status.label"),
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => t(`haccpItem.status.${row.status}`),
    },
    {
      field: "foodType",
      type: "select",
      headerName: t("haccpServing.foodType.label"),
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => t(`haccpServing.foodType.${row.foodType}`),
    },
    {
      field: "actions",
      headerName: " ",
      width: 40,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%" alignItems="center">
          <IconButton color="primary" size="small" onClick={() => setSelectedRow(row)}>
            <Edit />
          </IconButton>
        </Box>
      ),
    },
  ];

  const foodPageQuery = useQuery(
    ["foodPageQuery", page, pageSize, tenant.id, filterValue, sortState],
    async () => {
      const { data } = await getFoodPage(page, pageSize, tenant.id, filterValue, sortState);
      return data;
    },
  );

  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gridGap={16}
      >
        <Box>
          <Button startIcon={<AddBox />} onClick={() => setOpen(true)}>
            {t("haccpServing.create")}
          </Button>
        </Box>
        <HaccpServingSettingsDialog
          open={open}
          onClose={() => setOpen(false)}
          defaultValues={selectedRow}
          isModify={false}
        />
        <HaccpServingSettingsDialog
          open={selectedRow}
          onClose={() => setSelectedRow(null)}
          defaultValues={selectedRow}
          isModify
        />
        <PageableTable
          sortState={sortState}
          setSortState={setSortState}
          sessionStorageKey="haccp-serving-settings"
          tableState={tableState}
          setTableState={setTableState}
          columns={columns}
          query={foodPageQuery}
          minWidth={{ value: "md" }}
          filterOptions={[
            {
              columnName: "status",
              options: ["ACTIVE", "INACTIVE"].map(value => {
                return { translated: t(`haccpItem.status.${value}`).toString(), value };
              }),
            },
            {
              columnName: "foodType",
              options: ["FOOD", "MATERIAL"].map(value => {
                return { translated: t(`haccpServing.foodType.${value}`).toString(), value };
              }),
            },
          ]}
        />
      </Box>
    </Container>
  );
};

export default HaccpServingSettings;
