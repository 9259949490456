import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddBox, Close, Info } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import FormCard from "components/FormCard";
import { RootState } from "config/store";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { listTools } from "shared/network/tools.api";
import { IssueTemplate, Tool } from "shared/types";
import { RelIssueTemplateFormValues } from "../IssueTemplateForm";
import { useDebouncedCallback } from "use-debounce";
import {
  PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH,
  PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY,
} from "config/constants";
import { COLORS } from "config/theme";

type Props = {
  issueTemplate?: IssueTemplate;
};

const useStyles = makeStyles({
  empty: {
    fontSize: 20,
    width: "100%",
  },
});

const ToolListCard = ({ issueTemplate }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { control, setValue } = useFormContext<RelIssueTemplateFormValues>();

  const { append, fields, remove } = useFieldArray({
    control,
    name: "toolList",
    keyName: "key",
  });

  const { tenant } = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant,
  );

  const [toolSearch, setToolSearch] = useState<string>("");

  const toolListQuery = useQuery(
    ["toolListForIssueTemplateQuery", tenant.id, toolSearch],
    async () => {
      const { data } = await listTools(
        0,
        10,
        tenant.id,
        toolSearch ? `name:${toolSearch}` : ``,
      );
      return data.page.content;
    },
  );

  const handleToolSearchChange = useDebouncedCallback((value: string) => {
    if (
      value.length >= PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH ||
      value.length === 0
    ) {
      setToolSearch(value);
    }
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  useEffect(() => {
    //@ts-ignore

    setValue(
      "toolList",
      issueTemplate?.relIssueTemplateTools?.map(relTool => relTool.tool) || [],
    );
  }, [issueTemplate?.relIssueTemplateTools, setValue]);

  return (
    <FormCard title={t("common:tools")}>
      {fields.length ? (
        fields.map((field, index) => (
          <Grid
            key={field.key}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={4} style={{ padding: "12px" }}>
              <Typography
                style={{
                  fontWeight: "bold",
                }}
              >
                {t("tool.toolTitle", {
                  index: index + 1,
                })}
              </Typography>
            </Grid>
            <Grid item xs={2} container justifyContent="flex-end">
              <Tooltip title={t("common:button.delete").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => remove(index)}
                >
                  <Close />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={`toolList.${index}`}
                rules={{
                  required: t("validation.required").toString(),
                }}
                defaultValue={
                  issueTemplate?.relIssueTemplateTools?.[index]?.tool
                }
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onInputChange={(event, newInputValue) => {
                      handleToolSearchChange(newInputValue);
                    }}
                    options={toolListQuery.data || []}
                    getOptionLabel={(option: Tool) => {
                      if (option.name) {
                        return `${option.name}`;
                      } else {
                        return "";
                      }
                    }}
                    getOptionSelected={option => option.id === field.value?.id}
                    onChange={(_, value) => {
                      field.onChange(value);
                      handleToolSearchChange("");
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <Tooltip
                              style={{
                                paddingRight: "2px",
                              }}
                              title={t(
                                "tooltip.tool"
                              ).toString()}
                            >
                              <Info style={{ color: COLORS.lightBlue }} />
                            </Tooltip>
                          ),
                        }}
                        label={t("tool.title")}
                        InputLabelProps={{
                          shrink: true,
                          required: true,
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        ))
      ) : (
        <Grid item container xs={12} justifyContent="center">
          <Box textAlign="center">
            <Typography className={classes.empty} color="secondary">
              {t("common:emptyToolList")}
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <Button
            variant="outlined"
            color="primary"
            style={{ marginBottom: 8, marginTop: 16 }}
            onClick={event => {
              event.stopPropagation();
              append({});
            }}
            startIcon={<AddBox />}
          >
            {t("tool.addTool")}
          </Button>
        </Box>
      </Grid>
    </FormCard>
  );
};

export default ToolListCard;
