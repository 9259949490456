import { Box, Card, Divider, Typography } from "@material-ui/core";
import { COLORS } from "config/theme";
import { useTranslation } from "react-i18next";
import { JobForm } from "shared/network/job-questionnaire.api";

type Props = {
  jobForm: JobForm;
};

const DetailsDataCard = ({ jobForm }: Props) => {
  const { t } = useTranslation();

  return (
    <Card style={{ padding: 16, marginBottom: 16 }}>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontSize: 20,
          fontWeight: "bold",
          paddingBottom: 8,
        }}
      >
        {t("jobForm.details.title1")}
      </Typography>
      <Box style={{ marginBottom: 8 }}>
        {jobForm?.jobPositionEntries?.map((field, index) => {
          return (
            <Box key={field.id}>
              <Typography>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    color: COLORS.mainLight,
                    paddingLeft: 4,
                    paddingRight: 4,
                  }}
                >
                  {index + 1}.
                </span>
                {field.jobDescription}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Divider style={{ marginBottom: 8 }} />
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontSize: 20,
          fontWeight: "bold",
          paddingBottom: 8,
        }}
      >
        {t("jobForm.details.title2")}
      </Typography>
      <Box display="flex" alignItems="center" p={1}>
        <Typography style={{ flex: 1 }}>{t("jobForm.details.isTravelWithOwnCar")}</Typography>
        <Typography>
          {jobForm?.isTravelWithOwnCar ? t("jobForm.details.yes") : t("jobForm.details.no")}
        </Typography>
      </Box>
      <Divider />
      <Box display="flex" alignItems="center" p={1}>
        <Typography style={{ flex: 1 }}>{t("jobForm.details.isCareTakingRequired")}</Typography>
        <Typography>
          {jobForm?.isCareTakingRequired ? t("jobForm.details.yes") : t("jobForm.details.no")}
        </Typography>
      </Box>
      <Divider />
      <Box display="flex" alignItems="center" p={1}>
        <Typography style={{ flex: 1 }}>{t("jobForm.details.isAccommodationRequired")}</Typography>
        <Typography>
          {jobForm?.isAccommodationRequired ? t("jobForm.details.yes") : t("jobForm.details.no")}
        </Typography>
      </Box>
      <Divider />
      <Box display="flex" alignItems="center" p={1}>
        <Typography style={{ flex: 1 }}>{t("jobForm.details.likedRegion")}</Typography>
        <Typography>{t(`jobForm.austriaStates.${jobForm?.likedRegion}`)}</Typography>
      </Box>
      <Divider style={{ marginBottom: 8 }} />
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontSize: 20,
          fontWeight: "bold",
          paddingBottom: 8,
        }}
      >
        {t("jobForm.details.title3")}
      </Typography>
      <Box display="flex" alignItems="center" p={1}>
        <Typography style={{ flex: 1 }}>{t("jobForm.details.isExtraInsuranceRequired")}</Typography>
        <Typography>
          {jobForm?.isExtraInsuranceRequired ? t("jobForm.details.yes") : t("jobForm.details.no")}
        </Typography>
      </Box>
      <Divider />
      <Box display="flex" alignItems="center" p={1}>
        <Typography style={{ flex: 1 }}>{t("jobForm.details.isLegalAssistance")}</Typography>
        <Typography>
          {jobForm?.isLegalAssistance ? t("jobForm.details.yes") : t("jobForm.details.no")}
        </Typography>
      </Box>
      <Divider />
      <Box display="flex" alignItems="center" p={1}>
        <Typography style={{ flex: 1 }}>{t("jobForm.details.isDailyMedicine")}</Typography>
        <Typography>
          {jobForm?.isDailyMedicine ? t("jobForm.details.yes") : t("jobForm.details.no")}
        </Typography>
      </Box>
      <Divider />
      <Box display="flex" alignItems="center" p={1}>
        <Typography style={{ flex: 1 }}>{t("jobForm.details.isWorkbookAvailable")}</Typography>
        <Typography>
          {jobForm?.isWorkbookAvailable ? t("jobForm.details.yes") : t("jobForm.details.no")}
        </Typography>
      </Box>
      <Divider />
      <Box display="flex" alignItems="center" p={1}>
        <Typography style={{ flex: 1 }}>{t("jobForm.details.isWithOtherPerson")}</Typography>
        <Typography>
          {jobForm?.isWithOtherPerson ? t("jobForm.details.yes") : t("jobForm.details.no")}
        </Typography>
      </Box>
      {jobForm?.isWithOtherPerson && (
        <Box display="flex" flexDirection="column" p={1}>
          <Typography style={{ flex: 1, fontWeight: "bold" }}>
            {t("jobForm.details.otherPersonNameDetails")}
          </Typography>
          <Typography style={{ whiteSpace: "pre-wrap" }}>{jobForm?.otherPersonName}</Typography>
        </Box>
      )}
      <Divider />
      <Box display="flex" alignItems="center" p={1}>
        <Typography style={{ flex: 1 }}>{t("jobForm.details.isWithPet")}</Typography>
        <Typography>
          {jobForm?.isWithPet ? t("jobForm.details.yes") : t("jobForm.details.no")}
        </Typography>
      </Box>
      {jobForm?.isWithPet && (
        <Box display="flex" flexDirection="column" p={1}>
          <Typography style={{ flex: 1, fontWeight: "bold" }}>
            {t("jobForm.details.petDescriptionDetails")}
          </Typography>
          <Typography style={{ whiteSpace: "pre-wrap" }}>{jobForm?.petDescription}</Typography>
        </Box>
      )}
    </Card>
  );
};

export default DetailsDataCard;
