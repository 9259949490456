import { TextField } from "@material-ui/core";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";

type Props = {
  inputName: string;
  country?: string;
  isForeign?: boolean;
  isRequired?: boolean;
  defaultValue?: string;
  disabled?: boolean;
};

const TaxNumberInput = ({
  inputName,
  country,
  defaultValue,
  isRequired,
  isForeign,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const { control, setValue, watch } = useFormContext();
  const taxNumberValue = watch(inputName);
  const isHungary =
    country === "HUNGARY" ||
    country === "Magyarország" ||
    (isForeign !== null && isForeign === true);

  useEffect(() => {
    if (!isHungary && taxNumberValue?.includes("_")) {
      setValue(inputName, taxNumberValue?.replace("_", "")?.replace("-", ""));
    }
  }, [inputName, isHungary, setValue, taxNumberValue]);

  return (
    <Controller
      control={control}
      name={inputName}
      defaultValue={defaultValue || ""}
      rules={{
        required: !!isRequired && t("validation.required").toString(),
      }}
      render={({ field, fieldState }) => (
        <>
          {!!isHungary ? (
            <InputMask
              mask="99999999-9-99"
              disabled={disabled}
              {...field}
              // @ts-ignore
              children={() => (
                <TextField
                  {...field}
                  label={t("company.formValues.taxNumber")}
                  InputLabelProps={{
                    shrink: true,
                    required: isRequired,
                  }}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          ) : (
            <TextField
              {...field}
              label={t("company.formValues.taxNumber")}
              InputLabelProps={{
                shrink: true,
                required: !!isRequired,
              }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        </>
      )}
    />
  );
};

export default TaxNumberInput;
